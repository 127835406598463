import { useEffect } from "react";
import ExploringThePrinciples from "../components/aboutUs/ExploringThePrinciples ";
import Founder from "../components/aboutUs/Founder";
import Hero from "../components/aboutUs/Hero";
function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>

      <>
        <Hero />
        <ExploringThePrinciples />
        <Founder />
      </>

    </div>
  );
}

export default AboutUs;
