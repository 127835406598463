import React from "react";
import "../../styles/Home/MedicinesByDisease.css";
import img1 from "../../Assets/Images/home/1.Suhas.webp";
import img2 from "../../Assets/Images/home/2.Suhas.webp";
import img3 from "../../Assets/Images/home/3.suhas.webp";
import img4 from "../../Assets/Images/home/4.suhas.webp";
import img5 from "../../Assets/Images/home/5suhas.webp";
import PrimaryButton from "../shared/PrimaryButton";
import { Bounce, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCategory } from "../../store/categorySlice/CategorySlice";
import Slider from "react-slick";
import { getCategoriesByDisease } from "../../store/categories/categories";
import { fetchAllProductDataByCategory } from "../../store/productsData/productsData";

const images = [img1, img2, img3, img4, img5];

const MedicinesByDisease = () => {
  const medicinesData = useSelector(getCategoriesByDisease);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  // Function to handle category selection
  const handleCategory = (item) => {
    const capitalizedCategoryName = capitalizeFirstLetter(item.categoryName);
    const categoryData = `${capitalizedCategoryName}`;
    dispatch(selectCategory(categoryData));
    navigate("/allproducts");
    dispatch(fetchAllProductDataByCategory(categoryData))
  };

  // for capital letter
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className=" medicines-by-disease">
      <Bounce>
        <div>
          <h1 className="medicineBy-HeadingOfSlider">
            <span className="secondaryTextColor">Find meds according </span>
            <span className="primaryTextColor">to Disease</span>
          </h1>
        </div>
      </Bounce>
      <div className="medicines-by-disease-content">
        <div className="medicines-by-disease-content-left">
          <Slider {...settings}>
            {images.map((image, index) => (
              <Zoom key={index}>
                <img src={image} alt="images" className="slide-image" />
              </Zoom>
            ))}
          </Slider>
        </div>

        <div className="medicines-by-disease-content-right">
          {medicinesData.map((medicine, index) => (
            <div key={index} className="view-medicines">
              <Bounce>
                <div>
                  <img src={medicine.imageObj.url} alt="" />
                </div>
              </Bounce>
              <Bounce>
                <h3>{capitalizeFirstLetter(medicine.categoryName)}</h3>
              </Bounce>
              <Zoom>
                <PrimaryButton
                  onClick={() => {
                    handleCategory(medicine);
                  }}
                >
                  Click Here
                </PrimaryButton>
              </Zoom>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MedicinesByDisease;
