import React, { useEffect, useRef, useState } from "react";
import "../../../styles/medicalTest/labDescription/Services.css";
import { useDispatch, useSelector } from "react-redux";
import {
  closeProcessCard,
  openProcessCard,
} from "../../../store/labSlice/processCard";
import Loader from "../../loader/Loader";
import ProcessCard from "../Book_process/Process_card";

function Services() {
  const dispatch = useDispatch();
  const [active, setActive] = useState("all");
  const labservices = useSelector((state) => state.singleLab?.lab?.labService);
  const processCard = useSelector((state) => state.processCard?.processCard);
  const loader = useSelector((state) => state.labBooking.status);

  const modalRef = useRef(null);
  const [selectedLab, setSelectedLab] = useState();

  const filterServices = (location) => {
    if (location === "all") {
      return labservices;
    } else {
      return labservices.filter((service) =>
        service.availableAt.includes(location)
      );
    }
  };

  const handleChange = (location) => {
    setActive(location);
  };

  const filteredServices = filterServices(active);

  useEffect(() => {
    if (processCard) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [processCard]);

  // Close modal when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(closeProcessCard(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <section className="lab_services_container">
      <div className="lab_services_head">
        <h2>
          Our <span>Services</span>
        </h2>
      </div>
      <div className="lab_services_section">
        <div className="lab_services_btn">
          <button
            onClick={() => handleChange("all")}
            className={active === "all" ? "isActiveBtn" : ""}
          >
            All
          </button>
          <button
            onClick={() => handleChange("home")}
            className={active === "home" ? "isActiveBtn" : ""}
          >
            At Home
          </button>
          <button
            onClick={() => handleChange("lab")}
            className={active === "lab" ? "isActiveBtn" : ""}
          >
            At Lab
          </button>
        </div>
      </div>

      <div className="lab_services_item">
        {filteredServices.map((service, index) => (
          <div key={index} className="lab_services_card">
            <div className="lab_service_card_content">
              <div className="withoutHover">
                <h3>{service?.serviceName}</h3>
              </div>
              <div className="withHover">
                <h4>Price: {service?.sellingPrice}</h4>
                <button
                  className="lab_service_btn"
                  onClick={() => {
                    dispatch(openProcessCard(true));
                    setSelectedLab(service);
                  }}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Overlay to cover the screen when modal is open */}
      {processCard && (
        <div className="overlayCss">
          {loader === "loading" && <Loader />}
          <div className="process_card_overlay" ref={modalRef}>
            <ProcessCard selectedLab={selectedLab} />
          </div>
        </div>
      )}
    </section>
  );
}

export default Services;
