import React, { useState } from "react";
import "../../../styles/AllProducts/FilterProduct/SideBar.css";
import Medicine from "./Medicine";
import Treatments from "./Treatments";
import Category from "./Category";
import { useDispatch, useSelector } from "react-redux";
import {
    clearFilter,
    selectBrands,
    selectCategories,
    selectDiseases,
} from "../../../store/filterSlice/filterSlice";
import {
    fetchAllProductDataByFilter,
    fetchAllProductsData,
} from "../../../store/productsData/productsData";

function SideBar({toggleFilterIcon}) {
    const brands = useSelector(selectBrands);
    const diseases = useSelector(selectDiseases);
    const category = useSelector(selectCategories);

    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState("medicine");

    const handleClick = (value) => {
        setActiveIndex(value);
    };


    //apply button for filter
    const ApplyNow = async () => {
        const filterquery = {
            brand: brands,
            disease: diseases,
            mainCategory: category,
        };
        await dispatch(fetchAllProductDataByFilter(filterquery));
    };


    // clear selected category
    const clearAll = () => {
        dispatch(clearFilter());
        dispatch(fetchAllProductsData());
    };

    return (
        <>
            <div className="Sidebar_container">
                {/* <p onClick={toggleFilterIcon}>X</p> */}
                <Category handleClick={handleClick} activeIndex={activeIndex} toggleFilterIcon={toggleFilterIcon}/>

                <Medicine handleClick={handleClick} activeIndex={activeIndex} />

                <Treatments
                    handleClick={handleClick}
                    activeIndex={activeIndex}
                />

                <div className="applyNowButtonFilter">
                    <button onClick={clearAll}>Clear</button>
                    <button onClick={ApplyNow}>Apply</button>
                </div>
            </div>
        </>
    );
}

export default SideBar;
