import React from "react";
import "../../styles/productCategory/Hero.css";
import coverImage from "../../Assets/Images/productCategory/cover.png";
import { Fade, Zoom, Bounce } from "react-awesome-reveal";


import SearchBar from "../../Constents/SearchBar";

function Hero() {
  const isSmallScreen = window.innerWidth <= 768;
  return (
    <div className="productCategory">
      <div className="heroContent">
        <div className="textContainer">
          {isSmallScreen ? (
            <Bounce>
              <p>Natural Healing, Gentle Solutions</p>
              <h1>EXPLORE OUR HOMEOPATHIC REMEDIES</h1>
            </Bounce>
          ) : (
            <Fade direction="left">
              <p>Natural Healing, Gentle Solutions</p>
              <h1>EXPLORE OUR HOMEOPATHIC REMEDIES</h1>
            </Fade>
          )}
            <SearchBar/>
        </div>
        <div className="imageContainer">
          <Zoom>
            <img src={coverImage} alt="" />
          </Zoom>
        </div>
      </div>
    </div>
  );
}

export default Hero;
