import React from "react";
import "../../../styles/Blogs/BlogDescription/Hero.css";

function Hero({singleBlogDatas}) {
    console.log("singleBlogDatas:::",singleBlogDatas)
    return (
        <section className="blog_des_container">
            <div className="blog_des_content"></div>
            <div className="blog_des_text">
                    <h1>{singleBlogDatas?.blogTitle}</h1>
                <p>
                  {singleBlogDatas?.shortDiscription}
                </p>
            </div>
        </section>
    );
}

export default Hero;
