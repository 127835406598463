import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    brands: [],
    diseases: [],
    categories: [],
};

const brandsAndDiseasesSlice = createSlice({
    name: 'brandsAndDiseases',
    initialState,
    reducers: {
        addBrand(state, action) {
            state.brands.push(action.payload);
        },
        removeBrand(state, action) {
            state.brands = state.brands.filter(brand => brand !== action.payload);
        },
        addDisease(state, action) {
            state.diseases.push(action.payload);
        },
        removeDisease(state, action) {
            state.diseases = state.diseases.filter(disease => disease !== action.payload);
        },
        addCategory(state, action) {
            state.categories.push(action.payload);
        },
        removeCategory(state, action) {
            state.categories = state.categories.filter(category => category !== action.payload);
        },
        clearFilter(state) {
            state.brands = [];
            state.diseases = [];
            state.categories = [];
        },
    },
});

export const {
    addBrand,
    removeBrand,
    addDisease,
    removeDisease,
    addCategory,
    removeCategory,
    clearFilter,
} = brandsAndDiseasesSlice.actions;

export default brandsAndDiseasesSlice.reducer;


export const selectBrands = state => state?.filter?.brands;

export const selectDiseases = state => state?.filter?.diseases;

export const selectCategories = state => state?.filter?.categories;

