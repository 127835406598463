import React from "react";
import "../../styles/privacyPolicy/Hero.css";
import heroimg from "../../Assets/Images/privacyPolicy/Privacy Policy 1.png";

const Hero = () => {
  return (
    <div className="privacyHero-main ">
      <div className="privacy-content p-cointener">
        <div className="hero-img ">
          <img src={heroimg} alt="heroimg" className="ppimg " />
        </div>
        <div className="hero-content p-subcointener">
          <div className="hero-text">
            <h1>
              <span>PRIVACY</span> POLICY
            </h1>
          </div>
          <p className="first-p">
            This Privacy Policy describes how Sahas Homeopathy collects, uses,
            and discloses your personal information. Your privacy is important
            to us, and we are committed to protecting your personal information
            and your right to privacy. By accessing or using the Website, you
            agree to the terms of this Privacy Policy. If you do not agree with
            the terms of this Privacy Policy, please do not access or use our
            Website.
          </p>

        </div>
      </div>
    </div>
  );
};

export default Hero;
