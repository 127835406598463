import React, { useEffect } from "react";
import Hero from "../components/medicalTest/Hero";
import MedicalLab from "../components/medicalTest/MedicalLab";
import MedicalServices from "../components/medicalTest/MedicalServices";
import MedicalTestBottom from "../components/medicalTest/MedicalTestBottom";
import { useDispatch } from "react-redux";
import { closeProcessCard } from "../store/labSlice/processCard";
import LabPackages from "../components/medicalTest/LabPackage";

function MedicalTest() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(closeProcessCard(true));
        window.scrollTo(0, 0);
    }, [dispatch]);
    return (
        <div>
            <Hero />
            <LabPackages />
            <MedicalLab />
            <MedicalServices />
            <MedicalTestBottom />
        </div>
    );
}

export default MedicalTest;
