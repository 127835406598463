import React, { useEffect, useState } from 'react'
import Hero from '../components/profileScreen/Hero'
import Order from '../components/profileScreen/Order'
import AddressUpdate from '../components/profileScreen/AddressUpdate';
import { useSelector } from 'react-redux';
import { selectReturnOrderLoading } from '../store/newBuyNow/returnOrder';
import Loader from '../components/loader/Loader';
import { userAddressModal } from '../store/signupSlice/userAddress';

function ProfileScreen() {
  const returnLoading = useSelector(selectReturnOrderLoading)
  const [isModelOpen, setIsModelOpen] = useState(false);
  const modalforAddress = useSelector(userAddressModal);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isModelOpen || modalforAddress) {
      document.body.style.overflow = "hidden";

    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModelOpen, modalforAddress]);



  return (
    <div>
      {
        (returnLoading) ? <Loader /> :
          <>
            <Hero />
            <AddressUpdate />
            <Order isModelOpen={isModelOpen} setIsModelOpen={setIsModelOpen} />
          </>
      }

    </div>
  )
}

export default ProfileScreen