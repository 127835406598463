import React from "react";
import "../../styles/privacyPolicy/ExplorePrinciple.css";

const ExplorePrinciple = () => {
  return (
    <div className="exploreprincipleC-main">
      <div className="exploreprinciple-content">
        <p className="heading">
          <span>Your Choices:</span>
        </p>
        <p>
          You may opt out of receiving marketing communications from us by
          following the unsubscribe instructions provided in the communication.
          Please note that even if you opt out, we may still send you
          non-promotional communications, such as those about your account or
          our ongoing business relations.
        </p>

        <p className="authorHeading"> Changes to this Privacy Policy:</p>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this. We encourage you to review this Privacy Policy
          periodically for any changes
        </p>

        <p className="authorHeading"> Contact Us:</p>
        <p>
          If you have any questions about this Privacy Policy or our practices
          regarding your personal information, please contact us. By using our
          Website, you consent to the collection and use of your personal
          information as described in this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default ExplorePrinciple;
