import React, { useEffect, useState } from "react";
import "../../styles/BuyNowPage/BuyNowPage.css";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineCurrencyRupee } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import {
    setShowForm,
} from "../../store/CartSlice/CartSlice";

import { useNavigate } from "react-router-dom";
import AddressForm from "./AddressForm";
import PopUpAddress from "./PopUpAddress";
import {
    decreaseItemBuyNowQuantity,
    increaseItemBuyNowQuantity,
    removeItemFromBuyNow,
    selectBuyNow,
} from "../../store/newBuyNow/newBuyNow";

import { setCurrentSizePotency } from "../../store/productsData/productsData";

function BuyNow() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const buyNow = useSelector(selectBuyNow);
    const [toggle, setToggle] = useState(false);
    const [isNewAddress, setIsNewAddress] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState(null);

    // handler for form data
    const [formData, setFormData] = useState({
        fullName: "",
        mobileNo: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        pinCode: "",
        city: "",
        state: "",
        country: "India",
    });

    // scroll to the top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // handler for delete 
    const handelDelete = (id) => {
        dispatch(removeItemFromBuyNow(id));
        if (buyNow.length <= 1) {
            navigate("/allproducts");
        }
    };

    // handler for increase and decrease
    const handleIncrease = (id) => {
        dispatch(increaseItemBuyNowQuantity(id));
    };

    const handleDecrease = (id) => {
        dispatch(decreaseItemBuyNowQuantity(id));
    };

    const [pricingDetails, setPricingDetails] = useState({
        totalMRP: 0,
        totalSellingPrice: 0,
        totalShippingPrice: 0,
        totalPrice: 0,
        totalPay: 0,
        totalDiscountPrice: 0,
    });

    useEffect(() => {
        const totalMRP = buyNow.reduce(
            (total, item) => total + item?.mrp * item?.quantity,
            0
        );
        const totalSellingPrice = buyNow.reduce(
            (total, item) => total + item?.sellingPrice * item?.quantity,
            0
        );

        let totalShippingPrice = 0;
        if (totalMRP < 500) {
            totalShippingPrice = 100;
        }
        const totalPrice = totalSellingPrice + totalShippingPrice;
        const totalDiscountPrice = (totalMRP - totalSellingPrice).toFixed(2);

        setPricingDetails({
            totalMRP,
            totalSellingPrice,
            totalShippingPrice,
            totalPrice,
            totalDiscountPrice,
            totalPay: totalPrice.toFixed(2),
        });
    }, [buyNow]);

    const handleToggle = () => {
        dispatch(setShowForm(false));
        setToggle(true);
        setIsNewAddress(false);
    };

    const [newFormData, setNewFormData] = useState({
        fullName: "",
        mobileNo: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        pinCode: "",
        city: "",
        state: "",
        country: "India",
    });

    const handleAddNewAddress = () => {
        setIsNewAddress(true);
        dispatch(setShowForm(true));
        setToggle(false);
        setNewFormData({
            fullName: "",
            mobileNo: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            pinCode: "",
            city: "",
            state: "",
            country: "India",
        });
    };

    // Function to handle the selection of an address
    const handleSelectAddress = (address) => {
        setSelectedAddress(address);
        setFormData(address);
        setToggle(false);
    };

    const setAddress = (address) => {
        setAddress(address);
    };

    // sticky background
    useEffect(() => {
        if (toggle) {
            document.body.style.overflow = "hidden";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [toggle]);

    
    return (
        <section className="my_order_sec">
            <div className="my_order_sec_left">
                <div>
                    <h2>Your Order</h2>
                </div>

                <div className="my_order_items">
                    {buyNow.map((item) => (
                        <div className="my_order_card" key={item?._id}>
                            <div className="my_order_img">
                                <img src={item?.imageObj?.url} alt="medicine" />
                            </div>

                            <div className="my_order_text">
                                <p onClick={() => {
                                    dispatch(setCurrentSizePotency({ size: item?.size, potency: item?.potency }))
                                    navigate(
                                      `/singleproduct/${item.productId}`
                                    );
                                }}>{item?.brand} {item?.productName} {item?.potency} {item?.size}</p>

                                <div className="my_order_qut">
                                    <p>Qty-{item?.quantity}</p>
                                    <div className="my_order_qut_btn">
                                        <span onClick={() => handleIncrease(item?._id)}>+</span>
                                        <span>{item?.quantity}</span>
                                        <span onClick={() => handleDecrease(item?._id)}>-</span>
                                    </div>
                                </div>

                                <div>
                                    <p className="my_order_price">
                                        Price- <span>{item?.sellingPrice.toFixed(2)}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="my_order_dlt_icon">
                                <p onClick={() => handelDelete(item?._id)}>
                                    <MdDeleteOutline />
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="my_order_pricing">
                    <h3>Total Price</h3>

                    <div className="my_order_price_info">
                        <span>Subtotal</span>
                        <span>
                            {" "}
                            <MdOutlineCurrencyRupee />
                            {pricingDetails.totalSellingPrice.toFixed(2)}
                        </span>
                    </div>

                    <div className="my_order_price_info">
                        <span>Shipping Cost</span>
                        <span>
                            {" "}
                            <MdOutlineCurrencyRupee />
                            {/* {item.mrp} */}
                            {pricingDetails.totalMRP < 500
                                ? (pricingDetails.totalShippingPrice).toFixed(2)
                                : 0}
                        </span>
                    </div>

                    <div className="my_order_price_total">
                        <span>Total Price</span>
                        <span>
                            {" "}
                            <MdOutlineCurrencyRupee />
                            {pricingDetails.totalPay}
                        </span>
                    </div>
                </div>
            </div>
            <AddressForm
                pricingDetails={pricingDetails}
                selectedAddress={selectedAddress}
                formData={formData}
                newFormData={newFormData}
                isNewAddress={isNewAddress}
                setNewFormData={setNewFormData}
                handleAddNewAddress={handleAddNewAddress}
                handleToggle={() => handleToggle()}
            />
            {toggle ? (
                <PopUpAddress
                    onClose={() => {setToggle(false); setIsNewAddress(true)}}
                    onSelectAddress={handleSelectAddress}
                    showAddressForm={() => dispatch(setShowForm(true))}
                    setAddress={setAddress}
                    setIsNewAddress={setIsNewAddress}
                />
            ) : null}
        </section>
    );
}

export default BuyNow;
