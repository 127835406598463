import React from "react";
import { useNavigate } from "react-router-dom";

function BlogCard({ allBlog }) {
    const navigate = useNavigate();

    // show limited tect on screen
    const getShortenedDescription = (text) => {
        const words = text.split(" ");
        return words.slice(0, 30).join(" ");
    };


    // show limited text on screen
    const getShortenedHeading = (text) => {
        const words = text.split(" ");
        return words.slice(0, 12).join(" ");
    };

    return (
        <>
            <div className="AllBLog_Middle">
                {allBlog?.map((blog, index) => (
                    <div className="AllBlog_Card" key={index}>
                        <img src={blog?.images[0]?.imageObj[0]?.url} alt="" />
                        <div className="AllBLog_Text">
                            <h4>{getShortenedHeading (blog?.blogTitle)}</h4>
                            <p>{getShortenedDescription(blog?.shortDiscription)}</p>
                            <button
                                onClick={() =>
                                    navigate(`/blogdescription/${blog?._id}`)
                                }
                            >
                                Read More
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default BlogCard;
