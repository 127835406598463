import React from "react";
import { RxCross2 } from "react-icons/rx";
import "../../styles/ProfileScreen/CommomPopUp.css";

function CommonPopUp({ onClose, commonData }) {
    const Homeadd = commonData?.address
    const Labadd = commonData?.lab?.location
    return (
        <div className="order-history-container">
            <div className="commonPupUp_heading_data_div">
                <div className="commonPupUp_heading_div">
                    <h1>Details Page</h1>
                    <h5 onClick={onClose}>
                        <RxCross2 />
                    </h5>
                </div>

                <div className="commonPopUp_content_div">
                    {!commonData?.doctor && (
                        <>
                            <p>
                                Name:{" "}
                                <span>{commonData?.address?.fullName}</span>
                            </p>
                            <p>
                                Mobile No:{" "}
                                <span>{commonData?.address?.mobileNo}</span>
                            </p>
                            <p>
                                Lab Name: <span>{commonData?.lab?.name}</span>
                            </p>
                            <p>
                                Lab services:{" "}
                                <span>
                                    {commonData?.labservice?.serviceName}
                                </span>
                            </p>

                            <p>
                                cost: <span>{commonData?.finalPrice}</span>
                            </p>
                            <p>
                                Date: <span>{commonData?.date}</span>
                            </p>
                            <p>
                                Time: <span>{commonData?.time}</span>
                            </p>
                            {
                                commonData?.atplace === "home" ?
                                    <>
                                        <p>
                                            Address ({commonData?.atplace}): <span>{Homeadd?.addressLine1},{Homeadd?.addressLine2},{Homeadd?.city},{Homeadd?.state},{Homeadd?.pincode}</span>
                                        </p>
                                    </> :
                                    <>
                                     <p>
                                            Address ({commonData?.atplace}): <span>{Labadd?.addressLine},{Labadd?.city},{Homeadd?.city},{Labadd?.state}</span>
                                        </p>
                                    </>
                            }



                            <p>
                                Place: <span>{commonData?.atplace}</span>
                            </p>
                            <p>
                                Payment method: <span>{commonData?.type==="cod"?"Cash on delivery":"Online(paid)"}</span>
                            </p>
                        </>
                    )}

                    {commonData?.doctor && (
                        <>
                            <p>
                                Name: <span>{commonData?.name}</span>
                            </p>
                            <p>
                                Mobile No: <span>{commonData?.phoneNo}</span>
                            </p>
                            <p>
                                Email: <span>{commonData?.email}</span>
                            </p>
                            <p>
                                doctor: <span>{commonData?.doctor}</span>
                            </p>
                            <p>
                                message <span>{commonData?.message}</span>
                            </p>
                            <p>
                                Date: <span>{commonData?.date}</span>
                            </p>
                            <p>
                                Time: <span>{commonData?.time}</span>
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CommonPopUp;
