import React, { useState, useEffect } from "react";
import "../../styles/Home/Testinomials.css";
import img1 from "../../Assets/Images/Rectangle 25.png";
import img2 from "../../Assets/Images/Rectangle 25.png";
import img3 from "../../Assets/Images/Rectangle 25.png";
import img4 from "../../Assets/Images/Rectangle 25.png";

const testimonialsData = [
  {
    photo: img1,
    content: {
      title: "Deepti Pant",
      text: "Best homeopathic clinic in Haldwani... Thank you for being the dedicated, thoughtful, and compassionate doctor that you are ??? You always go above and beyond and work tirelessly towards a healthy outcome. I feel so happy ... safe....and.... blessed to know you and have you as my doctor.",
    },
  },
  {
    photo: img2,
    content: {
      title: " Shrishti Kumari",
      text: "The best homeopathic doctor I have ever met - I meant really i was surprised with the result because results that was amazing according the problem, I was having from the 2 year... but I got cure from Sahas Homeopathic i got results very quick and that was such blessing for me.. Thank u doctor.",
    },
  },
  {
    photo: img3,
    content: {
      title: " Himank Pandey",
      text: "There are not enough words to thank you for the incredible care that you and your team provided to me & my family. Your friendship, care and compassion will never be forgotten.",
    },
  },
  {
    photo: img4,
    content: {
      title: "Rahul Roy",
      text: "Very good experience everytime I visited Sahas Homeopathy Clinic. Extremely satisfied with the treatment and above it the friendly advice of Dr. Pandey. - Neeraj Bisht Very nice and very skilled doctor and experienced and polite too. ",
      // I was ill since 12 years of respiratory and digestive problem but after treatment from this clinic I got permanent relief from this problem. If you have any health issue please visit this clinic at least once I assure that you will I go with the complete solution....
    },
  },
  {
    photo: img4,
    content: {
      title: "Hemant Kumar",
      text: "Excellent diagnosis of my disease & best medicine prescribed by Dr. Naveen Pandey ji & best services by his homeopathic Sahas Clinic in Uttarakhand & Uttaar Pradesh",
    },
  },
  {
    photo: img4,
    content: {
      title: "Laxmi Gangwar",
      text: "I was suffering from liver disease from last 2 years. My brother suggest me sahas homeopathy I visited here and meet Dr Pandey. He gave me homeopathic medicine from these medicine I got fully cured.. I thank to Dr Pandey and sahas homeopathy for this. And recommend homeopathic treatment to you all.",
    },
  },
];

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonialsData.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const handlePagePointClick = (index) => {
    setCurrentTestimonial(index);
  };

  return (
    <div className="testimonials">
      <div className="testimonials_card">
        <div className="testimonials-photo">
          <img src={testimonialsData[currentTestimonial].photo} alt="" />
        </div>
        <div className="testimonials-content">
          <h3>{testimonialsData[currentTestimonial].content.title}</h3>
          <p className="testimonials-content-para">
            {testimonialsData[currentTestimonial].content.text}
          </p>
          <button>Read More</button>
        </div>
        <div className="testimonials-page-points">
          {testimonialsData.map((_, index) => (
            <span
              key={index}
              className={
                index === currentTestimonial
                  ? "testimonials-active-point"
                  : "testimonials-page-point"
              }
              onClick={() => handlePagePointClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
