import React from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import "../../styles/layout/CommonCard.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToWishlist,
  addItemToWishlistAsync,
  removeItemFromWishlist,
  wishlistItemDeleteAsync,
} from "../../store/newWishlist/newWishlist";
import { addItemToCart, addItemToCartAsync } from "../../store/newCart/newCart";
import { setCurrentSizePotency } from "../../store/productsData/productsData";
import { toast } from "react-toastify";

function CommonCard({ medicine, index, onBuyNow }) {
  const newWishlist = useSelector((state) => state?.newWishlist?.newWishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const token = localStorage.getItem("authToken");

  // handle wishlist
  const handleWishlist = async (item) => {
    if (token) {
      if (item.isInWishlist) {
        const wishlistItem = newWishlist.find(
          (wishlistItem) =>
            wishlistItem.productVariantId === item.productVariantId
        );
        if (wishlistItem) {
          const wishlistId = wishlistItem._id; // Retrieve the wishlistId
          // Dispatch the action to delete the item from the wishlist
          await dispatch(wishlistItemDeleteAsync(wishlistId));
        }
      } else {
        await dispatch(addItemToWishlistAsync(item));
      }
    } else {
      if (item.isInWishlist) {
        dispatch(removeItemFromWishlist(item.productVariantId));
      } else {
        dispatch(addItemToWishlist(item));
      }
    }
  };

  // handle medicine  button
  const handleCartButton = async (item) => {
    if (token) {
      if (item?.inventory >= 1) {
        await dispatch(addItemToCartAsync(item));

      } else {
        toast.error("this product is out of stock currently")
      }
    } else {
      if (item?.inventory >= 1) {
        dispatch(addItemToCart(item));
      } else {
        toast.error("this product is out of stock currently")
      }
    }
  };


  return (
    <div className="medicine-section-bottom-box" key={index}>
      <div className="innerProductBox">
        <div className="discountCont">{medicine?.discount}% OFF</div>
        <div className="commonCard-wishlistIconsHeart-positioning">
          {medicine?.isInWishlist ? (
            <IoHeart
              className="wishlistIconsHeart"
              onClick={() => handleWishlist(medicine)}
            />
          ) : (
            <IoHeartOutline
              className="wishlistIconsHeartOutline"
              onClick={() => handleWishlist(medicine)}
            />
          )}
        </div>
        <div className="Medical-commonCard-img-content-button">
          <div className="productImageContainer">
            <img src={medicine?.imageObj?.url} alt="" />
          </div>
          <div className="medicine-commonCard-content-mrp-discount">
            <div className="medicine-commonCard-productName">
              <p
                onClick={() => {
                  dispatch(setCurrentSizePotency({ size: medicine.size, potency: medicine.potency }))
                  navigate(
                    `/singleproduct/${medicine.productId}`
                  );

                }}
              >
                {capitalizeFirstLetter(
                  `${medicine?.brand} ${medicine?.productName} ${medicine?.potency !== "N/A" ? medicine?.potency : ""} (${medicine?.size})`
                )}
              </p>
            </div>
            <div className="medicine-commonCard-sellingPrice">
              <h4>&#x20B9;{medicine?.sellingPrice}</h4>
            </div>
            <div className="mrpCut">
              MRP <span className="needtowarp"> &#x20B9;{medicine?.mrp}</span>{" "}
            </div>
          </div>

          <button
            className="actionButtons"
            onClick={() => {
              handleCartButton(medicine);
            }}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}

export default CommonCard;
