import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Gallery from "./pages/Gallery";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductCategory from "./pages/ProductCategory";
import TermsAndConditions from "./pages/TermsAndConditions";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import SingleProduct from "./pages/SingleProduct";
import AllProducts from "./pages/AllProducts";
import Blog from "./pages/Blog";
import BlogDescription from "./pages/BlogDescription";
import MedicalTest from "./pages/MedicalTest";
import LabDescription from "./pages/LabDescription";
import Signup from "./components/LoginSignup/Signup";
import Login from "./components/LoginSignup/Login";
import ProfileScreen from "./pages/ProfileScreen";
import AddToCart from "./components/addOrder/AddToCart";
import AddToWishList from "./components/addOrder/AddToWishList";
import BuyNow from "./components/buyNowPage/BuyNow";
import FloatingIcon from "./components/layouts/FloatingIcon";
import SearchBar from "./Constents/SearchBar";
import RefundPolicy from "./pages/RefundPolicy";

/* test */
import OrderConfirmed from "./components/buyNowPage/OrderConfirmed";
import { useEffect } from "react";
import axios from "axios";
import { searchProductApi, userCount } from "./Constents/apiAxios";
import { useDispatch, useSelector } from "react-redux";
import { saveSearchData } from "./store/categorySlice/CategorySlice";
import {
  getForgotModal,
  getLoginModal,
  getSignupModal,
  setForgotModal,
  setLoginModal,
  setSignupModal,
} from "./store/signupSlice/signupSlice";
import ForgotPassword from "./components/LoginSignup/ForgotPassword";
import CantFindMedicine from "./components/layouts/CantFindMedicine";

function App() {
  const dispatch = useDispatch();
  const loginModal = useSelector(getLoginModal);
  const signupModal = useSelector(getSignupModal);
  const forgotModal = useSelector(getForgotModal);
  const location = useLocation(); // useLocation hook to get the current route
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(searchProductApi);
        dispatch(saveSearchData(response?.data?.allNames));

        // Fetch user count data
        await axios.post(userCount);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  });

  useEffect(() => {
    if (loginModal || signupModal || forgotModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to remove the style when the component is unmounted or loginModal changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loginModal, signupModal, forgotModal]);

  // Effect to close modals on route change
  useEffect(() => {
    if (loginModal) {
      dispatch(setLoginModal(false));
      dispatch(setForgotModal(false));
    }
    if (signupModal) {
      dispatch(setSignupModal(false));
      dispatch(setForgotModal(false));
    }
    if (forgotModal) {
      dispatch(setSignupModal(false));
      dispatch(setLoginModal(false));
    }
  }, [location, dispatch]);

  return (
    <>
      <Header />
      {loginModal && <Login />}
      {signupModal && <Signup />}
      {forgotModal && <ForgotPassword />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/productcategory" element={<ProductCategory />} />
        <Route path="/termsandcondition" element={<TermsAndConditions />} />
        <Route path="/singleproduct/:productId" element={<SingleProduct />} />
        <Route path="/allproducts" element={<AllProducts />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdescription/:id" element={<BlogDescription />} />
        <Route path="/medicaltest" element={<MedicalTest />} />
        <Route path="/lab-description/:labId" element={<LabDescription />} />

        <Route path="/profilescreen" element={<ProfileScreen />} />
        <Route path="/cart" element={<AddToCart />} />
        <Route path="/wishlist" element={<AddToWishList />} />
        <Route path="/buynow" element={<BuyNow />} />

        <Route path="/orderConfirmed" element={<OrderConfirmed />} />
        <Route path="/searchBar" element={<SearchBar />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />
      </Routes>
      <CantFindMedicine/>
      <FloatingIcon />
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
