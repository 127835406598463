import React, { useCallback, useEffect } from "react";
import "../../styles/Blogs/AllBlog.css";
import BlogCard from "./BlogCard";
import { Bounce } from "react-awesome-reveal";

function AllBlog({
    allBlogData,
    setPageNo,
    totalPages,
    pageNo,
    productLoading,
}) {
    const cleanup = useCallback(() => {
        setPageNo(1);
    }, [setPageNo]);

    useEffect(() => {
        cleanup();
    }, [cleanup]);

    return (
        <section className="AllBlog_Container">
            <div className="AllBlog_Content">
                <div className="AllBlog_Head">
                    <Bounce>
                        <h2>All Blogs</h2>
                    </Bounce>
                </div>
                <BlogCard allBlog={allBlogData} />
            </div>
            {totalPages !== pageNo && (
                <button
                    className="allProductBtn"
                    onClick={
                        !productLoading
                            ? () => setPageNo((prevItems) => prevItems + 1)
                            : undefined
                    }
                >
                    {productLoading ? "Loading...." : "View More"}
                </button>
            )}
        </section>
    );
}

export default AllBlog;
