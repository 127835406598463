import React from "react";
import "../../styles/AboutUs/ExploringThePrinciples.css";
import img from "../../Assets/Images/About Us/Asset 2 1.png";
import { Bounce, Fade } from "react-awesome-reveal";

const ExploringThePrinciples = () => {
  return (
    <div className="about-ETP">
      <div className="about-ETP-left">
        <img src={img} alt="" />
      </div>
      <div className="about-ETP-right">
        <Bounce>
          <div className="ExploringThePrinciples-Legacy-Heading">
            <h1>
              <span className="secondaryTextColor">
                THREE DECADES OF LEGACY WITH TWO{" "}
              </span>
              <span className="primaryTextColor">
                GENERATIONS WORKING FOR HOMEOPATHY.
              </span>
            </h1>
          </div>
        </Bounce>
        {/* image to show only mobile devices */}
        <div className="mobile-view">
          <div className="resposiveimg">
            <img src={img} alt="" />
          </div>
        </div>
        <Fade direction="down">
          <div className="fade-down-paragraph">
            <p>
              As Dr. NC Pandey's work laid the foundation for a healthier
              Kumaon, the torch of his vision was passed on to the next
              generation. In 2011, Dr. Khushboo Pandey, inspired by her father's
              dedication, pursued her studies in homeopathy at Swasth Kalyan
              Homeopathy College. Armed with knowledge and passion, carrying
              forward her father's legacy with compassion and expertise.
              Following in his footsteps, Dr. Yash Pandey, in 2015, embarked on
              his own journey in homeopathy, graduating from Dr. MPK Homeopathic
              Medical College. Together, the Pandey family united in their
              mission to alleviate suffering and promote wellness through the
              principles of homeopathy. With each passing year, their collective
              efforts continue to shape a healthier, happier future for Kumaon
            </p>

            <p style={{ padding: "1% 0%" }}>
              After all these efforts Dr. NC Pandey didn’t stop their In 2014 he
              and his brother Dr. DC Pandey combined their expertise to develop
              unique formulations aimed at addressing a wide range of health
              concerns. Today, Sahas Homeopathy stands as a beacon of innovation
              and trust, offering patients access to safe, effective, and
              personalized solutions rooted in the principles of homeopathy.
              Their dedication to excellence and their unwavering belief in the
              power of natural healing continue to guide Sahas Homeopathy on its
              mission to promote health and wellness for all.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default ExploringThePrinciples;
