import { useEffect } from "react";
import Hero from "../components/gallery/Hero"
import OurGallery from "../components/gallery/OurGallery";
function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return <div>
    <Hero/>
    <OurGallery/>
  </div>;
}

export default Gallery;
