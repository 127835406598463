import React from "react";
import "../../styles/productCategory/MedByBrand.css";
import Slider from "react-slick";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { selectCategory } from "../../store/categorySlice/CategorySlice";
import { useNavigate } from "react-router-dom";
import { getCategoriesByBrand } from "../../store/categories/categories";
import { fetchAllProductDataByCategory } from "../../store/productsData/productsData";

const MedByBrand = () => {
  const categories = useSelector(getCategoriesByBrand)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CustomPrevButton = ({ onClick }) => (
    <button className="prevButton prevbrand" onClick={onClick}>
      <IoIosArrowRoundBack className="prevIcon" />
    </button>
  );

  const CustomNextButton = ({ onClick }) => (
    <button className="nextButton nextbrand" onClick={onClick}>
      <IoIosArrowRoundForward className="prevIcon" />
    </button>
  );

  // for capital letter
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleCategory = async (item) => {

    const categoryData = `${item.categoryName}`;
    dispatch(selectCategory(categoryData));
    navigate("/allproducts");
    await dispatch(
      fetchAllProductDataByCategory(categoryData)
    );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="brandCategories">
      <h1>
        <span>Medicines </span>By Brand
      </h1>
      <div className="brandCarousal">
        <Slider {...settings1}>
          {categories?.map((item, index) => (
            <div>
              <div key={index} className="brandSlides">
                <div>
                  <img src={item.imageObj.url} alt="" />
                </div>
                <p>{capitalizeFirstLetter(item.categoryName)}</p>
                <button
                  onClick={() => {
                    handleCategory(item);
                  }}
                >
                  View More
                </button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default MedByBrand;
