import React from "react";
import "../../styles/AllProducts/FilterPopUp.css";
import { RxCross1 } from "react-icons/rx";

function FilterPopUp({
  closeModal,
  allBrand,
  toggleBrandSelection,
  selectedBrands,
}) {
  return (
    <div className="filter_pop_up">
      <div onClick={closeModal} className="filter_icon">
      <RxCross1 className="filter_icon_cross"/>
      </div>


  <div className="filter_page_list">
      {allBrand.map((brand) => {
        const brandString = String(brand?.categoryName);
        return (
          <div className="filter_pop_up_box" key={brandString}>
            <input
              type="checkbox"
              id={`brand-${brand?.categoryName}`}
              checked={selectedBrands.includes(brand?.categoryName)}
              onChange={() => toggleBrandSelection(brand?.categoryName)}
            />
            <label htmlFor="">{brand?.categoryName}</label>
          </div>
        );
      })}

</div>


    </div>
  );
}

export default FilterPopUp;
