import React, { useEffect, useState } from "react";
import Hero from "../components/blogs/Hero";
import TrandingBlog from "../components/blogs/TrandingBlog";
import AllBlog from "../components/blogs/AllBlog";
import Loader from "../components/loader/Loader";
import axios from "axios";
import { allBlogApi } from "../Constents/apiAxios";

function Blog() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [productLoading, setProductLoading] = useState(false);

    useEffect(() => {
        const getAllBlogs = async () => {
            setProductLoading(true);
            try {
                const response = await axios.get(`${allBlogApi}?page=${pageNo}`);
                setData(prevData => [...prevData, ...response?.data?.allBlogs]);
                setTotalPages(response?.data?.totalPages)
                setProductLoading(false);
            } catch (error) {
                console.log("all blog fetch error", error);
                setProductLoading(false);
            }
        };
        getAllBlogs();
    }, [pageNo]);

    return (
        <div>
        {productLoading && pageNo === 1 ? (
            <Loader />
        ) : (
            <>
                <Hero />
                <TrandingBlog allBlogData={data.slice(0, 4)} />
                <AllBlog
                    allBlogData={data}
                    setPageNo={setPageNo}
                    totalPages={totalPages}
                    pageNo={pageNo}
                    productLoading={productLoading}
                />
            </>
        )}
    </div>
    );
}

export default Blog;
