import React from "react";
import "../../styles/medicalTest/MedicalLab.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getLabs } from "../../store/labSlice/labSlice";
import { useNavigate } from "react-router-dom";
import { fetchSingleLab } from "../../store/labSlice/singleLab";

function MedicalLab() {
  const dispatch = useDispatch();
  const labData = useSelector(getLabs);
  const navigate = useNavigate();

  const CustomPrevButton = ({ onClick }) => (
    <button className="prevButton" onClick={onClick}>
      <IoIosArrowRoundBack className="prevIcon" />
    </button>
  );

  const CustomNextButton = ({ onClick }) => (
    <button className="nextButton" onClick={onClick}>
      <IoIosArrowRoundForward className="prevIcon" />
    </button>
  );
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // handle book lab

  const handleBook = (item) => {
    dispatch(fetchSingleLab(item?._id));
    navigate(`/lab-description/${item?._id}`);
  };
  return (
    <>
    {labData && labData?.length > 0 && 
    
    <section className="medical_test_lab">
      <div className="medical_lab_content">
        <div className="lab_head">
          <h2>Select Your Lab</h2>
        </div>
        <Slider {...settings}>
          {labData?.map((item, index) => (
            <div className="lab_item">
              <div className="lab_card" key={index}>
                <div className="lab_card_left">
                  <div className="labImageContainer">
                    <img src={item?.labImages} alt="" />
                  </div>
                  <div className="lab_card_item">
                    <p>{item?.labName}</p>
                    <span>{item?.labDescription?.substring(0, Math.floor(item?.labDescription?.length / 2))}</span>
                  </div>
                </div>

                <div className="lab_card_right">
                  <div className="lab_card_hide">
                    <div className="lab_hide_text">
                      <h3>{item?.labName}</h3>
                      <p>
                        <span>Distance</span> : 100km{" "}
                      </p>
                      <p>
                        <span>Address =</span> Near Milan Sweets Heera Nager
                        Maukani, Haldwani Uttarakhand 261339
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleBook(item);
                      }}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
    }


    </>

  );
}

export default MedicalLab;