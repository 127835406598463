import React, { useState } from "react";
import "../../styles/ProfileScreen/Returnorder.css";
import { useDispatch } from "react-redux";
import { returnOrderAsync } from "../../store/newBuyNow/returnOrder";
import { FaPlus } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";

const ReturnOrder = ({ setReturnmodal, selectedOrder }) => {
    const dispatch = useDispatch();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [step, setStep] = useState(1); // Step 1: Select Products, Step 2: Return Form

    const [images, setImages] = useState([]);
    const [returnMessage, setReturnMessage] = useState("");
    const [previewImages, setPreviewImages] = useState([]);

    console.log("selected order", selectedOrder)
    // Step 1: Handling product selection
    const handleProductSelect = (product) => {
        console.log("product s", product)
        setSelectedProducts((prevSelected) =>
            prevSelected.some(
                (p) => p.productVariantId === product.productVariantId
            )
                ? prevSelected.filter(
                    (p) => p.productVariantId !== product.productVariantId
                )
                : [
                    ...prevSelected,
                    {
                        productId: product?.productId,
                        productVariantId: product?.productVariantId,
                        quantity: product?.quantity,
                        discount: product?.discount,
                        productImage: product?.productImage,
                        mrp: product?.mrp,
                        potency: product?.potency,
                        size: product?.size,
                        productName: product?.productName,
                        brand: product?.brand,
                        sellingPrice: product?.sellingPrice
                    },
                ]
        );
    };

    console.log("SELEDCRED  PRODUT", selectedProducts);

    // Step 2: Handling return message and image uploads
    const handleReturnMessageChange = (event) => {
        setReturnMessage(event.target.value);
    };

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        setImages((prevFiles) => [...prevFiles, ...files]);

        const previews = files.map((file) => URL.createObjectURL(file));
        setPreviewImages((prevPreviews) => [...prevPreviews, ...previews]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("returnMessage", returnMessage);
        formData.append("MongoOrderId", selectedOrder?._id); // assuming `id` is MongoOrderId
        formData.append("OrderId", selectedOrder?.OrderId);
        formData.append("shippingPrice", selectedOrder?.shippingPrice);
        formData.append("type", selectedOrder?.type);
        formData.append("paid", selectedOrder?.paid);
        formData.append("orderstatus", selectedOrder?.orderstatus);
        formData.append("finalPrice", selectedOrder?.finalPrice);

        // Append each selected product individually
        selectedProducts.forEach((product, index) => {
            formData.append(`products[${index}][productId]`, product.productId);
            formData.append(`products[${index}][discount]`, product.discount);
            formData.append(`products[${index}][productImage]`, product.productImage);
            formData.append(`products[${index}][mrp]`, product.mrp);
            formData.append(`products[${index}][potency]`, product.potency);
            formData.append(`products[${index}][size]`, product.size);
            formData.append(`products[${index}][productName]`, product.productName);
            formData.append(`products[${index}][brand]`, product.brand);
            formData.append(`products[${index}][sellingPrice]`, product.sellingPrice);
            formData.append(
                `products[${index}][productVariantId]`,
                product.productVariantId
            );
            formData.append(
                `products[${index}][quantity]`,
                product.quantity
            );
        });

        images.forEach((image) => {
            formData.append("images", image);
        });

        await dispatch(returnOrderAsync(formData));

        setReturnmodal(false);
    };

    return (
        <>
            <div className="return-order-container">
                <div className="return-order-card">
                    <div className="header-container">
                        <h1>Return </h1>
                        <h5 onClick={() => setReturnmodal(false)}>
                            <RxCross1 />
                        </h5>
                    </div>

                    {step === 1 ? (
                        <div className="select-products-container">
                            <h3>Select Products to Return</h3>
                            <div className="Order_history_pop_up_second_div">
                                {selectedOrder?.products?.map((product) => (
                                    <div
                                        key={product.productVariantId}
                                        className="Order_history_card"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedProducts.some(
                                                (p) =>
                                                    p.productVariantId ===
                                                    product.productVariantId
                                            )}
                                            onChange={() =>
                                                handleProductSelect(product)
                                            }
                                            style={{ cursor: "pointer" }}
                                        />
                                        <div className="Order_history_card_img_box">
                                            <img
                                                src={product.productImage}
                                                alt=""
                                                className="Order_history_card_img_box_img"
                                            />
                                        </div>
                                        <span className="productNameReturn">
                                            <div className="productValueDiv">
                                                {product.productName}
                                            </div>
                                        </span>
                                        <span className="quantityReturn">
                                            <div className="productValueDiv">
                                                {product.quantity}
                                            </div>
                                        </span>
                                        <span className="sellingPriceReturn">
                                            <div className="productValueDiv">
                                                {product.sellingPrice}{" "}
                                                <span>Rs</span>
                                            </div>
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <button
                                onClick={() => setStep(2)}
                                disabled={selectedProducts.length === 0}
                            >
                                Next
                            </button>
                        </div>
                    ) : (
                        <div className="return-op">
                            <div className="text">
                                <textarea
                                    placeholder="Write your comment"
                                    value={returnMessage}
                                    onChange={handleReturnMessageChange}
                                ></textarea>

                                <div className="image_upload_container">
                                    <div className="return_img_iup_div">
                                        <label htmlFor="file">
                                            <input
                                                type="file"
                                                id="file"
                                                onChange={handleImageChange}
                                                style={{ display: "none" }}
                                            />
                                            <FaPlus className="return_img_iup_div_plush_sign" />
                                        </label>
                                    </div>
                                    {previewImages.length > 0 &&
                                        previewImages.map((image, index) => (
                                            <div
                                                key={index}
                                                className="return_img_iup_div"
                                            >
                                                <img
                                                    src={image}
                                                    alt=""
                                                    className="return_order_img"
                                                />
                                            </div>
                                        ))}
                                </div>

                                <div className="button-container">
                                    <button onClick={handleSubmit}>Send</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ReturnOrder;
