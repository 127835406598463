import "../../styles/layout/Header.css";
import { useNavigate } from "react-router-dom";
import Headroom from "react-headroom";
import logo from "../../Assets/Images/logo/head.png";
import AppointmentFom from "../appointmentForm/AppointmentFom";
import { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { IoIosHeartEmpty } from "react-icons/io";
import { FiShoppingCart } from "react-icons/fi";
import { logOutAction } from "../../store/actions/logIn/LogOutAction";
import { selectCategory } from "../../store/categorySlice/CategorySlice";
import {
  setFilter,
  setLoadingQuery,
  setSearchQuery,
} from "../../store/SearchQuery/SearchQuerySlice";
import { setLabStep, setShowForm } from "../../store/CartSlice/CartSlice";
import { closeProcessCard } from "../../store/labSlice/processCard";
import { toast } from "react-toastify";
import { fetchUserCartAsync } from "../../store/newCart/newCart";
import { fetchUserWishlistAsync } from "../../store/newWishlist/newWishlist";
import OfflineForm from "../appointmentForm/OfflineForm";
import {
  fetchAllProductsData,
  setCurrentCategory,
} from "../../store/productsData/productsData";
import {
  setLoginModal,
  setSignupModal,
} from "../../store/signupSlice/signupSlice";
import { RxCross1 } from "react-icons/rx";
import { closeProcessForm } from "../../store/labSlice/labBooking";


function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState("HOME");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otherModalOpen, setOtherModalOpen] = useState(false);
  const [hameburger, setHameburger] = useState(false)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const user = useSelector((state) => state.login.user);
  const token = localStorage.getItem("authToken");

  const cart = useSelector((state) => state.newCart.newCart);
  const wishlist = useSelector((state) => state.newWishlist.newWishlist);

  /// function for close nav bar in mobile view
  const navRef = useRef(null);
  
  useEffect(() => {
    const handleClickOUtSide = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        hideNavLinks();
      }
    };

    document.addEventListener("mousedown", handleClickOUtSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOUtSide);
    };
  }, []);

  useEffect(() => {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case "/":
        setActivePage("HOME");
        break;
      case "/allproducts":
        setActivePage("allproducts");
        break;
      case "/productcategory":
        setActivePage("prodByCat");
        break;
      case "/gallery":
        setActivePage("gallery");
        break;
      case "/blog":
        setActivePage("blogs");
        break;
      case "/medicaltest":
        setActivePage("labs");
        break;
      case "/wishlist":
        setActivePage("wish");
        break;
      case "/profilescreen":
        setActivePage("profilescreen");
        break;
      case "/cart":
        setActivePage("cart");
        break;
      default:
        setActivePage("");
    }
  }, [navigate, dispatch]);

  const getUserFirstLetter = () => {
    if (user && user?.firstName) {
      return user?.firstName.charAt(0).toUpperCase();
    }
    return "";
  };

  const handleLogOut = () => {
    dispatch(logOutAction());
    dispatch(setShowForm(false));
    navigate("/");
    setIsDropdownOpen(!isDropdownOpen);
    HmeBurgerMenu();
  };

  const openModal = () => {
    if (token) {
      setIsModalOpen(true);
    } else {
      toast.success("please log-in");
      dispatch(setLoginModal(true));
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const secondModalOpen = () => {
    setOtherModalOpen(true);
  };
  const closeSecondModal = () => {
    setOtherModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (isModalOpen || otherModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen, otherModalOpen]);

  /* Hmae burger Menu */
  const HmeBurgerMenu = () => {
    const navitems = document.querySelector(".headerText");
    navitems.classList.toggle("toggle");
    setHameburger(true)
  };

  /* click links to slide hide  */
  const NavlinkHide = () => {
    const Hidenav = document.getElementById("hideLinks");
    Hidenav.classList.remove("toggle");
    setHameburger(false)
  };

  const Navlink = document.querySelectorAll(".hideLink");
  Navlink.forEach((res) => {
    res.addEventListener("click", (e) => {
      e.preventDefault();
      NavlinkHide();
    });
  });

  /* click links to slide hide */
  const hideNavLinks = () => {
    const Hidenav = document.querySelector(".headerText");
    Hidenav.classList.remove("toggle");
    setHameburger(false)
  };

  return (
    <>
      <Headroom style={{ zIndex: "5" }}>
        <header className="header">
          <div className="headerIcon">
            <div
              onClick={() => {
                navigate("/");
                dispatch(setShowForm(false));
                dispatch(setLabStep(0));
                dispatch(setLoginModal(false));
                dispatch(setSignupModal(false));
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={logo} alt="" />

            </div>
          </div>
          <div className="headerText" id="hideLinks" ref={navRef}>
            <ul>
              <li
                onClick={() => {
                  navigate("/");
                  setActivePage("HOME");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
                className="hideLink"
              >
                <h4
                  style={{
                    color: activePage === "HOME" ? "#196EA4" : "",
                  }}
                >
                  Home
                </h4>
              </li>
              <li
                onClick={() => {
                  dispatch(setCurrentCategory("normalProduct"));
                  dispatch(fetchAllProductsData());
                  dispatch(setSearchQuery(""));
                  dispatch(selectCategory("All Products"));
                  dispatch(setLoadingQuery(true));
                  dispatch(setFilter(false));
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  navigate("/allproducts");
                  setActivePage("allproducts");
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
                className="hideLink"
              >
                <h4
                  style={{
                    color: activePage === "allproducts" ? "#196EA4" : "",
                  }}
                >
                  All Products
                </h4>
              </li>
              <li
                onClick={() => {
                  navigate("/productcategory");
                  setActivePage("prodByCat");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
                className="hideLink"
              >
                <h4
                  style={{
                    color: activePage === "prodByCat" ? "#196EA4" : "",
                  }}
                >
                  Products by Category
                </h4>
              </li>
              <li
                onClick={() => {
                  navigate("/gallery");
                  setActivePage("gallery");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
                className="hideLink"
              >
                <h4
                  style={{
                    color: activePage === "gallery" ? "#196EA4" : "",
                  }}
                >
                  Gallery
                </h4>
              </li>

              <li
                onClick={() => {
                  navigate("/blog");
                  setActivePage("blogs");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
                className="hideLink"
              >
                <h4
                  style={{
                    color: activePage === "blogs" ? "#196EA4" : "",
                  }}
                >
                  Blogs
                </h4>
              </li>
              <li
                onClick={() => {
                  navigate("/medicaltest");
                  setActivePage("labs");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  dispatch(closeProcessCard(false));
                  dispatch(closeProcessForm(false));
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
                className="hideLink"
              >
                <h4
                  style={{
                    color: activePage === "labs" ? "#196EA4" : "",
                  }}
                >
                  Labs
                </h4>
              </li>
            </ul>

            <div className="headerBtn">
              <button
                className="hideLink"
                onClick={() => {
                  secondModalOpen();
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
              >
                Repeat <br />
                Medicine
              </button>

              <button
                className="hideLink"
                onClick={() => {
                  openModal();
                  dispatch(setSignupModal(false));
                }}
              >
                Book Online <br /> Consultation
              </button>

              {!token ? (
                <button
                  onClick={() => {
                    dispatch(setLoginModal(true));
                    dispatch(setShowForm(false));
                    dispatch(setLabStep(0));
                    hideNavLinks();
                    dispatch(setSignupModal(false));
                  }}
                  className="hideLink header_logIn_btn"
                >
                  Login
                </button>
              ) : (
                <div onClick={toggleDropdown} className="mobile-hide-two">
                  {" "}
                  {getUserFirstLetter()}
                </div>
              )}
              {isDropdownOpen && token && (
                <div className="dropdown-header">
                  <div
                    onClick={() => {
                      navigate("/profilescreen");
                      dispatch(setShowForm(false));
                      dispatch(setLabStep(0));
                      setIsDropdownOpen(!isDropdownOpen);
                      setActivePage("profilescreen");
                      dispatch(setShowForm(false));
                      dispatch(closeProcessCard(false));
                  dispatch(closeProcessForm(false));
                      hideNavLinks();
                    }}
                    className="hideLink"
                    style={{
                      color: activePage === "profilescreen" ? "#196EA4" : "",
                    }}
                  >
                    Profile
                  </div>
                  <div onClick={handleLogOut} className="hideLink">
                    Log-out
                  </div>
                </div>
              )}
            </div>

            <div className="headerBtnTwo">
              <div
                className="header-toggle-btn hideLink"
                onClick={() => {
                  navigate("/wishlist");
                  setActivePage("wish");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  if (token) {
                    dispatch(fetchUserWishlistAsync(token));
                  }
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
              >
                <div>
                  <span className="header_num">{wishlist.length}</span>
                  <IoIosHeartEmpty
                    style={{
                      color: activePage === "wish" ? "#196EA4" : "",
                    }}
                  />
                </div>
              </div>

              <div
                className="header-toggle-btn hideLink"
                onClick={() => {
                  navigate("/cart");
                  setActivePage("cart");
                  dispatch(setShowForm(false));
                  dispatch(setLabStep(0));
                  if (token) {
                    dispatch(fetchUserCartAsync(token));
                  }
                  dispatch(setLoginModal(false));
                  dispatch(setSignupModal(false));
                }}
              >
                <div>
                  <span className="header_num">{cart.length}</span>

                  <FiShoppingCart
                    style={{
                      color: activePage === "cart" ? "#196EA4" : "",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {isModalOpen && <div className="overlay" onClick={closeModal}></div>}
          <div className={isModalOpen ? "Form open" : "Form"}>
            {isModalOpen && (
              <AppointmentFom
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            )}
          </div>
          {otherModalOpen && (
            <div className="overlay" onClick={closeSecondModal}></div>
          )}
          <div className={otherModalOpen ? "Form open" : "Form"}>
            {otherModalOpen && (
              <OfflineForm
                isOpen={otherModalOpen}
                setOtherModalOpen={setOtherModalOpen}
              />
            )}
          </div>

          <div className="hame-burger-menu">
            {
              hameburger ? <>  <RxCross1 className="menu" onClick={() => HmeBurgerMenu()} /></> : <> <GiHamburgerMenu className="menu" onClick={() => HmeBurgerMenu()} /></>
            }


          </div>
        </header>
      </Headroom>
    </>
  );
}

export default Header;
