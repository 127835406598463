import React, { useState } from "react";
import "../../styles/ProfileScreen/ProfilePassPopUp.css";
import { RxCross2 } from "react-icons/rx";
import { TbLoader3 } from "react-icons/tb";

function ProfilePassPopUp({
  setShowPopUp,
  verifyPass,
  loadingVerifyPassword,
}) {
  const [passwordToVerify, setPasswordToVerify] = useState();
  const handlePasswordChange = (e) => setPasswordToVerify(e.target.value);
  const passToVerifyFun = () => {
    verifyPass(passwordToVerify);
  };

  return (
    <div className="profile_screen_pup_up">
      <div
        className="profile_screen_pup_up_cross_btn"
        onClick={() => setShowPopUp()}
      >
        <RxCross2 />
      </div>

      <div className="profile_screen_pup_up_input">
        <input
          type="password"
          placeholder="Enter Password"
          onChange={handlePasswordChange}
          value={passwordToVerify}
        />
        <button onClick={() => passToVerifyFun()}>
          {loadingVerifyPassword ? <TbLoader3 className="spinner" /> : "Verify"}
        </button>
      </div>
    </div>
  );
}

export default ProfilePassPopUp;
