import {
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
    cartAndWishlistApi,
    getCartApi,
    getWishlistApi,
    removeWishlistApi,
} from "../../Constents/apiAxios";
import axios from "axios";

const initialState = {
    cartItems: localStorage.getItem("cartItems")
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    wishlistItems: localStorage.getItem("wishlistItems")
        ? JSON.parse(localStorage.getItem("wishlistItems"))
        : [],
    cartItemsAPI: [],
    wishlistAPIs: [],
    buyNow: localStorage.getItem("buyNow")
        ? JSON.parse(localStorage.getItem("buyNow"))
        : [],
    showForm: localStorage.getItem("buyNow")
        ? localStorage.getItem("buyNow")
        : false,
    labForm: localStorage.getItem("labAddress")
        ? localStorage.getItem("labAddress")
        : false,
    labStep: localStorage.getItem("setStep")
        ? localStorage.getItem("setStep")
        : 1,
    labPayment: localStorage.getItem("labPayment")
        ? localStorage.getItem("labPayment")
        : false,
};

// Async action creator for calling the API
export const checkItem = createAsyncThunk(
    "products/checkProductInWishlistOrCart",
    async (
        { productId, productVarientId },
        { rejectWithValue, getState, dispatch }
    ) => {
        try {
            const token = getState().login.token;
            const response = await axios.post(
                cartAndWishlistApi,
                {
                    productId,
                    productVarientId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("checkItem", response.data);

            if (!response.data.status === "success") {
                return rejectWithValue("server error");
            }

            const data = response.data;

            return data;
        } catch (error) {
            return rejectWithValue("server error");
        }
    }
);

// Async action creator for fetching cart items
export const fetchCartItems = createAsyncThunk(
    "cartItems/fetchCartItems",
    async (_, { getState, rejectWithValue }) => {
        try {
            const token = getState().login.token;
            const isAuthenticated = !!token;

            if (isAuthenticated) {
                const response = await axios.get(getCartApi, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("redux cartFetch", response.data.cartProducts[0]);
                if (response.status === 201) {
                    const cartItems = response.data.cartProducts[0];
                    return { cartItems };
                    // return rejectWithValue("server error");
                }
                console.log("fetchcartitem", response);
                return response.data.cartProducts[0];
            } else {
                // If the user is not authenticated, return the cart items from the local storage
                const cartItems = localStorage.getItem("cartItems")
                    ? JSON.parse(localStorage.getItem("cartItems"))
                    : [];
                return { cartItems };
            }
        } catch (error) {
            return rejectWithValue("server error");
        }
    }
);

export const removeWishlistItem = createAsyncThunk(
    "wishlistItems/removeWishlistItem",
    async ({ _id }, { rejectWithValue, getState, dispatch }) => {
        try {
            const token = getState().login.token;
            const isAuthenticated = !!token;

            if (isAuthenticated) {
                const requestBody = { productVariantId: _id };
                const response = await axios.post(
                    removeWishlistApi,
                    requestBody,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log("remove wishlist", response);
                if (response.status === 201) {
                    return response.data;
                    // return rejectWithValue("server error");
                }

                const data = response.data;
                return data;
            } else {
                // If the user is not authenticated, remove the item from the local storage
                const wishlistItems = localStorage.getItem("wishlistItems")
                    ? JSON.parse(localStorage.getItem("wishlistItems"))
                    : [];
                const updatedWishlistItems = wishlistItems.filter(
                    (item) => item._id !== _id
                );
                localStorage.setItem(
                    "wishlistItems",
                    JSON.stringify(updatedWishlistItems)
                );
                return { _id };
            }
        } catch (error) {
            return rejectWithValue("server error");
        }
    }
);

// Async action creator for fetching wishlist items
export const fetchWishlistItems = createAsyncThunk(
    "wishlistItems/fetchWishlistItems",
    async (_, { getState, rejectWithValue }) => {
        try {
            const token = getState().login.token;
            const isAuthenticated = !!token;

            if (isAuthenticated) {
                const response = await axios.get(getWishlistApi, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(
                    "fetchwishlist redux;;;;",
                    response.data.WishlistProducts[0]
                );
                if (response.status === 201) {
                    const wishlistItems = response.data.WishlistProducts[0];
                    return wishlistItems;
                    // return rejectWithValue("server error");
                }

                return response.data;
            } else {
                // If the user is not authenticated, return the wishlist items from the local storage
                const wishlistItems = localStorage.getItem("wishlistItems")
                    ? JSON.parse(localStorage.getItem("wishlistItems"))
                    : [];
                return { wishlistItems };
            }
        } catch (error) {
            return rejectWithValue("server error");
        }
    }
);

const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        addToCartAPIs: (state, action) => {
            const getCartData = action.payload;
            state.cartItems = getCartData;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        addToCart: (state, action) => {
            const { _id, size, potency } = action.payload;
            const existingItem = state.cartItems.find(
                (item) =>
                    item._id === _id &&
                    item.size === size &&
                    item.potency === potency
            );

            if (existingItem) {
                // existingItem.cartQuantity += 1;

                return;
            } else {
                state.cartItems.push({
                    ...action.payload,
                    cartQuantity: 1,
                    size,
                    potency,
                });
                toast.success(`added to cart`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            state.cartTotalQuantity = state.cartItems.reduce(
                (total, item) => total + item.cartQuantity,
                0
            );
            state.cartTotalAmount = state.cartItems.reduce(
                (total, item) => total + item.mrp * item.cartQuantity,
                0
            );
        },
        increaseCart: (state, action) => {
            const itemId = action.payload;

            const index = state.cartItemsAPI.findIndex(
                (item) => item.item._id === itemId
            );

            if (index !== -1) {
                state.cartItemsAPI[index].cartQuantity += 1;
                localStorage.setItem(
                    "cartItems",
                    JSON.stringify(state.cartItems)
                );
            }
        },
        decreaseCart: (state, action) => {
            const itemId = action.payload;
            const index = state.cartItemsAPI.findIndex(
                (item) => item.item._id === itemId
            );

            if (index !== -1) {
                if (state.cartItemsAPI[index].cartQuantity === 1) {
                    toast.error("Product quantity not less than 1");
                } else {
                    state.cartItemsAPI[index].cartQuantity -= 1;
                    localStorage.setItem(
                        "cartItems",
                        JSON.stringify(state.cartItems)
                    );

                }
            }
        },

        removeFromCart(state, action) {
            const { _id, size, potency } = action.payload;
            const nextCartItems = state.cartItems.filter(
                (cartItem) =>
                    !(
                        cartItem._id === _id &&
                        cartItem.size === size &&
                        cartItem.potency === potency
                    )
            );
            state.cartItems = nextCartItems;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            state.cartTotalQuantity = state.cartItems.reduce(
                (total, item) => total + item.cartQuantity,
                0
            );
            state.cartTotalAmount = state.cartItems.reduce(
                (total, item) => total + item.mrp * item.cartQuantity,
                0
            );
            toast.error(`item removed from cart`, {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 2000,
            });
        },

        getTotal(state) {
            let { total, quantity } = state.cartItems.reduce(
                (cartTotal, cartItems) => {
                    const { sellingPrice, cartQuantity } = cartItems;
                    const itemTotal = sellingPrice * cartQuantity;
                    cartTotal.total += itemTotal;
                    cartTotal.quantity += cartQuantity;
                    return cartTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                }
            );
            state.cartTotalQuantity = quantity;
            state.cartTotalAmount = total;
        },

        clearCart(state, action) {
            state.cartItems = [];
            localStorage.removeItem("cartItems");
        },
        addToWishlistApi: (state, action) => {
            const getWishlistItems = action.payload;
            state.wishlistItems = getWishlistItems;
            localStorage.setItem(
                "wishlistItems",
                JSON.stringify(state.wishlistItems)
            );
        },

        addToWishlist: (state, action) => {
            const { _id, size, potency } = action.payload;
            const existingItem = state.wishlistItems.find(
                (item) =>
                    item._id === _id &&
                    item.size === size &&
                    item.potency === potency
            );

            if (!existingItem) {
                state.wishlistItems.push({ ...action.payload, size, potency });
                toast.success(`added to wishlist`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            } else {
                toast.warn(`Item already in wishlist`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }

            localStorage.setItem(
                "wishlistItems",
                JSON.stringify(state.wishlistItems)
            );
        },

        removeWishListItem: (state, action) => {
            const { _id } = action.payload;
            const filterItem = state.wishlistItems.filter(
                (wishlistItems) => wishlistItems._id !== _id
            );
            state.wishlistItems = filterItem;

            localStorage.setItem(
                "wishlistItems",
                JSON.stringify(state.wishlistItems)
            );
            toast.error(`remove from wishlist`, {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 2000,
            });
        },

        clearAllWishlist: (state, action) => {
            state.wishlistItems = [];
            localStorage.removeItem("wishlistItems");
        },
        clearBuyNow: (state, action) => {
            state.buyNow = [];
            localStorage.removeItem("buyNow");
        },
        addBuyNow: (state, action) => {
            state.buyNow.push({ item: action.payload, quantity: action.payload.qty });
            localStorage.setItem("buyNow", JSON.stringify(state.buyNow));
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
            localStorage.setItem("showForm", state.showForm);
        },
        setLabForm: (state, action) => {
            state.labForm = action.payload;
            localStorage.setItem("labForm", state.labForm);
        },
        setLabStep: (state, action) => {
            state.labStep = action.payload;
            localStorage.setItem("labStep", state.labStep);
        },
        setLabPayment: (state, action) => {
            state.labPayment = action.payload;
            localStorage.setItem("labPayment", state.labPayment);
        },
        increaseBuyNow: (state, action) => {
            const itemId = action.payload;

            const index = state.buyNow.findIndex(
                (item) => item.item._id === itemId
            );

            if (index !== -1) {
                state.buyNow[index].quantity += 1;

                localStorage.setItem("buyNow", JSON.stringify(state.buyNow));
            }
        },
        decreaseBuyNow: (state, action) => {
            const itemId = action.payload;

            const index = state.buyNow.findIndex(
                (item) => item.item._id === itemId
            );

            if (index !== -1) {
                if (state.buyNow[index].quantity === 1) {
                    toast.error("Product quantity not less than 1");
                } else {
                    state.buyNow[index].quantity -= 1;

                    localStorage.setItem(
                        "buyNow",
                        JSON.stringify(state.buyNow)
                    );
                }
            }
        },
        deleteBuyNow: (state, action) => {
            const itemId = action.payload;
            const index = state.buyNow.findIndex(
                (item) => item.item._id === itemId
            );

            if (index !== -1) {
                state.buyNow.splice(index, 1);
                localStorage.setItem("buyNow", JSON.stringify(state.buyNow));
                toast.error("Product is Removed");
            } else {
                toast.error("Product is not Removed");
            }
        },

        resetStore: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkItem.fulfilled, (state, action) => {


                state.cartTotalQuantity = state.cartItems.reduce(
                    (total, item) => total + item.cartQuantity,
                    0
                );
                state.cartTotalAmount = state.cartItems.reduce(
                    (total, item) => total + item.mrp * item.cartQuantity,
                    0
                );
            })
            .addCase(fetchCartItems.fulfilled, (state, action) => {
                // state.cartItems = action.payload.cartItems || [];
                const cartItems = action.payload.cartItems || [];
                state.cartItems = cartItems;
                state.cartTotalQuantity = state.cartItems.reduce(
                    (total, item) => total + item.cartQuantity,
                    0
                );
                state.cartTotalAmount = state.cartItems.reduce(
                    (total, item) => total + item.mrp * item.cartQuantity,
                    0
                );
            })

            .addCase(removeWishlistItem.fulfilled, (state, action) => {
                const { _id } = action.payload;
                const nextWishlistItems = state.wishlistItems.filter(
                    (wishlistItem) => wishlistItem._id !== _id
                );
                state.wishlistItems = nextWishlistItems;
                toast.error(`remove from wishlist`, {
                    position: toast.POSITION.TOP_LEFT,
                    autoClose: 2000,
                });
            })
            .addCase(fetchWishlistItems.fulfilled, (state, action) => {
                // state.wishlistItems = action.payload.wishlistItems || [];
                const wishlistItems = action.payload.wishlistItems || [];
                state.wishlistItems = wishlistItems;
            })

    },
});

export const {
    decreaseBuyNow,
    increaseBuyNow,
    clearBuyNow,
    addBuyNow,
    setShowForm,
    setLabForm,
    setLabStep,
    setLabPayment,
    addToWishlistApi,
    addToCartAPIs,
    addToCart,
    removeFromCart,
    increaseCart,
    decreaseCart,
    getTotal,
    clearCart,
    addToWishlist,
    removeWishListItem,
    clearAllWishlist,
    resetStore,
} = productSlice.actions;
export default productSlice.reducer;
