import React, { useEffect } from "react";
import Hero from "../components/privacyPolicy/Hero";
import Principles from "../components/privacyPolicy/Principles";
import ExplorePrinciple from "../components/privacyPolicy/ExplorePrinciple";
import ExplorePrincipleC from "../components/privacyPolicy/ExplorePrincipleC";
import PrinciplesSecond from "../components/privacyPolicy/PrinciplesSecond";
// import PrinciplesLast from "../components/privacyPolicy/PrinciplesLast";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <Hero />
      <Principles/>
      <ExplorePrinciple/>
      <PrinciplesSecond/>
      <ExplorePrincipleC/>
      {/* <PrinciplesLast/> */}
    </>
  );
};

export default PrivacyPolicy;
