import React, {  useMemo, useState } from "react";
import "../../../styles/AllProducts/FilterProduct/Category.css";
import FilterPopUp from "../FilterPopUp";
import { useDispatch, useSelector } from "react-redux";
import { getMainCategory } from "../../../store/categories/categories";
import {
    addCategory,
    removeCategory,
    selectCategories,
} from "../../../store/filterSlice/filterSlice";

function Category({ toggleFilterIcon }) {
    const dispatch = useDispatch();
    const selectedCategories = useSelector(selectCategories);
    const categoryData = useSelector(getMainCategory);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleCategorySelection = (category) => {
        if (selectedCategories.includes(category)) {
            dispatch(removeCategory(category));
        } else {
            dispatch(addCategory(category));
        }
    };


    // selected category will show on the top 
    const sortedCategory = useMemo(() => {
        const mainCategory = categoryData;

        if (!Array.isArray(mainCategory)) {
            return [];
        }

        const selectedCategoryList = mainCategory.filter((category) =>
            selectedCategories.includes(category?.categoryName)
        );

        const unSelectedCategoryList = mainCategory.filter(
            (category) => !selectedCategories.includes(category?.categoryName)
        );

        return [...selectedCategoryList, ...unSelectedCategoryList];
    }, [categoryData, selectedCategories]);

    // initially show 4 data on filter list
    const showData = sortedCategory?.slice(0, 4);


    return (
        <div className="sidebar_medicine">
            <div className="sidebar_medicine_heading">
                <p onClick={toggleFilterIcon}>X</p>
                <h4 className="treatment">Homeopathy Category</h4>
            </div>

            <div className="sidebar_medicine_text">
                {showData?.map((category) => {
                    const categoryString = String(category?.categoryName);
                    return (
                        <div key={categoryString}>
                            <input
                                type="checkbox"
                                id={`brand-${category?.categoryName}`}
                                checked={selectedCategories.includes(
                                    category?.categoryName
                                )}
                                onChange={() =>
                                    toggleCategorySelection(
                                        category?.categoryName
                                    )
                                }
                            />
                            <label
                                htmlFor={`brand-${category?.categoryName}`}
                                className="lablefilter"
                            >
                                {category?.categoryName}
                            </label>
                        </div>
                    );
                })}
            </div>

            <button onClick={openModal}>view more</button>

            {isModalOpen && (
                <FilterPopUp
                    isOpen={isModalOpen}
                    allBrand={sortedCategory}
                    closeModal={closeModal}
                    toggleBrandSelection={toggleCategorySelection}
                    selectedBrands={selectedCategories}
                />
            )}
        </div>
    );
}

export default Category;
