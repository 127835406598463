// labSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchlabsApi, labPackageApi } from "../../Constents/apiAxios";

// Define the initial state
const initialState = {
    labs: [],
    allServices: localStorage.getItem("allServices")
        ? JSON.parse(localStorage.getItem("allServices"))
        : [],
    labPackages:[],
    loading: false,
    error: null,

};

// Define the async thunk for fetching lab data
export const fetchLabs = createAsyncThunk(
    "labs/fetchLabs",
    async (_, { dispatch }) => {
        try {
            const response = await axios.get(fetchlabsApi);
            const data = response.data;

           
            return data;
        } catch (error) {
            throw error;
        }
    }
);

export const fetchAllLabPackagesAsync = createAsyncThunk(
    'labPackages/fetchAllLabPackagesAsync',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const response = await axios.get(labPackageApi);

            return response.data; // Assuming the response contains the array of lab packages
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);



// Create the lab slice
const labSlice = createSlice({
    name: "labs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLabs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLabs.fulfilled, (state, action) => {
                state.loading = false;
                state.labs = action?.payload?.allLab;
            
                // Extract and accumulate all services from each lab
                const allServices = action?.payload?.allLab.reduce(
                    (accumulator, lab) => [
                        ...accumulator,
                        ...(Array.isArray(lab?.labServices) ? lab.labServices : []),
                    ],
                    []
                );
            
                state.allServices = allServices;
            
                // Save all services to localStorage
                localStorage.setItem(
                    "allServices",
                    JSON.stringify(state.allServices)
                );
            })
            
            .addCase(fetchLabs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
             // Fetch Lab Packages
             .addCase(fetchAllLabPackagesAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllLabPackagesAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.labPackages = action.payload.packages;
            })
            .addCase(fetchAllLabPackagesAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            
    },
});

// Export actions and reducer
export default labSlice.reducer;

export const getLabs = (state) => state?.labs?.labs;
export const getServices = (state) => state?.labs?.allServices;
export const getLabPackages = (state) => state?.labs?.labPackages;
