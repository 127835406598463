import Hero from "../components/productCategory/Hero";
import MedByBrand from "../components/productCategory/MedByBrand";
import MedicineByDisease from "../components/productCategory/MedicineByDisease";
import ProductCategories from "../components/productCategory/ProductCategories";
import { useEffect } from "react";
import Loader from "../components/loader/Loader";
import {  useSelector } from "react-redux";
import { catLoading } from "../store/categories/categories";

function ProductCategory() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const catLoader = useSelector(catLoading)
    return (
        <div>
            {catLoader ? (
                <Loader />
            ) : (
                <>
                    <Hero />
                    <MedicineByDisease />
                    <ProductCategories />
                    <MedByBrand/>
                </>
            )}
        </div>
    );  
}

export default ProductCategory;
