// processCardSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    processCard: localStorage.getItem("processCard")
    ? localStorage.getItem("processCard")
    : false,
};

const processCardSlice = createSlice({
    name: 'processCard',
    initialState,
    reducers: {
        openProcessCard(state) {
            state.processCard = true;
            localStorage.setItem("processCard", state.processCard);
        },
        closeProcessCard(state) {
            state.processCard = false;
            localStorage.setItem("processCard", state.processCard);
        },
    },
});

export const { openProcessCard, closeProcessCard } = processCardSlice.actions;

export default processCardSlice.reducer;
