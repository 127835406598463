import React from 'react'
import '../../../styles/medicalTest/labDescription/Hero.css'

function Hero({ singleLabData }) {
  return (
    <section className="medical_test_hero">
      <div className="medical_content">
        <h2>
          {singleLabData?.name}
        </h2>
        <p>{singleLabData?.description}</p>
        <div className='btn-box'>
        </div>
      
      </div>
    </section>
  )
}

export default Hero