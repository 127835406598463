import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLabPackages } from "../../store/labSlice/labSlice";
import "../../styles/medicalTest/LabPackages.css";
import test from "../../Assets/Images/medicalTest/labImage.jpg";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import LabPackageCard from "./Book_process/LabPackageCard";
import { openProcessForm,closeProcessForm } from "../../store/labSlice/labBooking";

const LabPackages = () => {
    const dispatch = useDispatch();
    const labPackages = useSelector(getLabPackages);
    const loading = useSelector((state) => state.labs.loading);
    const [viewCount, setViewCount] = useState(5);
    const processForm = useSelector((state) => state.labBooking.processForm)
    const modalRef = useRef(null);

    const [cardData, setCardData] = useState();

    // Show only the first viewCount packages initially
    const displayedPackages = labPackages.slice(0, viewCount);

    const handleViewMore = () => {
        if (viewCount < labPackages.length) {
            setViewCount((prevCount) => prevCount + 5);
        } else {
            setViewCount(5); // Reset to initial view count
        }
    };

    const openProcessCardFunction = (item) => {
        dispatch(openProcessForm(true));
        setCardData(item);
    };
    useEffect(() => {
        if (processForm) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [processForm]);

    // Close modal when clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dispatch(closeProcessForm(false));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    return (
        <div className="labPackages">
            {/* {loading && <Loader />} */}
            <h1>Most Selling Test Packages</h1>
            <div className="labpackageSection">
                {displayedPackages?.map((item, index) => (
                    <div key={index} className="labPackageBox">
                        <div className="testImage">
                            <img src={test} alt="" />
                        </div>
                        <h3>{item?.packageName}</h3>
                        <h5>{item?.labName}</h5>
                        <div className="testsLists">
                            {item?.tests?.map((test, idx) => (
                                <span key={idx}>
                                    <div className="dotBox"></div>
                                    {test}
                                </span>
                            ))}
                        </div>
                        <div className="pricings">
                            <div className="mrpClass">
                                MRP: &#8377;<span>{item?.mrp}</span>
                            </div>
                            <button
                                onClick={() => openProcessCardFunction(item)}
                            >
                                {" "}
                                <HiOutlineCurrencyRupee className="pricingButton" />{" "}
                                {item?.sellingPrice}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {labPackages.length > 5 && (
                <button onClick={handleViewMore} className="viewMoreButton">
                    {viewCount < labPackages.length ? "View More" : "View Less"}
                </button>
            )}

            {processForm && (
                <div className="overlayCss">
                    <div className="process_card_overlay" ref={modalRef}>
                        <LabPackageCard service={cardData} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default LabPackages;
