import { toast } from "react-toastify";
import { resetStore } from "../../CartSlice/CartSlice";
import { clearCart } from "../../newCart/newCart";
import { clearNewWishlist } from "../../newWishlist/newWishlist";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const logOutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

//Async Thunk Action

export const logOutAction = () => {
    return async (dispatch) => {
        try {
            dispatch(logOutSuccess());

            //clear the token from local storage
            localStorage.removeItem("authToken");

            localStorage.clear();

            // clear the redux store
            dispatch(resetStore());
            dispatch(clearCart());
            dispatch(clearNewWishlist())
            // show a success message of logout
            toast.error("log-out successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        } catch (error) {
            console.log("Log-out failed", error);
        }
    };
};
