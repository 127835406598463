import axios from "axios";
import { toast } from "react-toastify";
import { setAuthHeader } from "./AuthenticateHeader";
import { setLoading } from "../loader/Loader";
import { clearAllWishlist, clearCart } from "../../CartSlice/CartSlice";
import { dasboardApi, googleAuth, loginApi } from "../../../Constents/apiAxios";
import { cartAddApi, wishlistAddApi } from "../../../Constents/apiAxios";
import {
  fetchCartItems,
  fetchWishlistItems,
} from "../../CartSlice/CartSlice";
import { fetchUserCartAsync } from "../../newCart/newCart";
import { fetchUserWishlistAsync } from "../../newWishlist/newWishlist";
import { setLoginModal, setSignupModal } from "../../signupSlice/signupSlice";
import { fetchUserAddresses } from "../../signupSlice/userAddress";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_USER_DATA = " SET_USER_DATA ";
export const SET_AUTH_TOKEN = " SET_AUTH_TOKEN";

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});

export const setAuthToken = (token) => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const loginAction = (credentials) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loginRequest());
      dispatch(setLoading(true));
      await dispatch(fetchCartItems());
      await dispatch(fetchWishlistItems());
      const response = await axios.post(loginApi, credentials, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // console.log(response.data);
      const { data } = response;
      console.log("this login ", data)

      if (data.token) {
        const response1 = await axios.get(dasboardApi, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        const userData = {
          user: response1.data.data,
          token: data.token,
        };

        localStorage.setItem("authToken", userData.token);
        setAuthHeader(userData.token);
        const serializedUser = JSON.stringify(userData.user);
        localStorage.setItem("userData", serializedUser);
        dispatch(loginSuccess(userData));
        dispatch(fetchUserCartAsync(data.token))
        dispatch(fetchUserWishlistAsync(data.token))
        dispatch(setLoginModal(false));
        dispatch(fetchUserAddresses(data.token))
      }

      // After successful login, check if there are items in cart and wishlist in Redux
      const { cartItems, wishlistItems } = getState().products;
      // console.log("Cart Items:", cartItems);
      // console.log("Wishlist Items:", wishlistItems);

      if (cartItems.length > 0) {
        await Promise.all(
          cartItems.map(async (cartItem) => {
            const responseCart = await axios.post(
              cartAddApi,
              {
                productId: cartItem.productId,
                productVariantId: cartItem._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                },
              }
            );
            console.log("login cart", responseCart.data);
          })
        );
      }

      if (wishlistItems.length > 0) {
        await Promise.all(
          wishlistItems.map(async (wishlistItem) => {
            const responseWishlist = await axios.post(
              wishlistAddApi,
              {
                productId: wishlistItem.productId,
                productVariantId: wishlistItem._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                },
              }
            );
            console.log("login store", responseWishlist.data);
          })
        );
      }

      //  Upon successful storage, clear the cart and wishlist in Redux
      dispatch(clearAllWishlist());
      dispatch(clearCart());

      toast.success("Login successful!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        dispatch(loginFailure("Incorrect email or password"));
        toast.error("Incorrect email or password", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else if (error.response && error.response.status === 500) {
        dispatch(loginFailure("Server error"));
        toast.error("Server error, please try again later", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        dispatch(loginFailure(error.message || "Login failed"));
        toast.error("Login failed, Please try again later", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

      dispatch(setLoading(false));
    }
  };
};
export const googleLoginAction = (credentials) => {
  // console.log("google credentials;;;;;;;", credentials)
  return async (dispatch, getState) => {
    try {
      dispatch(loginRequest());
      dispatch(setLoading(true));
      await dispatch(fetchCartItems());
      await dispatch(fetchWishlistItems());
      const response = await axios.post(googleAuth, credentials, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // console.log(response?.data);
      const { data } = response;

      if (data.token) {

        const response1 = await axios.get(dasboardApi, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        const userData = {
          user: response1.data.data,
          token: data.token,
        };
        console.log("userData", userData)
        localStorage.setItem("authToken", userData.token);
        setAuthHeader(userData.token);
        const serializedUser = JSON.stringify(userData.user);
        localStorage.setItem("userData", serializedUser);
        dispatch(loginSuccess(userData));
        dispatch(setLoginModal(false));
        dispatch(setSignupModal(false));
      }

      // After successful login, check if there are items in cart and wishlist in Redux
      const { cartItems, wishlistItems } = getState().products;
      console.log("Cart Items:", cartItems);
      console.log("Wishlist Items:", wishlistItems);

      if (cartItems.length > 0) {
        await Promise.all(
          cartItems.map(async (cartItem) => {
            const responseCart = await axios.post(
              cartAddApi,
              {
                productId: cartItem.productId,
                productVariantId: cartItem._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                },
              }
            );
            console.log("login cart", responseCart.data);
          })
        );
      }

      if (wishlistItems.length > 0) {
        await Promise.all(
          wishlistItems.map(async (wishlistItem) => {
            const responseWishlist = await axios.post(
              wishlistAddApi,
              {
                productId: wishlistItem.productId,
                productVariantId: wishlistItem._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                },
              }
            );
            console.log("login store", responseWishlist.data);
          })
        );
      }

      //  Upon successful storage, clear the cart and wishlist in Redux
      dispatch(clearAllWishlist());
      dispatch(clearCart());

      toast.success("Login successful!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        dispatch(loginFailure("Incorrect email or password"));
        toast.error("Incorrect email or password", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else if (error.response && error.response.status === 500) {
        dispatch(loginFailure("Server error"));
        toast.error("Server error, please try again later", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        dispatch(loginFailure(error.message || "Login failed"));
        toast.error("Login failed, Please try again later", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

      dispatch(setLoading(false));
    }
  };
};