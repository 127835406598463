import React from "react";
import "../../styles/AllProducts/Hero.css";
import right from "../../Assets/Images/allProduct/right.png";
import left from "../../Assets/Images/allProduct/left.png";

import SearchBar from "../../Constents/SearchBar";

const Hero = () => {
    return (
        <section className="allContainer">
            <div className="allProductContent">
                <div className="productHeading">
                    <h1>our product</h1>
                </div>
                <SearchBar />
            </div>
            <div className="allHeroRightImg">
                <img src={right} alt="" />
            </div>
            <div className="allHeroLeftImg">
                <img src={left} alt="" />
            </div>
        </section>
    );
};

export default Hero;
