// wishlistSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getWishlistApi, wishlistAddApi, removeWishlistApi } from '../../Constents/apiAxios';
import axios from 'axios';
const initialState = {
    newWishlist: [], // Array to store wishlist items
    loading: false,
    error: null,
};

// Async Thunk action to fetch user's wishlist from the backend
export const fetchUserWishlistAsync = createAsyncThunk(
    'wishlist/fetchUserWishlist',
    async (token, thunkAPI) => {
        try {

            // Define the headers with the token
            const dispatch = thunkAPI.dispatch
            dispatch(setWishlistLoading(true))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            // Make a GET request to fetch user's wishlist from the backend API
            const response = await axios.get(getWishlistApi, config);
            // Return the wishlist data from the response
            // console.log("response from user's wishlist", response.data)
            dispatch(updateWishlistAfterLogin(response?.data?.data))

            dispatch(setWishlistLoading(false))
            return response?.data?.data;
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Async Thunk action to add a product to the wishlist
export const addItemToWishlistAsync = createAsyncThunk(
    'wishlist/addItemToWishlist',
    async (item, thunkAPI) => {
        try {

            console.log("item to send", item)
            const dataToSend = {
                productId: item.productId,
                productVariantId: item.productVariantId,
                
            }
            // Get the token from the Redux store
            const token = thunkAPI.getState().login.token;
            console.log(token)

            // Check if the user is authenticated
            if (!token) {
                throw new Error('User not authenticated');
            }

            // Define the headers with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            // Make a POST request to add the item to the wishlist
            const response = await axios.post(wishlistAddApi, dataToSend, config);

            return response.data
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Async Thunk action to delete a product from the wishlist
export const wishlistItemDeleteAsync = createAsyncThunk(
    'wishlist/deleteWishlistItem',
    async (wishlistItemId, thunkAPI) => {
        try {
            // Get the token from the Redux store
            const token = thunkAPI.getState().login.token;

            // Check if the user is authenticated
            if (!token) {
                throw new Error('User not authenticated');
            }

            // Define the headers with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            // Make a DELETE request to delete the item from the wishlist
            await axios.delete(`${removeWishlistApi}${wishlistItemId}`, config);

            // Return the deleted wishlistItemId
            return wishlistItemId;
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        // Action to add a product to the wishlist
        addItemToWishlist(state, action) {
            const newItem = action.payload;
            // Check if the item already exists in the wishlist
            const existingItem = state.newWishlist.find(item => item.productVariantId === newItem.productVariantId);
            if (existingItem) {
                // If item exists, update its quantity
                // existingItem.quantity += 1;
                toast.error("Product is already present in the Wishlist")
            } else {
                // If item doesn't exist, add it to the wishlist with quantity 1
                const dataToPush = {
                    productVariantId:newItem.productVariantId,
                    productId: newItem.productId,
                    productName: newItem.productName,
                    size: newItem.size,
                    potency: newItem.potency,
                    inventory: newItem.inventory,
                    costPrice: newItem.costPrice,
                    mrp: newItem.mrp,
                    discount: newItem.discount,
                    sellingPrice: newItem.sellingPrice,
                    imageObj: newItem.imageObj,
                }
                state.newWishlist.push(dataToPush);
                toast.success("Product Added in the Wishlist");
                localStorage.setItem('newwishlist', JSON.stringify(state.newWishlist));
            }
        },
        // Action to remove a product from the wishlist
        removeItemFromWishlist(state, action) {
            const productVariantId = action.payload;
            state.newWishlist = state.newWishlist.filter(item => item.productVariantId !== productVariantId);
        },
        // Action to clear all items from the wishlist
        clearNewWishlist(state) {
            state.newWishlist = [];
        },
        // Action to set wishlist items from local storage
        setWishlistFromLocalStorage(state, action) {
            state.newWishlist = action.payload || [];
        },
        // Action to update wishlist items after user login
        updateWishlistAfterLogin(state, action) {
            const userWishlist = action.payload;
            const token = state?.login?.token;
            console.log(token)
            // Iterate through the user's wishlist items
            userWishlist.forEach(userWishlistItem => {
                // Check if the user's wishlist item already exists in the local wishlist
                const existingWishlistItemIndex = state.newWishlist.findIndex(localWishlistItem => localWishlistItem.productVariantId === userWishlistItem.productVariantId);

                if (existingWishlistItemIndex !== -1) {
                    // If the item exists, replace it with the user's wishlist item
                    state.newWishlist.splice(existingWishlistItemIndex, 1, userWishlistItem);
                } else {
                    // If the item doesn't exist, add it to the local wishlist
                    state.newWishlist.push(userWishlistItem);
                }
            });



            // Send local wishlist items not found in the user's wishlist to the database
            const localItemsNotInUserWishlist = state.newWishlist.filter(localWishlistItem => {
                return !localWishlistItem.hasOwnProperty('_id');
            });

            localItemsNotInUserWishlist.forEach(async (localWishlistItem) => {
                try {


                    const dataToSend = {
                        productId: localWishlistItem.productId,
                        productVariantId: localWishlistItem.productVariantId,
                    }
                    // Make a POST request to add the local wishlist item to the database
                    await axios.post(wishlistAddApi, dataToSend);



                } catch (error) {
                    console.error('Error adding item to database:', error);
                }
            });
        },

        // Action to mark wishlist loading state
        setWishlistLoading(state, action) {
            state.loading = action.payload;
        },
        // Action to handle wishlist loading error
        setWishlistError(state, action) {
            state.error = action.payload;

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addItemToWishlistAsync.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addItemToWishlistAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.newWishlist = [...state.newWishlist, action.payload.data]
                // You can handle success state if needed
                toast.success("Product Added in the Wishlist");

            })
            .addCase(addItemToWishlistAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                toast.error("Product is already present in the Wishlist")


            })
            .addCase(wishlistItemDeleteAsync.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(wishlistItemDeleteAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.newWishlist = state.newWishlist.filter(item => item._id !== action.payload);
                toast.success("Product removed from the Wishlist");
            })
            .addCase(wishlistItemDeleteAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                toast.error("Product could not removed from the Wishlist");
            })
    },

});

export const {
    addItemToWishlist,
    removeItemFromWishlist,
    clearNewWishlist,
    setWishlistFromLocalStorage,
    updateWishlistAfterLogin,
    setWishlistLoading,
    setWishlistError,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;

