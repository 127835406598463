import "../../styles/Shared/PrimaryButton.css"

const PrimaryButton = (props) => {
  return (
    <button
      className="primary-button"
      type={props.type || 'button'}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
