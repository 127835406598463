import React, { useEffect, useState } from "react";
import Hero from "../components/blogs/blogDescription/Hero";
import Product from "../components/blogs/blogDescription/Product";
import { singleBlogApi } from "../Constents/apiAxios";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/Loader";

function BlogDescription() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [singleBlogData, setSingleBlogData] = useState();
    const [loading, setLoading] = useState(false);

    const { id } = useParams();
    useEffect(() => {
        setLoading(true);
        const handleSingleBlog = async () => {
            try {
                const response = await axios.get(`${singleBlogApi}/${id}`);
                console.log("single blog", response?.data?.singleBlog)
                setSingleBlogData(response?.data?.singleBlog);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        };

        handleSingleBlog();
    }, [id]);

    return (
        <>
            {!loading ? (
                <Loader />
            ) : (
                <>
                    <Hero singleBlogDatas={singleBlogData}/>
                    <Product singleBlogDatas={singleBlogData}/>
                 
                </>
            )}
        </>
    );
}

export default BlogDescription;
