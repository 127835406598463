import React from "react";
import "../../../styles/medicalTest/labDescription/Lab.css";
import { useSelector } from "react-redux";

function Lab() {
    const location = useSelector((state) => state.singleLab?.lab?.labDetail?.location);   

    return (
        <section className="lab_map_container">
            <div  className="location-map">
                <iframe
                    src={`https://maps.google.com/maps?q=${parseFloat(location.lat)},${parseFloat(location.log)}&z=${15}&output=embed`}
                    width="100%"
                    height="100%"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="google map"
                ></iframe>
            </div>

          
        </section>
    );
}



export default Lab;
