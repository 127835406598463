import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
    newBuyNow: [],
    loading: false,
    error: null,
};

const buyNowSlice = createSlice({
    name: 'buyNow',
    initialState,
    reducers: {
        addItemToBuyNow(state, action) {
            state.newBuyNow.push(action.payload);
            localStorage.setItem('newBuyNow', JSON.stringify(state.newBuyNow));
        },
        removeItemFromBuyNow(state, action) {
            const itemId = action.payload;
            state.newBuyNow = state.newBuyNow.filter(item => item._id !== itemId);
            localStorage.setItem('newBuyNow', JSON.stringify(state.newBuyNow));
        },
        increaseItemBuyNowQuantity(state, action) {
            const itemId = action.payload;
            const itemIndex = state.newBuyNow.findIndex(item => item._id === itemId);
            if (itemIndex !== -1) {
                if (state?.newBuyNow[itemIndex]?.quantity < state?.newBuyNow[itemIndex]?.inventory) {
                    state.newBuyNow[itemIndex].quantity += 1;
                    localStorage.setItem('newBuyNow', JSON.stringify(state.newBuyNow));
                } else {
                    toast.error('Cannot increase quantity. Only ' + state?.newBuyNow[itemIndex]?.inventory + ' items available in stock.');
                }
                
            }
        },
        decreaseItemBuyNowQuantity(state, action) {
            const itemId = action.payload;
            const itemIndex = state.newBuyNow.findIndex(item => item._id === itemId);
            if (itemIndex !== -1 && state.newBuyNow[itemIndex].quantity > 1) {
                state.newBuyNow[itemIndex].quantity -= 1;
                localStorage.setItem('newBuyNow', JSON.stringify(state.newBuyNow));
            }
        },
        clearNewBuyNow(state) {
            state.newBuyNow = [];
            localStorage.removeItem('newBuyNow');
        },
        initializeNewBuyNowFromLocalStorage(state) {
            const storedData = localStorage.getItem('newBuyNow');
            if (storedData) {
                state.newBuyNow = JSON.parse(storedData);
            }
        },
    },
});

export const {
    addItemToBuyNow,
    removeItemFromBuyNow,
    increaseItemBuyNowQuantity,
    decreaseItemBuyNowQuantity,
    clearNewBuyNow,
    initializeNewBuyNowFromLocalStorage
} = buyNowSlice.actions;

export default buyNowSlice.reducer;

// Selectors
export const selectBuyNow = state => state.newBuyNow.newBuyNow;