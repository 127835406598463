import React, { useEffect, useState } from "react";
import "../../styles/Gallary/OurGallary.css";
import { getGallery } from "../../Constents/apiAxios";

const OurGallery = () => {
  const [initialData, setInitialData] = useState([]); // Initial data for resetting view
  const [images, setImages] = useState([]); // Images to be displayed
  const [page, setPage] = useState(1); // Current page for pagination
  const [hasMore, setHasMore] = useState(true); // To check if more data is available for fetching
  const [isExpanded, setIsExpanded] = useState(false); // To toggle between "View More" and "View Less"

  // Fetch initial gallery data on component mount
  useEffect(() => {
    fetchGalleryData(1);
  }, []);

  // Function to fetch gallery data based on the current page
  const fetchGalleryData = (page) => {
    fetch(`${getGallery}?page=${page}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.galleryDetails && data.galleryDetails.length > 0) {
          if (page === 1) {
            setInitialData(data.galleryDetails); // Store initial data for resetting view
          }
          setImages((prevImages) => [
            ...prevImages,
            ...data.galleryDetails.map((image) => image.imageObj.url), // Append new images to the existing list
          ]);
        } else {
          setHasMore(false); // No more data available
        }
        setIsExpanded(page > 1); // Set expanded state if page is greater than 1
      })
      .catch((error) => console.error("Error fetching gallery:", error));
  };

  // Function to get image styles for specific index (to create a different layout)
  const getImageStyle = (index) => {
    if ((index + 1) % 10 === 0) {
      return {
        gridColumn: "3 / span 2",
        gridRow: "4 / span 2",
      };
    } else {
      return {};
    }
  };

  // Function to load more images by increasing the page number
  const loadMoreImages = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      fetchGalleryData(nextPage);
      return nextPage;
    });
  };

  // Function to reset images to initial data and reset pagination
  const viewLessImages = () => {
    setImages(initialData.map((image) => image.imageObj.url));
    setPage(1);
    setHasMore(true);
    setIsExpanded(false);
  };

  return (
    <div className="fullheight">
      <div className="fullheight-main">
        <div className="gallery-text">
          <h1>Our Gallery</h1>
          <p>Navigating Wellness with Homeopathy</p>
        </div>
        <div className="gallery-img">
          {images?.map((imageUrl, index) => (
            <div key={index} style={getImageStyle(index)}>
              <img src={imageUrl} alt={`Gallery_image${index + 1}`} />
            </div>
          ))}
        </div>
        {isExpanded ? (
          <div className="ourGallery_view_more">
            <button onClick={viewLessImages} className="ourGalleryButton">View Less</button>
          </div>
        ) : hasMore ? (
          <div className="ourGallery_view_more">
            <button onClick={loadMoreImages} className="ourGalleryButton">View More</button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OurGallery;
