import React, { useEffect, useState } from "react";
import "../../styles/ProfileScreen/Hero.css";
import { useSelector } from "react-redux";
import ProfilePassPopUp from "./ProfilePassPopUp";
import { TbLoader3 } from "react-icons/tb";
import {
    useGetDashboardQuery,
    usePassVerifyMutation,
    useSendOtpMutation,
    useUpdateNameMutation,
    useUpdatePasswordMutation,
    useUpdatePhoneMutation,
    useUpdateEmailMutation, // Assuming you have this mutation
} from "../../store/apis/profileEditApis";
import axios from "axios"; // Import axios
import { toast } from "react-toastify";
import { verificationEmailApi } from "../../Constents/apiAxios";

function Hero() {
    const [verifyBtn, setVerifyBtn] = useState("Verify");
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingPhone, setIsEditingPhone] = useState(false);
    const [isEditingPass, setIsEditingPass] = useState(false);
    const [isEditingEmail, setIsEditingEmail] = useState(false); // New state for email editing
    const [showOptField, setShowOtpField] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState(""); // New state for email
    const [emailVarLoad, setEmailVarLoad] = useState(false); // New state for email


    const [fieldToEdit, setFieldToEdit] = useState("");
    const [password, setPassword] = useState("password");
    const [otp, setOtp] = useState("");

    const [showPopUp, setShowPopUp] = useState(false);

    // Dashboard APIS
    const {
        data: dashboardData,
        isSuccess: dashboardSuccess,
        isError,
    } = useGetDashboardQuery();
    //   Verify Password Apis
    const [
        verifyPass,
        {
            isLoading: loadingVerifyPassword,
            isSuccess: successVerifyPassword,
            isError: isErrorVerifyPassword,
            error: errorVerifyPassword,
        },
    ] = usePassVerifyMutation();
    // Update APis
    const [
        updateNameApiCall,
        {
            isLoading: loadingNameUpdate,
            isSuccess: successNameUpdate,
            isError: iserrorNameUpdate,
            error: errorNameUpdate,
        },
    ] = useUpdateNameMutation();
    //   update Phone
    const [
        updatePhoneApiCall,
        {
            isLoading: loadingPhoneUpdate,
            isSuccess: successPhoneUpdate,
            isError: iserrorPhoneUpdate,
            error: errorPhoneUpdate,
        },
    ] = useUpdatePhoneMutation();
    //   setOtpAPi
    const [
        sendOtpApiCall,
        {
            data: otpData,
            isLoading: loadingSendotpUpdate,
            isSuccess: successSendotpUpdate,
            isError: iserrorSendOtpUpdate,
            error: errorSendotpUpdate,
        },
    ] = useSendOtpMutation();
    //   update Password
    const [
        updatePassApiCall,
        {
            isLoading: loadingPassUpdate,
            isSuccess: successPasspdate,
            isError: iserrorPassUpdate,
            error: errorPassUpdate,
        },
    ] = useUpdatePasswordMutation();
    //   update Email
    const [
        updateEmailApiCall,
        {
            isLoading: loadingEmailUpdate,
            isSuccess: successEmailUpdate,
            isError: iserrorEmailUpdate,
            error: errorEmailUpdate,
        },
    ] = useUpdateEmailMutation();

    // Function to send email verification
    const sendEmailVerification = async () => {
        const email = dashboardData?.data?.email;
        try {
            const token = localStorage.getItem("authToken"); // Get the token from localStorage or wherever you store it
            setEmailVarLoad(true)
            if (!dashboardData?.data?.emailVerified) {
                const request = await axios.post(
                    verificationEmailApi,
                    { email },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(request.data)
                if (request?.data?.status === "success") {
                    toast.success("Verification email sent successfully.");
                    setEmailVarLoad(false)
                }

                setVerifyBtn("Sent");
            } else {
                toast.success("email is verified already");
                setEmailVarLoad(false)
            }
        } catch (error) {
            toast.error("Failed to send verification email.");
            setEmailVarLoad(false)
        }
    };

    //   useEffect
    useEffect(() => {
        if (successVerifyPassword) {
            setShowPopUp(false);
            if (fieldToEdit === "name") {
                setIsEditingName(true);
            } else if (fieldToEdit === "phone") {
                setIsEditingPhone(true);
            } else if (fieldToEdit === "password") {
                setIsEditingPass(true);
            } else if (fieldToEdit === "email") {
                // New condition for email
                setIsEditingEmail(true);
            }
        }

        if (
            iserrorNameUpdate ||
            iserrorPhoneUpdate ||
            iserrorSendOtpUpdate ||
            iserrorPassUpdate ||
            iserrorEmailUpdate // New error condition for email
        ) {
            setFieldToEdit("");
            setIsEditingName(false);
            setIsEditingPhone(false);
            setIsEditingPass(false);
            setIsEditingEmail(false); // Reset email editing state
            setShowOtpField(false);
        }
        if (isErrorVerifyPassword) {
            toast.error(errorVerifyPassword?.data?.message);
        }
        if (fieldToEdit === "name" && iserrorNameUpdate) {
            toast.error(errorNameUpdate?.data?.message);
        }
        if (fieldToEdit === "phone" && iserrorPhoneUpdate) {
            toast.error(errorPhoneUpdate?.data?.message);
        }
        if (fieldToEdit === "phone" && iserrorSendOtpUpdate) {
            toast.error(errorSendotpUpdate?.data?.message);
        }
        if (fieldToEdit === "password" && iserrorPassUpdate) {
            toast.error(errorPassUpdate?.data?.message);
        }
        if (iserrorEmailUpdate) {
            toast.error(errorEmailUpdate?.data?.message);
        }
        if (successNameUpdate) {
            setIsEditingName(false);
        }
        if (successPasspdate) {
            setIsEditingPass(false);
        }
        if (successPhoneUpdate) {
            setShowOtpField(false);
            setPhone(null);
        }
        if (successSendotpUpdate) {
            setIsEditingPhone(false);
            setShowOtpField(true);
        }
        if (successEmailUpdate) {
            // Handle email update success
            setIsEditingEmail(false);
        }

        if (isError) {
            toast.error(errorPassUpdate?.data?.message);
        }
    }, [
        successVerifyPassword,
        isErrorVerifyPassword,
        isError,
        iserrorNameUpdate,
        successNameUpdate,
        iserrorPhoneUpdate,
        iserrorSendOtpUpdate,
        successSendotpUpdate,
        successPhoneUpdate,
        iserrorPassUpdate,
        successPasspdate,
        iserrorEmailUpdate,
        successEmailUpdate,
    ]);

    const orderHistory = useSelector(
        (state) => state?.orderHistory?.orderHistory?.productOrders
    );
    console.log("ghhvghvg", orderHistory);
    const getUserFirstLetter = () => {
        if (
            dashboardSuccess &&
            dashboardData &&
            dashboardData?.data?.firstName
        ) {
            return dashboardData?.data?.firstName.charAt(0).toUpperCase();
        }
        return "";
    };

    const getProgressValue = (status) => {
        switch (status) {
            case "new":
                return 5;
            case "dispatch":
                return 46;
            case "delivered":
                return 100;
            case "cancelled":
                return 100;
            default:
                return 0;
        }
    };

    // Assuming you want to show progress for the first order in the orderHistory array
    const currentOrderStatus =
        orderHistory && orderHistory.length > 0
            ? orderHistory[0].orderstatus
            : "";

    const progressValue = getProgressValue(currentOrderStatus);
    // edit name function
    const handleEditName = () => {
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        const capitalizedFirstName = capitalizeFirstLetter(firstName);
        const capitalizedLastName = capitalizeFirstLetter(lastName);
        updateNameApiCall({
            firstName: capitalizedFirstName,
            lastName: capitalizedLastName,
        });
    };

    // edit email function
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/;
    const handleEditEmail = () => {
        if (!emailRegex.test(email)) {
            toast.error("Email must be a valid Gmail or Yahoo address");
        } else {
            updateEmailApiCall({ email: email });
        }
    };
    const phoneNumberRegex = /^\+?\d{10}$/;

    const sendOtpFun = () => {
        if (!phoneNumberRegex.test(phone)) {
            toast.error("Invalid phone number format");
        } else {
            sendOtpApiCall({ phone: phone });
            toast.success("Otp has been sent to your New mobile Number");
        }
    };

    const handelEditPassword = () => {
        updatePassApiCall({ newPassword: password });
    };
    // Verify Otp
    const verifyOtp = () => {
        if (Number(otpData?.otp) === Number(otp)) {
            updatePhoneApiCall({ phone: phone });
            toast.success("New mobile number has been updated successfully");

        } else {
            toast.error("Otp is Incorrect");
        }
    };
    // All handle Change
    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handlePhoneChange = (e) => setPhone(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleOtpChange = (e) => setOtp(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value); // Handle email change

    return (
        <section className="profile_container">
            <div className="profile_content">
                <div className="profile_left_div">
                    <div className="profile_card">
                        <div className="profile_card_img">
                            <h2>{getUserFirstLetter()}</h2>
                        </div>

                        <div className="profile_card_text">
                            <>
                                {/* edit and Shoe Name */}
                                <p>
                                    Name :&nbsp;
                                    {isEditingName ? (
                                        <>
                                            <input
                                                type="text"
                                                value={firstName}
                                                onChange={handleFirstNameChange}
                                                placeholder={
                                                    dashboardSuccess
                                                        ? dashboardData?.data
                                                            ?.firstName
                                                        : ""
                                                }
                                            />{" "}
                                            <input
                                                type="text"
                                                value={lastName}
                                                onChange={handleLastNameChange}
                                                placeholder={
                                                    dashboardSuccess
                                                        ? dashboardData?.data
                                                            ?.lastName
                                                        : ""
                                                }
                                            />{" "}
                                            <button
                                                id="icon"
                                                onClick={handleEditName}
                                            >
                                                {loadingNameUpdate ? (
                                                    <TbLoader3 className="spinner" />
                                                ) : (
                                                    "Save"
                                                )}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                {dashboardSuccess
                                                    ? dashboardData?.data
                                                        ?.firstName
                                                    : ""}
                                            </span>{" "}
                                            <span>
                                                {dashboardSuccess
                                                    ? dashboardData?.data
                                                        ?.lastName
                                                    : ""}
                                            </span>
                                            &nbsp;
                                            {dashboardSuccess &&
                                                dashboardData.data
                                                    ?.password && (
                                                    <button
                                                        id="icon"
                                                        onClick={() => {
                                                            setShowPopUp(true);
                                                            setFieldToEdit(
                                                                "name"
                                                            );
                                                            setFirstName(
                                                                dashboardSuccess
                                                                    ? dashboardData
                                                                        ?.data
                                                                        ?.firstName
                                                                    : ""
                                                            );
                                                            setLastName(
                                                                dashboardSuccess
                                                                    ? dashboardData
                                                                        ?.data
                                                                        ?.lastName
                                                                    : ""
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                        </>
                                    )}
                                </p>
                                {/* Edit and Show Phone No. */}
                                {dashboardSuccess &&
                                    dashboardData?.data?.phone ? (
                                    <p>
                                        Phone No :&nbsp;
                                        {isEditingPhone ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    placeholder={
                                                        dashboardData?.data
                                                            ?.phone
                                                    }
                                                />{" "}
                                                {!successSendotpUpdate && (
                                                    <button
                                                        id="icon"
                                                        onClick={sendOtpFun}
                                                    >
                                                        {!loadingSendotpUpdate ? (
                                                            "Send Otp"
                                                        ) : (
                                                            <TbLoader3 className="spinner" />
                                                        )}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span>
                                                    {phone
                                                        ? phone
                                                        : dashboardData?.data
                                                            ?.phone}
                                                </span>
                                                &nbsp;
                                                <button
                                                    id="icon"
                                                    onClick={() => {
                                                        setPhone(
                                                            dashboardData?.data
                                                                ?.phone
                                                        );
                                                        setFieldToEdit("phone");
                                                        setShowPopUp(true);
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                            </>
                                        )}
                                    </p>
                                ) : (
                                    // Add Phone No.
                                    <p>
                                        {isEditingPhone ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    placeholder={
                                                        dashboardData?.data
                                                            ?.phone
                                                    }
                                                />{" "}
                                                {!successSendotpUpdate && (
                                                    <button
                                                        id="icon"
                                                        onClick={sendOtpFun}
                                                    >
                                                        {!loadingSendotpUpdate ? (
                                                            "Send Otp"
                                                        ) : (
                                                            <TbLoader3 className="spinner" />
                                                        )}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            !showOptField && (
                                                <button
                                                    id="icon"
                                                    onClick={() => {
                                                        setPhone("");
                                                        setFieldToEdit("phone");
                                                        setIsEditingPhone(true);
                                                    }}
                                                >
                                                    Add Phone No.
                                                </button>
                                            )
                                        )}
                                    </p>
                                )}

                                {/* // Otp Field  */}
                                {!successPhoneUpdate && showOptField && (
                                    <p className="EmailProfileScreenTextDiv">
                                        Enter Otp :&nbsp;
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Enter otp"
                                                onChange={handleOtpChange}
                                                value={otp}
                                            />

                                            <button
                                                id="icon"
                                                onClick={verifyOtp}
                                            >
                                                {loadingPhoneUpdate ? (
                                                    <TbLoader3 className="spinner" />
                                                ) : (
                                                    "verify"
                                                )}
                                            </button>
                                        </>
                                    </p>
                                )}
                                {/* edit and Show Password */}
                                {dashboardSuccess &&
                                    dashboardData.data?.password ? (
                                    <p className="EmailProfileScreenTextDiv">
                                        Password :&nbsp;
                                        {isEditingPass ? (
                                            <>
                                                <input
                                                    type="password"
                                                    onChange={
                                                        handlePasswordChange
                                                    }
                                                    placeholder="Password"
                                                    value={password}
                                                />{" "}
                                                <button
                                                    id="icon"
                                                    onClick={handelEditPassword}
                                                >
                                                    {loadingPassUpdate ? (
                                                        <TbLoader3 className="spinner" />
                                                    ) : (
                                                        "Save"
                                                    )}
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <span>***********</span>
                                                &nbsp;
                                                <button
                                                    id="icon"
                                                    onClick={() => {
                                                        setPassword("");
                                                        setShowPopUp(true);
                                                        setFieldToEdit(
                                                            "password"
                                                        );
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                            </>
                                        )}
                                    </p>
                                ) : (
                                    // Add Password
                                    dashboardSuccess &&
                                    dashboardData?.data?.phone && (
                                        <p>
                                            {" "}
                                            {isEditingPass ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        onChange={
                                                            handlePasswordChange
                                                        }
                                                        placeholder="demo"
                                                        value={password}
                                                    />{" "}
                                                    <button
                                                        id="icon"
                                                        onClick={
                                                            handelEditPassword
                                                        }
                                                    >
                                                        {loadingPassUpdate ? (
                                                            <TbLoader3 className="spinner" />
                                                        ) : (
                                                            "Save"
                                                        )}
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        id="icon"
                                                        onClick={() => {
                                                            setPassword(
                                                                "Password"
                                                            );
                                                            setFieldToEdit(
                                                                "password"
                                                            );
                                                            setIsEditingPass(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Add Password
                                                    </button>
                                                </>
                                            )}
                                        </p>
                                    )
                                )}

                                {/* edit and Shoe Email */}

                                {dashboardSuccess && (
                                    <p className="EmailProfileScreenTextDiv">
                                        Email :{" "}
                                        {isEditingEmail ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    placeholder="Enter new email"
                                                />{" "}
                                                <button
                                                    id="icon"
                                                    onClick={handleEditEmail}
                                                >
                                                    {loadingEmailUpdate ? (
                                                        <TbLoader3 className="spinner" />
                                                    ) : (
                                                        "Save"
                                                    )}
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <span
                                                    id="user_email"
                                                    className="use_email_span"
                                                >
                                                    {dashboardData?.data?.email
                                                        ? dashboardData?.data
                                                            ?.email
                                                        : "not Provided"}
                                                </span>
                                                &nbsp;
                                                <button
                                                    id="icon"
                                                    onClick={() => {
                                                        if (
                                                            dashboardData?.data
                                                                ?.email === ""
                                                        ) {
                                                            setShowPopUp(true);
                                                            setFieldToEdit(
                                                                "email"
                                                            );
                                                        } else {
                                                            sendEmailVerification();
                                                        }
                                                    }}
                                                    className={
                                                        verifyBtn === "Verify"
                                                            ? "VerifyBtnBlue"
                                                            : ""
                                                    }
                                                >



                                                    {emailVarLoad ? (
                                                        <TbLoader3 className="spinner" />
                                                    ) : (
                                                        dashboardData?.data?.email !== "" ? (
                                                            dashboardData?.data?.emailVerified ? (
                                                                "Verified"
                                                            ) : (
                                                                verifyBtn
                                                            )
                                                        ) : (
                                                            "Add"
                                                        )
                                                    )}
                                                </button>
                                                {dashboardData?.data?.email !==
                                                    "" && (
                                                        <button
                                                            id="icon"
                                                            onClick={() => {
                                                                if (
                                                                    dashboardData
                                                                        ?.data
                                                                        ?.email !==
                                                                    ""
                                                                ) {
                                                                    setShowPopUp(
                                                                        true
                                                                    );
                                                                    setFieldToEdit(
                                                                        "email"
                                                                    );
                                                                }
                                                            }}
                                                            className={
                                                                "VerifyBtnBlue"
                                                            }
                                                        >
                                                            {dashboardData?.data
                                                                ?.email !== ""
                                                                ? "update"
                                                                : ""}
                                                        </button>
                                                    )}
                                            </>
                                        )}
                                    </p>
                                )}
                            </>
                        </div>
                    </div>
                </div>

                <div className="profile_right_div">
                    <div className="profile_right_div_text">
                        <h3>
                            Current <span>Order</span>
                        </h3>
                        <p>Orders</p>
                        <div className="status-container">
                            <span>New</span> <span>Dispatch</span>
                            {currentOrderStatus === "cancelled" ? (
                                <span>Cancelled</span>
                            ) : (
                                <span>Delivered</span>
                            )}
                        </div>
                        <progress value={progressValue} max="100"></progress>
                    </div>
                </div>
            </div>

            {showPopUp && (
                <ProfilePassPopUp
                    setShowPopUp={() => setShowPopUp(!showPopUp)}
                    verifyPass={verifyPass}
                    loadingVerifyPassword={loadingVerifyPassword}
                />
            )}
        </section>
    );
}

export default Hero;
