import React from "react";
import "../../styles/Contactus/Hero.css";
import g1 from "../../Assets/Images/contact/Contact US.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { FaPhone, FaLocationDot } from "react-icons/fa6";
import { AiFillMail } from "react-icons/ai";
import { Zoom } from "react-awesome-reveal";

const Hero = () => {
  return (
    <div className="herocontact-main">
      <div className="herocontact-content">
        <div className="herocontact-text">
          <Zoom>
            <h1>CONTACT US</h1>
          </Zoom>
          <p className="heading-text">
          Welcome to our sanctuary of holistic healing! At Sahas Homeo, we're dedicated to supporting your wellness journey with personalized care and natural remedies. Whether you have questions, need guidance, or simply want to connect, our team of experienced homeopathy practitioners is here for you. Reach out today and let's embark on a path to vibrant health together.
          </p>
          <div className="responsive-view">
             <Player src={g1} autoplay loop className="player-responsive-image" />
          </div>
          <div className="halfitem">
            <div>
              <span>
                <FaLocationDot/>
              </span>
              <b>Address</b>
              <p>Satyanarayan Mandir, Kaladhungi Rd, Badripura, Rampur, Haldwani, Uttarakhand 263139</p>
            </div>
            <div>
              <span>
                {" "}
                <FaPhone />
              </span>
              <b>Mobile No.</b>
              <p>+91 7817822216</p>
            </div>
            <div>
              <span>
                <AiFillMail />
              </span>
              <b>E-mail</b>
              <p>contactus@sahashomeopharmacy.com</p>
            </div>
          </div>
        </div>
        <div className="herocontact-lottifile">
          <Player src={g1} autoplay loop />
        </div>
      </div>
    </div>
  );
};

export default Hero;
