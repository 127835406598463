import React, { useEffect, useState } from "react";
import "../../styles/BuyNowPage/OrderConfirmed.css";
import { IoMdCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
    setLabForm,
    setLabStep,
    setShowForm,
} from "../../store/CartSlice/CartSlice";
import { useDispatch } from "react-redux";
import { closeProcessCard } from "../../store/labSlice/processCard";
const OrderConfirmed = () => {
    const [showText, setShowText] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);

        localStorage.removeItem("labAddress");
        localStorage.removeItem("labBookDetails");
        localStorage.removeItem("allServices");
        localStorage.removeItem("labSetService");
        localStorage.removeItem("paymentData");

        dispatch(setShowForm(false));
        dispatch(setLabStep(0));
        dispatch(setLabForm(false));
        dispatch(closeProcessCard(true));
        const search = new URLSearchParams(window.location.search);
        if (search.get("labBooked")) {
            setShowText(search.get("labBooked")); 
        }
    }, [dispatch]);

    return (
        <>
            <div className="OrderConfirmed-cntainer">
                <div className="orderconcheck">
                    <div className="box-ordercon">
                        <h1>Awesome!</h1>
                        <div className="ceckorder-anim1">
                            <div className="ceckorder-anim2">
                                <div className="ceckorder-anim3">
                                    <div className="check-order-bt">
                                        <IoMdCheckmark className="check-check" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!showText ? (
                            <h3>Order Confirmed Successfully</h3>
                        ) : (
                            <h3>Lab Booked Successfully</h3>
                        )}

                        <div className="para-p">
                            {!showText ? (
                                <p>
                                    Your Order Is Confirmed. You Will Received
                                    An Order Confirmation Email/SMS Shortly With
                                    Expected Delivery Date For Your Purchase
                                    Items..
                                </p>
                            ) : (
                                <p>
                                    Your Lab Is Booked. You Will Received An Lab
                                    Booking Confirmation Email/SMS Shortly With
                                    date of your booking.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="order-button-con">
                    <button className="or-btn1" onClick={() => navigate("/")}>
                        Continue
                    </button>
                    {!showText && (
                        <button className="or-btn2" onClick={() => navigate("/profilescreen")}>View Order History</button>
                    )}
                </div>
            </div>
        </>
    );
};

export default OrderConfirmed;
