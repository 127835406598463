import React from "react";
import "../../styles/productCategory/MedicineByDisease.css";
import female from "../../Assets/Images/productCategory/female.png";
import icon1 from "../../Assets/Images/productCategory/icon1.png";
import male from "../../Assets/Images/productCategory/male.png";
import icon2 from "../../Assets/Images/productCategory/icon2.png";
import med from "../../Assets/Images/productCategory/med.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import Slider from "react-slick";
import { Zoom } from "react-awesome-reveal";
import { selectCategory } from "../../store/categorySlice/CategorySlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesByDisease } from "../../store/categories/categories";
import { fetchAllProductDataByCategory } from "../../store/productsData/productsData";

const MedicineByDisease = () => {
  const medicines = useSelector(getCategoriesByDisease)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CustomPrevButton = ({ onClick }) => (
    <button className="prevButton" onClick={onClick}>
      <IoIosArrowRoundBack className="prevIcon" />
    </button>
  );

  const CustomNextButton = ({ onClick }) => (
    <button className="nextButton" onClick={onClick}>
      <IoIosArrowRoundForward className="prevIcon" />
    </button>
  );

  // for capital letter
  const capitalizeFirstLetter = (str) => {
    if (!str) return ""; // Check if str is undefined or null
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleCategory = async (item) => {
    const categoryData = `${item.categoryName}`;
    dispatch(selectCategory(categoryData));
    navigate("/allproducts");
    await dispatch(
      fetchAllProductDataByCategory(categoryData)
    );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    rtl: true,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="medicineSection">
      <div className="medicines-innerContainer">
        <div class="medicineImgsLeft">

          <img src={female} alt="" className="image_first" />

        </div>

        <div className="medicinesSearch">

          <div className="medicineHeadingTop">
            <img className="medIconsFirst" src={med} alt="" />

            <div className="medicineHeadingSec">
              <img class="medIcons" src={icon1} alt="" />

              <h2>
                Medicines <span>By Disease</span>
              </h2>

              <img className="medIcons" src={icon2} alt="" />
            </div>
          </div>


        </div>

        <div className="medicinesImgsRight">

          <img src={male} alt="" className="image_first" />

        </div>
      </div>

      {medicines.length > 0 && (
        <div className="carousalBox">
          <Slider {...settings1}>
            {medicines?.map((medicine, index) => (

              <div
                key={index}
                className="sliderContentBox"
                onClick={() => {
                  handleCategory(medicine);
                }}
              >
                <div>
                  <img
                    src={medicine?.imageObj?.url}
                    alt={medicine.categoryName}
                  />
                </div>
                <p>{capitalizeFirstLetter(medicine.categoryName)}</p>
              </div>

            ))}
          </Slider>
        </div>
      )}


      {medicines.length > 0 && (
        <div className="carousalBox">
          <Slider {...settings2}>
            {medicines?.map((medicine, index) => (
              <Zoom>
                <div
                  key={index}
                  className="sliderContentBox"
                  onClick={() => {
                    handleCategory(medicine);
                  }}
                >
                  <div>
                    <img
                      src={medicine?.imageObj?.url}
                      alt={medicine.categoryName}
                    />
                  </div>
                  <p>{capitalizeFirstLetter(medicine.categoryName)}</p>
                </div>
              </Zoom>
            ))}
          </Slider>
        </div>
      )}

    </div>
  );
};

export default MedicineByDisease;
