import React from "react";

const Map = () => {

  return (
    <div className="Mapmain" style={{ width: "100%", height: "500px", margin: 'auto' }}>
      <div style={{ width: "90%", height: "450px", margin: 'auto' }}>
        <iframe
          src={`https://maps.google.com/maps?q=${29.2145731},${79.5258481}&z=${15}&output=embed`}
          width="100%"
          height="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="google map"
        ></iframe>
      </div>

    </div>
  );
};





export default Map;
