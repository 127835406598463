import React from "react";
import "../../../styles/Blogs/BlogDescription/Product.css";

function Product({ singleBlogDatas }) {
    // show youtube video according to url
    function getYouTubeVideoId(url) {
        const regex =
            /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/;
        const match = url?.match(regex);
        return match ? match[1] || match[2] : null;
    }
    const url = singleBlogDatas?.blogYTLink;
    const videoId = getYouTubeVideoId(url);

    return (
        <>
            <section className="blogDescription_container">
                {singleBlogDatas?.allSUbHeading?.map((subHeading, index) => (
                    <div className="blogDescription_content" key={index}>
                        <div className="blogDescription_left_div">
                                <div>
                                    <h2>{subHeading?.subTitle}</h2>
                                    <p>{subHeading?.shortDiscription}</p>
                                </div>
                        </div>

                            <div className="blogDescription_right_div">
                                <img
                                    src={subHeading?.imageObj[0]?.url}
                                    alt=""
                                />
                            </div>
                    </div>
                ))}
            </section>
            {singleBlogDatas?.blogYTLink && (
                <>
                    <div className="yt-container">
                        <iframe
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="yt-videos"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="yt_video"
                        ></iframe>
                    </div>
                </>
            )}
        </>
    );
}

export default Product;
