import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCategory: "All Products",
  allProductData: [],
  categoryByDisease: [],
  categoryByBrand: [],
  categoryByMedicine: [],
  mostSellingProduct: [],
  wishListCount: [],
  cartItemCount: [],
  saveSearchData: []
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    selectCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    saveAllProducts(state, action) {
      state.allProductData = action.payload;
    },
    saveCategoryByDisease(state, action){
      state.categoryByDisease = action.payload;
    },
    saveCategoryByBrand(state, action){
      state.categoryByBrand = action.payload;
    },
    saveCategoryByMedicine(state, action){
      state.categoryByMedicine = action.payload;
    },
    saveMostSellingProduct(state, action){
      state.mostSellingProduct = action.payload;
    },
    saveCartItemCount(state, action){
      state.cartItemCount = action.payload;
    },
    saveWishlistCount(state, action){
      state.wishListCount = action.payload;
    },
    saveSearchData(state, action){
      state.saveSearchData = action.payload;
    },
  },
});

export const { selectCategory, saveAllProducts,saveCategoryByDisease,saveCategoryByBrand, saveCategoryByMedicine,saveMostSellingProduct,saveCartItemCount,saveWishlistCount,saveSearchData} = categorySlice.actions;
export const selectedCategorySelector = (state) =>
  state.category.selectedCategory;
export const allProductDataSelector = (state) => state.category.allProductData;
export const categoryByDiseaseSelector = (state) => state.category.categoryByDisease;
export const categoryByBrandSelector = (state) => state.category.categoryByBrand;
export const categoryByMedicineSelector = (state) => state.category.categoryByMedicine;
export const mostSellingProductSelector = (state) => state.category.mostSellingProduct;
export const storeCartItemSelector = (state) => state.category.mostSellingProduct;
export const storeWishlistSelector = (state) => state.category.mostSellingProduct;
export const storeSearchDataSelector = (state) => state.category.saveSearchData;


export default categorySlice.reducer;
