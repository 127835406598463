import { useState } from "react";
import "./style.css";
const Input = ({ onChange }) => {
  const [value, setValue] = useState("");
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange(e);
    };
  return (
    <div className="input_container">
      <input value={value} onChange={handleChange} type="text" placeholder="Search here.." />
    </div>
  );
};

export default Input;
