import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { userAddressApi, userAddressDeleteApi, userAddressUpdateApi } from '../../Constents/apiAxios';

// Initial State
const initialState = {
    addresses: [],
    addressToUpdate: null,
    isModalOpen: false,
    loading: false,
    error: null,
};

// Async Thunks
export const fetchUserAddresses = createAsyncThunk(
    "userAddress/fetchUserAddresses",
    async (token, thunkAPI) => {
        try {
            const response = await axios.get(userAddressApi, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


// delete the user address 
export const deleteUserAddress = createAsyncThunk(
    'userAddress/deleteUserAddress',
    async (addressId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().login?.token;
            const response = await axios.delete(`${userAddressDeleteApi}/${addressId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Async thunk to update the user address
export const updateUserAddress = createAsyncThunk(
    'userAddress/updateUserAddress',
    async (addressDataToUpdate, thunkAPI) => {
        try {
            const token = thunkAPI.getState().login?.token;
            const response = await axios.put(`${userAddressUpdateApi}/${addressDataToUpdate?._id}`, addressDataToUpdate, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


// Slice
const userAddressSlice = createSlice({
    name: "userAddress",
    initialState,
    reducers: {
        openModalAddress: (state) => {
            state.isModalOpen = true;
        },
        closeModalAddress: (state) => {
            state.isModalOpen = false;
        },
        addressToUpdate: (state, action) => {
            state.addressToUpdate = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserAddresses.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserAddresses.fulfilled, (state, action) => {
                state.loading = false;
                state.addresses = action.payload.data;
            })
            .addCase(fetchUserAddresses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteUserAddress.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteUserAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.addresses = state.addresses.filter(address => address._id !== action.payload.deletedId);
            })
            .addCase(deleteUserAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateUserAddress.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUserAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.addresses = state.addresses.map(address =>
                    address._id === action?.payload?.data?._id ? action?.payload?.data : address
                );
            })
            .addCase(updateUserAddress.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            });
    },
});

export default userAddressSlice.reducer;

// Export the modal actions
export const { openModalAddress, closeModalAddress, addressToUpdate } = userAddressSlice.actions;

export const selectUserAddresses = (state) => state?.addressData?.addresses;
export const userAddressLoading = (state) => state?.addressData?.loading;
export const userAddressError = (state) => state?.addressData?.error;
export const userAddressModal = (state) => state?.addressData?.isModalOpen;
export const userAddressData = (state) => state?.addressData?.addressToUpdate;
