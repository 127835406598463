import React, { useEffect } from "react";
import Hero from "../components/medicalTest/labDescription/Hero";
import Lab from "../components/medicalTest/labDescription/Lab";
import Services from "../components/medicalTest/labDescription/Services";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleLab, selectSingleLab } from "../store/labSlice/singleLab";
import Loader from "../components/loader/Loader";
import { useParams } from "react-router-dom";



function LabDescription() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const loader = useSelector((state) => state.singleLab.status);
    const singleLabData = useSelector(selectSingleLab);
    const { labId } = useParams(); // Fetch labId from the path using useParams
    console.log(labId)
    console.log(loader, singleLabData)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSingleLab(labId))
    }, [labId, dispatch])


    return (
        <div>
            {loader === "loading" ? <Loader /> : <>
                {singleLabData ? <>
                    <Hero singleLabData={singleLabData?.labDetail} />
                    <Lab />
                    <Services />
                </> : ""}

            </>}
        </div>
    );
}

export default LabDescription;
