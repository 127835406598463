import React, { useEffect, useState } from "react";
import "../../../styles/medicalTest/Process_card.css";
import { RxCross2 } from "react-icons/rx";
import { CgArrowLeft } from "react-icons/cg";
import first from "../../../Assets/Images/medicalTest/card/first.png";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../store/labSlice/labSlice";
import Address from "./Address";
import { bookLab , labPackageBook} from "../../../store/labSlice/labBooking";
import { useNavigate } from "react-router-dom";
import { closeProcessCard } from "../../../store/labSlice/processCard";

import { setLabPayment, setLabStep } from "../../../store/CartSlice/CartSlice";
import { setLabAddress } from "../../../store/addressSlice";
import logo from "../../../Assets/Images/logo/head.png";

function ProcessCard({ service, selectedLab }) {
    const SelectedStep = useSelector((state) => state.products.labStep);
    const SelectedLabPayment = useSelector((state) => state.products.labPayment);
    const [selectedLabService, setSelectedLabService] = useState();

    const navigate = useNavigate();
    const processCard = useSelector((state) => state.processCard?.processCard);
    const user = useSelector((state) => state?.login?.user);
    const token = useSelector((state) => state?.login?.token);
    const dispatch = useDispatch();
    const allservices = useSelector(getServices);
    const [filteredServices, setFilteredServices] = useState([]);

    // razor pay script
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const labSetService = JSON.parse(localStorage.getItem("labSetService"));

    useEffect(() => {
        if (
            processCard &&
            labSetService &&
            !selectedLab &&
            !SelectedLabPayment
        ) {
            const filtered = allservices.filter(
                (s) => s.serviceName === labSetService
            );
            setFilteredServices(filtered);
            dispatch(setLabStep(1)); // Move to step 1
        } else if (
            processCard &&
            selectedLab &&
            !labSetService &&
            !SelectedLabPayment
        ) {
            // Set labId, serviceId, sellingPrice, and move to step 2
            setFormData((prevData) => ({
                ...prevData,
                lab: selectedLab.labId,
                labservice: selectedLab._id,
                finalPrice: selectedLab.sellingPrice,
            }));
            setSelectedLabService(selectedLab)
            dispatch(setLabStep(2)); // Move to step 2
        }
    },  [processCard, labSetService, selectedLab, SelectedLabPayment, allservices, dispatch]);

    const handleClose = () => {
        dispatch(closeProcessCard(false));
    };

    const [formData, setFormData] = useState({
        lab: "",
        labservice: "",
        atplace: "",
        finalPrice: 0,
        time: "",
        date: "",
        transitionId: "",
        referenceId: "",
        address: {
            fullName: `${user?.firstName} ${user?.lastName}`,
            mobileNo: user?.phone,
            email: user?.email,
        },
    });
    dispatch(setLabAddress(formData));

    const handleNextBtn = () => {
        if (SelectedStep === 1 && !formData.lab) {
            alert("Please select a lab before proceeding.");
            return;
        } else if (
            SelectedStep === 2 &&
            (!formData?.atplace ||
                !formData.date ||
                !formData.time ||
                !formData.address)
        ) {
            alert("Please fill in all details before proceeding.");
            return;
        } else if (
            SelectedStep === 3 &&
            (!formData.type || formData.type === "")
        ) {
            // Corrected condition here
            dispatch(setLabPayment(true));
            alert("Please select a payment type before proceeding.");
            return;
        }

        if (SelectedStep < 5) {
            dispatch(setLabStep(SelectedStep + 1));
        }
    };

    const handleBackBtn = () => {
        if (SelectedStep > 1) {
            dispatch(setLabStep(SelectedStep - 1));
        }
    
    };

    const handleFormChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        localStorage.setItem("labAddress", JSON.stringify(formData));
    };

    const handleRazorpayPayment = async () => {
        let formDataWithExtraData = { ...formData };
        try {
            const options = {
                key: "rzp_test_4uxwXGLBXfhAQs",
                amount: formData?.finalPrice * 100, // Convert to paise
                currency: "INR",
                name: "sahas homeo",
                description: "Test payment",
                image: logo,
                handler: async (response) => {
                    formDataWithExtraData = {
                        ...formDataWithExtraData,
                        paymentgatway: response,
                        paid: true,
                        type: "online",
                    };
                    // Send data to your backend API
                    try {
                        const apiResponse = await dispatch(bookLab(formDataWithExtraData));
                        if (apiResponse?.payload?.status === "success") {
                            // Payment and booking successful
                            navigate(`/orderConfirmed?labBooked=true`);
                        } else {
                            // Handle error from the API
                            console.error(
                                "Error booking lab:",
                                apiResponse.error
                            );
                        }
                    } catch (error) {
                        console.error("Error booking lab:", error);
                    }
                },

                prefill: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    contact: user.phone,
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error("Error handling Razorpay payment:", error);
        }
    };

    const handleFormSubmit = async () => {
        let formDataWithExtraData = { ...formData };

        if (token) {
            if (
                formData.lab &&
                formData?.atplace &&
                formData.date &&
                formData.time &&
                formData.type
            ) {
                if (formData.type === "online") {
                    // Call the Razorpay payment function
                    handleRazorpayPayment();
                } else {
                    formDataWithExtraData = {
                        ...formDataWithExtraData,
                        type: "cod",
                        paid: false,
                    };
                    dispatch(setLabAddress(formData));

                    localStorage.setItem(
                        "labAddress",
                        JSON.stringify(formData)
                    );
                    const response = await dispatch(bookLab(formDataWithExtraData));
                    if (response?.payload?.status === "success") {
                        navigate(`/orderConfirmed?labBooked=true`);
                    }
                }
            } else {
                alert(
                    "Please Choose the payment method before submitting the form."
                );
            }
        } else {
            toast.info("please login first");
            dispatch(closeProcessCard(false));
            document.body.style.overflow = "";
            navigate("/login");
        }
    };


    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero
        const day = today.getDate().toString().padStart(2, '0'); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    return (
        <div className="function_card">
            <div className="function_card_head">
                <div>
                    <CgArrowLeft
                        className="f_card_arrow"
                        onClick={handleBackBtn}
                    />
                </div>
                <div>
                    <RxCross2 className="f_card_cross" onClick={handleClose} />
                </div>
            </div>

            {SelectedStep === 1 && (
                <>
                    <div className="form_first">
                        <h2>Select Your Lab</h2>
                        <div className="allLabService">
                            {filteredServices.map((lab, index) => (
                                <div className="form_data" key={index}>
                                    <div className="labradio">
                                        <input
                                            type="radio"
                                            className="radioButton"
                                            id={`service-${index}`}
                                            name="lab"
                                            value={lab.labName}
                                            onChange={(e) => {
                                                handleFormChange(
                                                    "lab",
                                                    e.target.value
                                                );
                                                handleFormChange(
                                                    "finalPrice",
                                                    lab.sellingPrice
                                                );
                                                handleFormChange(
                                                    "lab",
                                                    lab.labId
                                                );
                                                handleFormChange(
                                                    "labservice",
                                                    lab._id
                                                );
                                                setSelectedLabService(lab);
                                            }}
                                        />
                                        <label
                                            htmlFor={`service-${index}`}
                                            className="labCss"
                                        >
                                            <p>{lab.labName}</p>
                                            <h3 className="priceCss">
                                                Price: {lab.sellingPrice}
                                            </h3>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="function_bottom">
                            <button onClick={handleNextBtn}>Process</button>
                        </div>
                    </div>
                </>
            )}

            {SelectedStep === 2 && (
                <>
                    <div className="form_two">
                        <div className="form_two_first_div">
                            {
                                selectedLabService?.availableAt?.map((item, index) => (
                                    <div>
                                        <input
                                            type="radio"
                                            className="radioButton"
                                            id={`at${item}`}
                                            name="atplace"
                                            value={item}
                                            onChange={() =>
                                                handleFormChange("atplace", item)
                                            }
                                        />
                                        <label
                                            htmlFor={`at${item}`}
                                            className="labCss labLebel"
                                        >
                                            At {item}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>

                        {formData?.atplace === "home" && (
                            <Address
                                formData={formData}
                                handleFormChange={handleFormChange}
                            />
                        )}

                        <div className="dateForm">
                            <label htmlFor="">Date</label>
                            <input
                                type="date"
                                name="date"
                                min={getTodayDate()} // Set minimum date to today
                                onChange={(e) =>
                                    handleFormChange("date", e.target.value)
                                }
                            />
                        </div>

                        <div className="dateForm">
                            <label htmlFor="">Time</label>
                            <select
                                className="selectTime"
                                name="time"
                                id=""
                                onChange={(e) =>
                                    handleFormChange("time", e.target.value)
                                }
                            >
                                <option value="">Choose Slot</option>
                                <option value="10:00 AM">10:00 AM</option>
                                <option value="11:00 AM">11:00 AM</option>
                                <option value="12:00 PM">12:00 PM</option>
                                <option value="1:00 PM">1:00 PM</option>
                                <option value="2:00 PM">2:00 PM</option>
                                <option value="3:00 PM">3:00 PM</option>
                                <option value="4:00 PM">4:00 PM</option>
                            </select>
                        </div>
                    </div>

                    <button className="processButton" onClick={handleNextBtn}>
                        Process
                    </button>
                </>
            )}

            {SelectedStep === 3 && (
                <>
                    <div action="" className="form_three">
                        <h2>Select Your Payment Type</h2>
                        <div>
                            <div className="form_data">
                                <div className="labradio">
                                    <input
                                        type="radio"
                                        className="radioButton"
                                        id={`cashpayment`}
                                        name="type"
                                        onChange={() =>
                                            handleFormChange("type", "cod")
                                        }
                                    />
                                    <label
                                        htmlFor={`cashpayment`}
                                        className="labCss payment"
                                    >
                                        <h3>Cash</h3>
                                    </label>
                                </div>
                            </div>

                            <div className="form_data">
                                <div className="labradio">
                                    <input
                                        type="radio"
                                        className="radioButton"
                                        id={`onlinepayment`}
                                        name="type"
                                        onChange={() =>
                                            handleFormChange("type", "online")
                                        }
                                    />
                                    <label
                                        htmlFor={`onlinepayment`}
                                        className="labCss payment"
                                    >
                                        <h3>Online(UPI/Card)</h3>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="function_bottom">
                        <button onClick={handleFormSubmit}>Process</button>
                    </div>
                </>
            )}

            {SelectedStep === 4 && (
                <>
                    <div action="" className="form_four">
                        <h4>
                            <span>Your Test</span> Booked Successfully
                        </h4>
                        <img src={first} alt="" />
                    </div>
                </>
            )}
        </div>
    );
}

export default ProcessCard;
