import React, { useEffect, useState } from "react";
import "../../styles/LoginSignup/Signup.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    googleLoginAction,
    loginAction,
} from "../../store/actions/logIn/LogInAction";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { selectUser } from "../../store/reducers/login/logInReducer";
import { logOutAction } from "../../store/actions/logIn/LogOutAction";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { setForgotModal, setLoginModal, setSignupModal } from "../../store/signupSlice/signupSlice";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";

const Login = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [showPassword, setShowPassword] = useState(false);

    const passVisible = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState({
        phone: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/; // Change here: limit to Gmail and Yahoo
    const phoneNumberRegex = /^\+?\d{10}$/;

    const handleSubmit = (e) => {
        e.preventDefault();

        const { phone } = formData;

        if (!emailRegex.test(phone) && !phoneNumberRegex.test(phone)) {
            toast.error("Input must be a valid Gmail or Yahoo email address, or a valid phone number");
            return;
        }

        const redirectUrl = localStorage.getItem("redirectUrl");

        dispatch(loginAction(formData));
        if (redirectUrl && user) {
            localStorage.removeItem("redirectUrl");
            navigate(redirectUrl);
        } 
        // else {
        //     navigate("/");
        // }

        setFormData({
            phone: "",
            password: "",
        });
    };

    const handleLogOut = () => {
        dispatch(logOutAction());
    };
   
    return (
        <div className="signupSection">
            <div className="signupContainer">
            <RxCross2  className="login_signup_close_btn" onClick={() =>navigate("/")}/>
                <h1>
                    Welcome <span>Back</span>
                </h1>

                <div className="googleSignup">
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
                        <GoogleLogin
                            onSuccess={(credentialResponse) => {
                                const decoded = jwtDecode(
                                    credentialResponse?.credential
                                );
                                console.log("log in details", credentialResponse?.credential)
                                dispatch(
                                    googleLoginAction({
                                        firstName: decoded.given_name,
                                        lastName: decoded.family_name,
                                        email: decoded.email,
                                        picture: decoded.picture,
                                        gmail: true,
                                    })
                                );
                                console.log(decoded);
                            }}
                            onError={() => {
                                console.log("Login Failed");
                            }}
                        />
                    </GoogleOAuthProvider>
                </div>

                <form onSubmit={handleSubmit} className="formContainer">
                    <div className="signupWithEmail">
                        <div className="signupBorders"></div>
                        <div>Login With Email</div>
                        <div className="signupBorders"></div>
                    </div>

                    <div className="formInputs">
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Enter Email or Phone Number"
                            required
                        />

                        <div className="inputPassSec">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter The Password"
                                required
                            />
                            <span onClick={passVisible}>
                                {showPassword ? (
                                    <FaRegEyeSlash />
                                ) : (
                                    <FaRegEye />
                                )}
                            </span>
                        </div>
                        <button type="submit">Login</button>
                    </div>

                    <p>
                        Don't have account?{" "}
                        <span onClick={() => {
                            dispatch(setLoginModal(false))
                            dispatch(setSignupModal(true))
                        }}>Sign-up</span>{" "}
                        {user && <span onClick={handleLogOut}>Log-out</span>}
                    </p>
                    <p>
                        Already have an Account{" "}
                        <span onClick={() => {
                            dispatch(setLoginModal(false))
                            dispatch(setSignupModal(false))
                            dispatch(setForgotModal(true))
                        }}>Forgot Password</span>{" "}
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;
