import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import '../../styles/SingleProduct/SinglePageFAQ.css'
import { Bounce} from "react-awesome-reveal";

//// FAQ data /////
const preWrittenFAQs = [
  {
      title: "Are homeopathy medicines safe and effective?",
      content: [

      
          {
              text: "Homeopathy is a system of alternative medicine that relies on natural substances to stimulate the body's own healing processes. While opinions on its effectiveness vary, many people find relief from various ailments using homeopathic remedies. However, it's essential to consult with a qualified healthcare professional before starting any new treatment regimen.",
          },
      ],
  },
  {
      title: "How do I choose the right homeopathy medicine for my condition?",
      content: [
          {
              text: "Selecting the appropriate homeopathic medicine involves understanding your symptoms and matching them to the symptoms listed for each remedy. Homeopathic remedies are chosen based on the principle of 'like cures like,'' meaning a substance that causes symptoms in a healthy person can be used to treat similar symptoms in a sick person. If you're unsure about which remedy to choose, consider consulting with a qualified homeopathic practitioner for personalized advice.",
          },
      ],
  },
  {
      title: "Is it safe to purchase homeopathy medicines online?",
      content: [
          {
              text: "Buying homeopathy medicines online can be safe as long as you purchase from a reputable and licensed pharmacy or retailer. Look for websites that provide detailed product information, including ingredients, dosage instructions, and potential side effects. Additionally, ensure that the website follows regulations regarding the sale of pharmaceutical products and offers secure payment options to protect your personal information. Always exercise caution and verify the credibility of the online store before making a purchase.",
          },
      ],
  },
];

const AccordionItem = ({ title, content, isActive, onClick,  }) => (
    <div className='accordionmain'>
      <div
        onClick={onClick}
        className={`accordion-title ${isActive ? 'active' : ''}`}
      >
        <h3>{title}</h3>
        <div className="icon-container">
          {isActive ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      <div className="accordion-content" style={{ display: isActive ? 'block' : 'none' }}>
        {content.map((contentItem, contentIndex) => (
          <div key={contentIndex}>
            <p>{contentItem.text}</p>
          </div>
        ))} 
      </div>
    </div>
  );
const SinglePageFAQ = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
 
  const renderedItems = [
    ...preWrittenFAQs.map((item, index) => (
      <AccordionItem
        key={`pre-written-${index}`}
        title={item.title}
        content={item.content}
        isActive={index === activeIndex}
        onClick={() => onTitleClick(index)}
      />
    )),
    ...items.productDetail.faq.map((item, index) => (
      <AccordionItem
        key={`api-${index}`}
        title={item.question}
        content={[{ text: item.answer }]}
        isActive={index + preWrittenFAQs.length === activeIndex}
        onClick={() => onTitleClick(index + preWrittenFAQs.length)}
      />
    )),
  ];

  return <div className='section5main'>
    <div className='section5content'>
        <div className='section5text'>
        <Bounce>
            <h1><span>Frequently</span> Asked Questions</h1>
            </Bounce>
        </div>

        <div className='section5item'>

          {renderedItems}
    
          </div>
    </div>
  </div>;
};

export default SinglePageFAQ;