// mostSellingProductsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { mostsellingproduct, allProducts, getAllProductByCatWebsite, filterProduct, searchProduct } from "../../Constents/apiAxios";
import { clearFilter } from "../filterSlice/filterSlice";

// Define the initial state
const initialState = {
    mostSellingProducts: [],
    products: [],
    singleProduct: null,
    currentPage: 1,
    currentSizePotency: { size: "", potency: "" },
    currentCategory: "normalProduct",
    loadingNextPage: false,
    loading: false,
    error: null,

};

// Define the async thunk for fetching most selling products
export const fetchMostSellingProducts = createAsyncThunk(
    "mostSellingProducts/fetchMostSellingProducts",
    async (_, { dispatch }) => {
        try {
            const response = await axios.get(mostsellingproduct);
            const data = response?.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);


// Define the async thunk for fetching all products
export const fetchAllProductsData = createAsyncThunk(
    "products/fetchAllProductsData",
    async (_, { dispatch, getState }) => {
        try {

            const currentPage = getState().productsData.currentPage;
            const response = await axios.get(`${allProducts}?page=${currentPage}`);
            // dispatch(setCurrentCategory("normalProduct"))
            dispatch(clearFilter())
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

// Define the async thunk for fetching all product data by category
export const fetchAllProductDataByCategory = createAsyncThunk(
    "products/fetchAllProductDataByCategory",
    async (category, { dispatch }) => {
        try {
            const response = await axios.get(`${getAllProductByCatWebsite}/${category}`);
            // dispatch(setCurrentCategory("categoryProduct"))
            dispatch(clearFilter())
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

// Define the async thunk for fetching all product data by category
export const fetchAllProductDataBySearch = createAsyncThunk(
    "products/fetchAllProductDataBySearch",
    async (search, { dispatch, getState }) => {
        try {
            const response = await axios.get(`${searchProduct}/${search}`);
            // dispatch(setCurrentCategory("searchProduct"))
            dispatch(clearFilter())
            console.log("response redux product", response.data)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

// Define the async thunk for fetching all product data by category
export const fetchAllProductDataByFilter = createAsyncThunk(
    "products/fetchAllProductDataByFilter",
    async (filter, { dispatch, getState }) => {
        try {
            const response = await axios.post(`${filterProduct}`, filter);
            // dispatch(setCurrentCategory("filterProduct"))
            console.log("filter by category ::", response.data)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);


// Create the most selling products slice
const ProductsSliceData = createSlice({
    name: "AllProducts",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload
        },
        setCurrentSizePotency: (state, action) => {
            state.currentSizePotency = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchMostSellingProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMostSellingProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.mostSellingProducts = action?.payload?.allProduct;
            })
            .addCase(fetchMostSellingProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAllProductsData.pending, (state) => {
                if (state.currentPage > 1) {
                    state.loadingNextPage = true; // Set loadingNextPage to true if currentPage > 1
                } else {
                    state.loading = true; // Otherwise, set loading to true
                }
                state.error = null;
            })
            .addCase(fetchAllProductsData.fulfilled, (state, action) => {
                if (state.currentPage > 1) {
                    // If currentPage > 1, concatenate the new products with the existing products
                    state.products = [...state.products, ...action.payload.allProduct];
                } else {
                    // Otherwise, set the products to the new data
                    state.products = action.payload.allProduct;
                }

                state.loadingNextPage = false;
                state.loading = false;
            })
            .addCase(fetchAllProductsData.rejected, (state, action) => {
                if (state.searchCurrentPage > 1) {
                    state.loadingNextPage = false; // Set loadingNextPage to false if currentPage > 1
                } else {
                    state.loading = false; // Otherwise, set loading to false
                }
                state.error = action.error.message;
            })


            .addCase(fetchAllProductDataByCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllProductDataByCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.allProduct;
                // state.currentCategory = "categoryProduct";
            })
            .addCase(fetchAllProductDataByCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAllProductDataBySearch.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllProductDataBySearch.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.allProduct;
                // state.currentCategory = "searchProduct";

            })
            .addCase(fetchAllProductDataBySearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAllProductDataByFilter.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllProductDataByFilter.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.allProduct;

            })
            .addCase(fetchAllProductDataByFilter.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

    },
});

// Export actions and reducer
export const { setCurrentPage, setCurrentCategory,setCurrentSizePotency } =
    ProductsSliceData.actions;

export default ProductsSliceData.reducer;

export const getMostSellingProducts = (state) => state?.productsData?.mostSellingProducts;
export const getAllProducts = (state) => state?.productsData?.products;
export const loadingProduct = (state) => state?.productsData?.loading;
export const getCurrentPage = (state) => state?.productsData?.currentPage;
export const getNextPageLoading = (state) => state?.productsData?.loadingNextPage;
export const getCurrentCategory = (state) => state?.productsData?.currentCategory;
export const getCurrentSizePotency = (state) => state?.productsData?.currentSizePotency;

