import React from "react";
import "../../styles/medicalTest/Hero.css";

function Hero() {
    return (
        <section className="medical_test_hero">
            <div className="medical_content">
                <h3>Precision Diagnostics</h3>
                <h2>
                    ADVANCING HEALTHCARE{" "}
                    <span>
                        THROUGH STATE-OF-THE-ART MADICAL LABORATORY TESTING
                    </span>
                </h2>
            </div>
        </section>
    );
}

export default Hero;
