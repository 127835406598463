import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SET_USER_DATA,
    SET_AUTH_TOKEN,
} from "../../actions/logIn/LogInAction";
import { LOGOUT_SUCCESS } from "../../actions/logIn/LogOutAction";

const initialState = {
    user: null,
    token: null,
    loading: false,
    error: null,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                loading: false,
                error: null,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                user: null,
                token: null,
                loading: null,
                error: null,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                token: null,
                loading: false,
                error: null,
            };
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload,
                token: null,
                loading: false,
                error: null,
            };
            case SET_AUTH_TOKEN:
                return {
                    ...state,
                    token: action.payload,
                    loading: false,
                    error: null,
                };
        default:
            return state;
    }
};

export default loginReducer;

export const selectToken = (state) => state.login.token;
export const selectUser = (state) => state.login.user;
