import React, { useState } from "react";
import "../../styles/ProfileScreen/OrderPopUp.css";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { setCurrentSizePotency } from "../../store/productsData/productsData";
import RatingReviewPopUp from "./RatingReviewPopUp";

function OrderPopUp({ orderData, onClose, ratingPopUp }) {
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ratingModal, showRatingModal] = useState(false)

    const handlePopUpModel = () => {
        showRatingModal(!ratingModal);
    };

    return (
        <div className="order-history-container">
            <div className="Order_history_pop_up">
                <div className="nameorder">
                    <h1>Order details Page</h1>
                    <h5 onClick={onClose}>
                        <RxCross2 />
                    </h5>
                </div>
                <div className="Order_history_pop_up_first_div">
                    <div className="order-history-popuplist">
                        <p>
                            Order ID: <span>{orderData?.OrderId}</span>
                        </p>
                        <p>
                            Final Price: <span>{orderData?.finalPrice}</span>
                        </p>
                    </div>

                    <div className="orderbox1">
                        <p>
                            Order Status: <span>{orderData.orderstatus}</span>
                        </p>
                        <p>
                            Payment Type: <span>{orderData.type}</span>
                        </p>
                    </div>
                    <div className="order-adress">
                        <p>Address:</p>
                        <p>
                            <span>{orderData?.address?.fullName}</span>
                            <span> {orderData?.address?.addressLine1}</span>
                            <span> {orderData?.address?.addressLine2}</span>
                            <span> {orderData?.address?.city}</span>
                            <span> {orderData?.address?.state}</span>
                            <span> {orderData?.address?.country}</span>
                        </p>
                    </div>
                </div>

                <div className="Order_history_pop_up_second_div">
                    {orderData?.products?.map((item, index) => (
                        <div key={index} className="Order_history_card">
                            <div className="Order_history_card_img_box">
                                <img
                                    src={item?.productImage}
                                    alt="product_image"
                                    className="Order_history_card_img_box_img"
                                />
                            </div>
                            <div>
                                <div>
                                    <p
                                        onClick={() => {
                                            dispatch(
                                                setCurrentSizePotency({
                                                    size: item?.size,
                                                    potency: item?.potency,
                                                })
                                            );
                                            navigate(
                                                `/singleproduct/${item?.productId}`
                                            );
                                        }}
                                    >
                                        Name:
                                        <span>
                                            {` ${item?.brand} ${item?.productName} ${item?.potency} ${item?.size}`}
                                        </span>
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        Price:
                                        <span>{item?.sellingPrice}</span>
                                    </p>
                                    <p>
                                        qty:
                                        <span>{item?.quantity}</span>
                                    </p>

                                    {ratingPopUp === true && (
                                        <button onClick={handlePopUpModel}>Add review</button>
                                    )}
                                </div>
                            </div>
                            {ratingModal && <RatingReviewPopUp close={handlePopUpModel} productId={item?.productId} productVariantId={item?.productVariantId}/>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OrderPopUp;
