import React, { useEffect, useState } from "react";
import "../../styles/SingleProduct/SinglePageUseFul.css";
import { Zoom, Bounce } from "react-awesome-reveal";
import {  useSelector } from "react-redux";
import { getCategoriesByDisease } from "../../store/categories/categories";

const SinglePageUseFul = ({ usefulData }) => {
    const allCategories = useSelector(getCategoriesByDisease);
    const [filterCat, setFilterCat] = useState([]);
    useEffect(() => {
        const dataTouse =
            allCategories?.filter((item) => {
                return usefulData.includes(item?.categoryName);
            });

        setFilterCat(dataTouse);
    }, [allCategories, usefulData]);
    console.log(filterCat)

    return (
        <div className="section2main">
            <Bounce>
                <h1>
                    Useful <span>In</span>
                </h1>
            </Bounce>
            <div className="section2content">
                <Zoom>
                    {filterCat.map((item, index) => (
                        <div className="usefulCard" key={index}>
                            <div className="usefulImg">
                                <img src={item?.imageObj?.url} alt="" />
                            </div>

                            <div className="usefulText">
                                <p>{item?.categoryName}</p>
                            </div>
                        </div>
                    ))}
                </Zoom>
            </div>
        </div>
    );
};

export default SinglePageUseFul;
