import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { bookingLab, labPackageBooking } from "../../Constents/apiAxios";
// Define the initial state
const initialState = {
    status: "idle", // or 'loading', 'succeeded', 'failed'
    error: null,
    processForm: localStorage.getItem("processForm")
        ? localStorage.getItem("processForm")
        : false,
};

// Define the thunk for sending the booking request
export const bookLab = createAsyncThunk(
    "lab/booking",
    async (bookingData, thunkAPI) => {
        try {
            // Accessing the token from the login slice in the state
            const token = thunkAPI.getState().login.token;
            // console.log("from booking slice", token);
            // Set the authorization header
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(bookingLab, bookingData, config);
            console.log("response of book lab", response);
            return response.data;
        } catch (error) {
            console.log("lab error;;;;;;", error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// book lab package
export const labPackageBook = createAsyncThunk(
    "lab/labPackageBook",
    async (labBookingData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().login.token;

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                labPackageBooking,
                labBookingData,
                config
            );
            console.log("lab booking response", response);
            return response.data;
        } catch (error) {
            console.log("lab package booking error", error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create the lab booking slice
const labBookingSlice = createSlice({
    name: "labBooking",
    initialState,
    reducers: {
        openProcessForm(state) {
            state.processForm = true;
            localStorage.setItem("processForm", state.processForm);
        },
        closeProcessForm(state) {
            state.processForm = false;
            localStorage.setItem("processForm", state.processForm);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(bookLab.pending, (state) => {
                state.status = "loading";
            })
            .addCase(bookLab.fulfilled, (state) => {
                state.status = "succeeded";
                toast.success("Lab booked successfully!");
            })
            .addCase(bookLab.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
                toast.error("Failed to book lab");
            })

            //lab package booking
            .addCase(labPackageBook.pending, (state) => {
                state.status = "loading";
            })
            .addCase(labPackageBook.fulfilled, (state) => {
                state.status = "succeeded";
                toast.success("Lab package booked successfully!");
            })
            .addCase(labPackageBook.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload.error;
                toast.error("Failed to book lab package");
            });
    },
});

export const { openProcessForm, closeProcessForm } = labBookingSlice.actions;

export default labBookingSlice.reducer;
