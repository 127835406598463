import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userAddress: {
        fullName: "",
        mobileNo: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        pinCode: "",
        city: "",
        state: "",
        country: "India",
    },
    labBookDetails: {
        atplace: "",
        date: "",
        finalPrice: 0,
        lab: "",
        labservice: "",
        paid: true,
        referenceId: "",
        time: "",
        transitionId: "",
        type: "",
    },
};

const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        setUserAddress: (state, action) => {
            state.userAddress = action.payload;
        },
        clearUserAddress: (state) => {
            state.userAddress = initialState.userAddress;
        },
        updateUserAddressField: (state, action) => {
            const { field, value } = action.payload;
            state.userAddress[field] = value;
        },
        setLabAddress: (state, action) => {
            state.labBookDetails = action.payload;
            localStorage.setItem("labBookDetails", JSON.stringify(state.labBookDetails));
        },
        clearLabAddress: (state) => {
            state.labBookDetails = initialState.labBookDetails;
            localStorage.removeItem("labBookDetails");
        },
        updateLabAddressField: (state, action) => {
            const { field, value } = action.payload;
            state.labBookDetails[field] = value;
        },
    },
});

export const {
    setUserAddress,
    clearUserAddress,
    updateUserAddressField,
    setLabAddress,
    clearLabAddress,
    updateLabAddressField,
} = addressSlice.actions;

export const selectUserAddress = (state) => state.address.userAddress;
export const selectLabAddress = (state) => state.address.labBookDetails;


export default addressSlice.reducer;
