import React, { useState } from "react";
import "../../styles/ProfileScreen/RatingReviewPopUp.css";
import { RxCross2 } from "react-icons/rx";
import { addReviewApi } from "../../Constents/apiAxios";
import axios from "axios";
import { toast } from "react-toastify";

function RatingReviewPopUp({ close, productId, productVariantId }) {
    const [loading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        comment: "",
        rating: "",
        productId: productId,
        productVariantId: productVariantId,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${addReviewApi}`, formData);
            if (response?.data?.status === "success") {
                toast.success("Review added!");
                setFormData({
                    comment: "",
                    rating: "",
                    productId: productId,
                    productVariantId: productVariantId,
                });
                close();     
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error("Server error");
            console.error("Failed to add review:", error.message);
        }
    };

    return (
        <>
            <div className="order-history-container">
                <div className="rating_popup">
                    <div className="nameorder">
                        <h1>Write a review here</h1>
                        <h5 onClick={close}>
                            <RxCross2 />
                        </h5>
                    </div>

                    <div className="rating_popup_content_box">
                        <div className="rating_popup_star_box">
                            <fieldset className="starability-slot">
                                <input
                                    type="radio"
                                    id="no-rate"
                                    className="input-no-rate"
                                    name="rating"
                                    value="0"
                                    checked={formData.rating === "0"}
                                    aria-label="No rating."
                                    onChange={handleChange}
                                />
                                <input
                                    type="radio"
                                    id="first-rate1"
                                    name="rating"
                                    value="1"
                                    checked={formData.rating === "1"}
                                    onChange={handleChange}
                                />
                                <label htmlFor="first-rate1" title="Terrible">
                                    1 star
                                </label>
                                <input
                                    type="radio"
                                    id="first-rate2"
                                    name="rating"
                                    value="2"
                                    checked={formData.rating === "2"}
                                    onChange={handleChange}
                                />
                                <label htmlFor="first-rate2" title="Not good">
                                    2 stars
                                </label>
                                <input
                                    type="radio"
                                    id="first-rate3"
                                    name="rating"
                                    value="3"
                                    checked={formData.rating === "3"}
                                    onChange={handleChange}
                                />
                                <label htmlFor="first-rate3" title="Average">
                                    3 stars
                                </label>
                                <input
                                    type="radio"
                                    id="first-rate4"
                                    name="rating"
                                    value="4"
                                    checked={formData.rating === "4"}
                                    onChange={handleChange}
                                />
                                <label htmlFor="first-rate4" title="Very good">
                                    4 stars
                                </label>
                                <input
                                    type="radio"
                                    id="first-rate5"
                                    name="rating"
                                    value="5"
                                    checked={formData.rating === "5"}
                                    onChange={handleChange}
                                />
                                <label htmlFor="first-rate5" title="Amazing">
                                    5 stars
                                </label>
                            </fieldset>
                        </div>

                        <div className="rating_popup_text_box">
                            <textarea
                                name="comment"
                                placeholder="Write your comment here.."
                                value={formData.comment}
                                onChange={handleChange}
                            ></textarea>

                            <button onClick={handleSubmit}>
                                {loading ? "Submitting.." : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RatingReviewPopUp;
