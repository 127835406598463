import React from "react";
import "../../styles/AddOrder/AddToWishList.css";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import ToggleButton from "../SingleProduct/ToggelButton";
import { CgShoppingCart } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import dlt from "../../Assets/Images/singleProduct/img/dlt.png";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../loader/Loader";

import {
  removeItemFromWishlist,
  wishlistItemDeleteAsync,
} from "../../store/newWishlist/newWishlist";
import { addItemToCart, addItemToCartAsync } from "../../store/newCart/newCart";
import { setCurrentSizePotency } from "../../store/productsData/productsData";

function AddToWishList() {
  const newWishlist = useSelector((state) => state?.newWishlist?.newWishlist);
  const loading = useSelector((state) => state?.newWishlist?.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

    // Generate star icons based on the rating
  const generateStarIcons = (rating) => {
    const starIcons = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        // Full star
        starIcons.push(<FaStar key={i} style={{ color: "#FFD700" }} />);
      } else if (i < rating) {
        // Partial star (use percentage to fill the star partially)
        starIcons.push(
          <FaStarHalfAlt key={i} style={{ color: "#FFD700" }} />
        );
      } else {
        // Empty star
        starIcons.push(<FaStar key={i} style={{ color: "#ccc" }} />);
      }
    }

    return starIcons;
  };

   // Handle deletion of item from wishlist
  const handleDeleteItem = (item) => {
    // console.log("wishlistId item id", wishlistId);
    if (token) {
      dispatch(wishlistItemDeleteAsync(item._id));
    } else {
      dispatch(removeItemFromWishlist(item.productVariantId));
    }
  };

  // handle add to cart from the wishlist
  const handleCartButton = async (item) => {
    if (token) {
      await dispatch(addItemToCartAsync(item));
    } else {
      dispatch(addItemToCart(item));
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="wish_list_container">
        <div className="wish_list_nav">
          <div>
            <p>My Wishlist ({newWishlist.length})</p>
          </div>
        </div>

        <div className="wishlist_content">
          <div className="wishlist_content_card">
            {newWishlist.map((item) => (
              <div key={item._id} className="wishlist_card">
                <div className="wishlist_left_div">
                  <div className="wishlist_img">
                    <img src={item?.imageObj?.url} alt="image_here" />
                  </div>
                  <div className="wishlist_text">
                    <h4
                      onClick={() => {
                        dispatch(
                          setCurrentSizePotency({
                            size: item?.size,
                            potency: item?.potency,
                          })
                        );
                        navigate(`/singleproduct/${item.productId}`);
                      }}
                    >
                      {item?.brand} {item?.productName} {item?.potency} {item?.size}
                    </h4>
                    <p>
                      {item?.categoryByDisease &&
                        item?.categoryByDisease
                          .filter((word) => !word.includes(","))
                          .join(", ")}
                    </p>
                    <div className="wishlist_rating">
                      <span>{generateStarIcons(item?.averageRating)}</span>
                      <span>({item?.noOfRating}) rating</span>
                    </div>
                    <div className="wishlist_potency">
                      <p>Select From available Potency</p>
                      <div className="wishlist_item">
                        <div className="wishlist_item_more">
                          <span>{item?.size}</span>
                          <div className="potency-btn">
                            <p>{item?.potency}</p>
                          </div>
                        </div>

                        <div className="wishlist_discount">
                          <span>
                            <MdOutlineCurrencyRupee /> {item?.mrp}
                          </span>
                          <span className="discount">{item?.discount} %</span>
                          <span>
                            <MdOutlineCurrencyRupee /> {item?.sellingPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wishlist_btn">
                  <div
                    className="wishlist_dlt_btn"
                    onClick={() => handleDeleteItem(item)}
                  >
                    <p>
                      <img src={dlt} alt="" />
                    </p>
                  </div>
                  <div className="wishlist_cart_btn">
                    <ToggleButton
                      textContent="Add to cart"
                      icon={<CgShoppingCart />}
                      onClick={() => handleCartButton(item)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddToWishList;
