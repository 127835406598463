import React, { useEffect, useState } from "react";
import "../../styles/Home/MedicineSection.css";
import { IoHeartOutline, IoHeart } from "react-icons/io5";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getMostSellingProducts, setCurrentSizePotency } from "../../store/productsData/productsData";
import {
  addItemToWishlist,
  addItemToWishlistAsync,
  removeItemFromWishlist,
  wishlistItemDeleteAsync,
} from "../../store/newWishlist/newWishlist";
import { addItemToCart, addItemToCartAsync } from "../../store/newCart/newCart";
import { toast } from "react-toastify";

const MedicineSection = () => {
  const newWishlist = useSelector((state) => state?.newWishlist?.newWishlist);
  const mostSellingProducts = useSelector(getMostSellingProducts);

  const [filteredSelling, setFilteredSelling] = useState();
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const CustomPrevButton = ({ onClick }) => (
    <button className="prevButton prevbrand" onClick={onClick}>
      <IoIosArrowRoundBack className="prevIcon" />
    </button>
  );

  const CustomNextButton = ({ onClick }) => (
    <button className="nextButton nextbrand" onClick={onClick}>
      <IoIosArrowRoundForward className="prevIcon" />
    </button>
  );

  useEffect(() => {
    // Check if a product is in the wishlist
    const updatedMostSellingProducts = mostSellingProducts.map((product) => {
      const isInWishlist = newWishlist.some(
        (wishlistItem) =>
          wishlistItem.productVariantId === product.productVariantId
      );
      return { ...product, isInWishlist };
    });

    setFilteredSelling(updatedMostSellingProducts);
  }, [mostSellingProducts, newWishlist]);

  // handle wishlist
  const handleWishlist = async (item) => {
    if (token) {
      if (item.isInWishlist) {
        const wishlistItem = newWishlist.find(
          (wishlistItem) =>
            wishlistItem.productVariantId === item.productVariantId
        );
        if (wishlistItem) {
          const wishlistId = wishlistItem._id; // Retrieve the wishlistId
          // Dispatch the action to delete the item from the wishlist
          await dispatch(wishlistItemDeleteAsync(wishlistId));
        }
      } else {
        await dispatch(addItemToWishlistAsync(item));
      }
    } else {
      if (item.isInWishlist) {
        dispatch(removeItemFromWishlist(item.productVariantId));
      } else {
        dispatch(addItemToWishlist(item));
      }
    }
  };

  // handle medicine  button
  const handleCartButton = async (item) => {
    if (token) {
      if (item?.inventory >= 1) {
        await dispatch(addItemToCartAsync(item));

      } else {
        toast.error("this product is out of stock currently")
      }
    } else {
      if (item?.inventory >= 1) {
        dispatch(addItemToCart(item));
      } else {
        toast.error("this product is out of stock currently")
      }
    }
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1200,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          speed: 1000,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="medicine-section">
      <div className="medicine-section-top">

        <h1>
          <span className="secondaryTextColor">Balance health naturally</span>
          <span className="primaryTextColor">
            {" "}
            with our Homeopathy Medicines,
          </span>
        </h1>

      </div>
      <div className="medicine-section-middle">
        <h1 className="primaryTextColor">Start your wellness journey.</h1>
      </div>
      <div className="mobile-section-bottom">
        <Slider {...settings1}>
          {filteredSelling?.map((medicine, index) => (
            <div className="medicine-section-bottom-box" key={index}>
              <div className="innerProductBox">
                <div className="discountCont">{medicine?.discount}% OFF</div>
                <div className="commonCard-wishlistIconsHeart-positioning">
                  {medicine?.isInWishlist ? (
                    <IoHeart
                      className="wishlistIconsHeart"
                      onClick={() => handleWishlist(medicine)}
                    />
                  ) : (
                    <IoHeartOutline
                      className="wishlistIconsHeartOutline"
                      onClick={() => handleWishlist(medicine)}
                    />
                  )}
                </div>
                <div className="Medical-section-img-content-button">
                  <div className="innerProductBox-img-slider">
                    <img src={medicine?.imageObj.url} alt="" />
                  </div>
                  <div className="medicine-section-content-mrp-discount">
                    <div className="medicine-commonCard-productName">
                      <p
                        onClick={() => {
                          dispatch(setCurrentSizePotency({ size: medicine?.size, potency: medicine?.potency }))
                          navigate(
                            `/singleproduct/${medicine.productId}`
                          );

                        }}
                      >
                        {capitalizeFirstLetter(
                          `${medicine?.brand} ${medicine?.productName}
                      ${medicine?.potency === "N/A" ? "" : medicine?.potency} (${medicine?.size})`
                        )}
                      </p>
                    </div>
                    <div className="medicine-commonCard-sellingPrice">
                      <h4>&#x20B9;{medicine?.sellingPrice}</h4>
                    </div>
                    <div className="mrpCut">
                      MRP{" "}
                      <span className="needtowarp">
                        {" "}
                        &#x20B9;{medicine?.mrp}
                      </span>{" "}
                    </div>
                  </div>

                  <button
                    className="actionButtons"
                    onClick={() => {
                      handleCartButton(medicine);
                    }}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default MedicineSection;
