import React, { useState, useEffect } from "react";
import "../../styles/ProfileScreen/AddressUpdateModal.css";
import { useDispatch, useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import {
  closeModalAddress,
  updateUserAddress,
  userAddressData,
  userAddressModal,
} from "../../store/signupSlice/userAddress";
import { toast } from "react-toastify";

const AddressUpdate = () => {
  const dispatch = useDispatch();
  const ismodal = useSelector(userAddressModal);
  const addressData = useSelector(userAddressData);

  const [formData, setFormData] = useState({
    fullName: "",
    mobileNo: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    pinCode: "",
    city: "",
    state: "",
    country: "",
  });

  useEffect(() => {
    if (addressData) {
      setFormData({
        fullName: addressData.fullName || "",
        mobileNo: addressData.mobileNo || "",
        email: addressData.email || "",
        addressLine1: addressData.addressLine1 || "",
        addressLine2: addressData.addressLine2 || "",
        pinCode: addressData.pinCode || "",
        city: addressData.city || "",
        state: addressData.state || "",
        country: addressData.country || "",
        _id: addressData._id,
      });
    }
  }, [addressData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    const capitalizedValue = value.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );
    if (
      id === "fullName" ||
      id === "addressLine1" ||
      id === "addressLine2" ||
      id === "city" ||
      id === "state"
    ) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: capitalizedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  const handleModalClose = () => {
    dispatch(closeModalAddress());
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/; // Change here: limit to Gmail and Yahoo
  const phoneNumberRegex = /^\+?\d{10}$/;

  const handleSave = async () => {
    if (!emailRegex.test(formData.email)) {
      toast.error("Email must be a valid Gmail or Yahoo address");
      return;
    }

    if (!phoneNumberRegex.test(formData.mobileNo)) {
      toast.error("Invalid phone number format");
      return;
    }

    try {
      const resultAction = await dispatch(updateUserAddress(formData));
      if (updateUserAddress.fulfilled.match(resultAction)) {
        toast.success("Address updated successfully!");
        dispatch(closeModalAddress());
      } else {
        toast.error("Failed to update address!");
      }
    } catch (error) {
      toast.error("An error occurred while updating address!");
    }
  };

  return (
    <div className="addressForm">
      {ismodal && (
        <div className="addressUpdateModal">
          <div className="containerUpdateAddress">
            <div className="cutEditIconDiv">
              <RxCross2 className="crossIconsEdit" onClick={handleModalClose} />
            </div>

            <div className="NameDiv xyzq">
              <label htmlFor="fullName">Your Full Name</label>
              <input
                type="text"
                id="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="Enter Your Full Name"
              />
            </div>
            <div className="mobileEmailDiv">
              <div className="mobileEdit xyzq">
                <label htmlFor="mobileNo">Mobile No.</label>
                <input
                  type="number"
                  id="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleInputChange}
                  placeholder="Enter Mobile No."
                />
              </div>
              <div className="emailEdit xyzq">
                <label htmlFor="email">Email Id.</label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email Id."
                />
              </div>
            </div>
            <div className="AddressDiv1 xyzq">
              <label htmlFor="addressLine1">Address Line 1</label>
              <input
                type="text"
                id="addressLine1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                placeholder="Enter Your House No./Flate No./Building No."
              />
            </div>
            <div className="AddressDiv2 xyzq">
              <label htmlFor="addressLine2">Address Line 2</label>
              <input
                type="text"
                id="addressLine2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                placeholder="Enter Your House No./Flate No./Building No."
              />
            </div>
            <div className="pinCityDiv">
              <div className="pinDiv xyzq">
                <label htmlFor="pinCode">Pin Code</label>
                <input
                  type="number"
                  id="pinCode"
                  value={formData.pinCode}
                  onChange={handleInputChange}
                  placeholder="Enter Pin Code"
                />
              </div>
              <div className="cityDiv xyzq">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="Enter City"
                />
              </div>
            </div>
            <div className="stateCountryDiv">
              <div className="stateDiv xyzq">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  placeholder="Enter State"
                />
              </div>
              <div className="countryDiv xyzq">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  id="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  placeholder="Enter Country"
                />
              </div>
            </div>
            <button className="saveBtnEdit" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressUpdate;
