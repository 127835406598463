import React, {useMemo, useState } from "react";
import "../../../styles/AllProducts/FilterProduct/Medicine.css";
import FilterPopUp from "../FilterPopUp";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesByBrand } from "../../../store/categories/categories";
import { addBrand, removeBrand, selectBrands } from "../../../store/filterSlice/filterSlice";

function Medicine({
    handleClick,
}) {
    const dispatch = useDispatch()
    const selectedBrands = useSelector(selectBrands);
    const brandCats = useSelector(getCategoriesByBrand);
    //for pop-up
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleBrandSelection = (brand) => {
        if (selectedBrands.includes(brand)) {
            // Brand is already selected, so remove it
            dispatch(removeBrand(brand));
        } else {
            // Brand is not selected, so add it
            dispatch(addBrand(brand));
        }
    };

    // selected brand will show on the top 
    const sortedBrands = useMemo(() => {
        const categoryByMedicine = brandCats;
        if (!Array.isArray(categoryByMedicine)) {
            return [];
        }
        const selectedBrandsList = categoryByMedicine.filter((brand) =>
            selectedBrands.includes(brand?.categoryName)
        );
        const unselectedBrandsList = categoryByMedicine.filter(
            (brand) => !selectedBrands.includes(brand?.categoryName)
        );

        return [...selectedBrandsList, ...unselectedBrandsList];
    }, [brandCats, selectedBrands]);


    //show four data initially
    const showData = sortedBrands?.slice(0, 4);

    return (
        <div className="sidebar_medicine">
            <div className="sidebar_medicine_heading">
             
                    <h4
                        onClick={() => handleClick("medicine")}
                        className="medicine"
                    >
                        Brand
                    </h4>
            </div>
  
                <div className="sidebar_medicine_text">
                    {showData?.map((brand) => {
                        const brandString = String(brand?.categoryName);
                        return (
                            <div key={brandString}>
                                <input
                                    type="checkbox"
                                    id={`brand-${brand?.categoryName}`}
                                    checked={selectedBrands.includes(
                                        brand?.categoryName
                                    )}
                                    onChange={() =>
                                        toggleBrandSelection(
                                            brand?.categoryName
                                        )
                                    }
                                />
                                <label
                                    htmlFor={`brand-${brand?.categoryName}`}
                                    className="lablefilter"
                                >
                                    {brand?.categoryName}
                                </label>
                            </div>
                        );
                    })}
                </div>
           
             <button onClick={openModal}>view more</button>

            {isModalOpen && (
                <FilterPopUp
                    isOpen={isModalOpen}
                    allBrand={sortedBrands}
                    closeModal={closeModal}
                    toggleBrandSelection={toggleBrandSelection}
                    selectedBrands={selectedBrands}
                />
            )}
        </div>
    );
}

export default Medicine;
