import { useState } from "react";
import "../../styles/layout/Footer.css";
import { useNavigate } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import logo from "../../Assets/Images/logo/foot.png";
import { useDispatch } from "react-redux";

import {
  setFilter,
  setLoadingQuery,
  setSearchQuery,
} from "../../store/SearchQuery/SearchQuerySlice";
import { selectCategory } from "../../store/categorySlice/CategorySlice";
import { toast } from "react-toastify";
import { subscribeApi } from "../../Constents/apiAxios";
import { setCurrentCategory } from "../../store/productsData/productsData";

function Footer() {
  const [email, setEmail] = useState("");
  const [textchange, setTextchange] = useState("More +");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const postEmaildata = () => {
    // Make a POST request using the fetch API
    fetch(subscribeApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (email === "") {
          return;
        } else {
          toast.success("Subscribe successful");
          document.getElementById("email").value = "";
        }
      })
      .catch((error) => {
        toast.error("Something went wrong !");
      });
  };

  const emailsubmit = () => {
    if (email === "") {
      toast.error("Please Enter email");
    }
    postEmaildata();
  };

  /* footer more data show */
  const Footershow = () => {
    const Hide = document.getElementById("re-hide");
    if (Hide.style.display === "none") {
      Hide.style.display = "block";
      setTextchange("Less -");
    } else {
      Hide.style.display = "none";
      setTextchange("More +");
    }
  };

  const handleGmailRedirect = (event) => {
    event.preventDefault();
    const gmailAddress = "contactus@sahashomeopharmacy.com";
    const subject = "Sent";
    window.location.href = `mailto:${gmailAddress}?subject=${subject}`;
  };

  //update this function
  const handleContactNumberRedirect = (event) => {
    event.preventDefault();
    window.location.href = "tel:+917817822216";
  };

  const handleWebreateRedirect = () => {
    window.open("https://www.webreate.com", "_blank");
  };

  return (
    <div className="footerMain">
      <div className="footer">
        <div className="footerSections">
          <div className="footerSection1">
            <div className="footerSection1_first_div">
              <img src={logo} alt="" />
            </div>
            <p>
              In 1996, Dr. NC Pandey embarked on a visionary journey to empower
              his community in Kumaon to combat diseases through the gentle yet
              powerful principles of homeopathy.
            </p>
            <div className="footer-email">
              <h2>Subscribe to our Newsletter</h2>

              <div className="footer-email-div">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email id"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button onClick={() => emailsubmit()}>Subscribe Now</button>
              </div>

              {/* mobile view buttons */}
              <div className="res-view">
                <div className="button-res">
                  <BiSolidPhoneCall
                    className="icon-res"
                    title="+917817822216"
                    onClick={handleContactNumberRedirect}
                  />
                </div>
                <div className="button-res">
                  <a
                    className="a-res"
                    href="mailto:contactus@sahashomeopharmacy.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdEmail
                      className="icon-res"
                      title="contactus@sahashomeopharmacy.com"
                      onClick={handleGmailRedirect}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right-sec">
            <div className="footerSection2 ">
              <ul>
                <li>
                  <h3 className="footerSection2-Quick-Links">Quick Links </h3>
                </li>
                <li>
                  <p onClick={() => navigate("/")}>Home</p>
                </li>
                <li>
                  <p onClick={() => navigate("/gallery")}>Gallery</p>
                </li>
                <li>
                  <p
                    onClick={() => {
                      dispatch(setCurrentCategory("normalProduct"))
                      dispatch(setSearchQuery(""));
                      dispatch(selectCategory("All Products"));
                      dispatch(setLoadingQuery(true));
                      dispatch(setFilter(false));
                      navigate("/allproducts");
                    }}
                  >
                    All Products
                  </p>
                </li>
                <li>
                  <p onClick={() => navigate("/productcategory")}>
                    Products by Category
                  </p>
                </li>
                <li>
                  <p onClick={() => navigate("/profilescreen")}>
                    Track Your Order
                  </p>
                </li>
                <li>
                  <p onClick={() => navigate("/medicaltest")}>Labs</p>
                </li>
              </ul>
            </div>

            <div className="footerSection2 " id="re-hide">
              <ul>
                <li>
                  <h3 className="footerSection2-Quick-Links footerSection2-Charlie">
                    Charlie
                  </h3>
                </li>
                <li>
                  <p onClick={() => navigate("/blog")}>Blogs</p>
                </li>
                <li>
                  <p onClick={() => navigate("/aboutus")}>About us</p>
                </li>
                <li>
                  <p onClick={() => navigate("/privacypolicy")}>
                    Privacy Policy
                  </p>
                </li>
                <li>
                  <p onClick={() => navigate("/refundPolicy")}>Refund Policy</p>
                </li>
                <li>
                  <p onClick={() => navigate("/termsandcondition")}>
                    Terms & Condition
                  </p>
                </li>
                <li>
                  <p onClick={() => navigate("/contactus")}>Contact us</p>
                </li>
              </ul>
            </div>

            <div className="footerSection2 res-mobile">
              <ul>
                <li>
                  <h3
                    className="footerSection2-contactus-heading"
                    onClick={() => navigate("/contactus")}
                  >
                    Contact Us
                  </h3>
                </li>
                <li onClick={handleContactNumberRedirect}>
                  <p>+917817822216</p>
                </li>
                <li>
                  <p onClick={handleGmailRedirect}>
                    contactus@sahashomeopharmacy.com
                  </p>
                </li>
              </ul>
            </div>

            <p className="more" onClick={(e) => Footershow(e)}>
              {textchange}
            </p>
          </div>
        </div>
      </div>
        <small className="copyright">&#169; all right reserved SahasHomeo developed by <span onClick={handleWebreateRedirect}> &nbsp; @Webreate</span></small>
    </div>
  );
}

export default Footer;
