import React, { useEffect, useRef, useState } from "react";
import "../../styles/AllProducts/AllProduct.css";
import { useDispatch, useSelector } from "react-redux";
import { selectedCategorySelector } from "../../store/categorySlice/CategorySlice";
import CommonCard from "../layouts/CommonCard";
import { CiFilter } from "react-icons/ci";
import SideBar from "./FilterProduct/SideBar";
import { saveAllProducts } from "../../store/categorySlice/CategorySlice";
import {
  clearSearchQuery,
} from "../../store/SearchQuery/SearchQuerySlice";

import {
  getAllProducts,
  getCurrentCategory,
  getCurrentPage,
  getNextPageLoading,
  setCurrentPage,
} from "../../store/productsData/productsData";

function AllProduct() {
  const allProductData = useSelector(getAllProducts);

  const nextpageloading = useSelector(getNextPageLoading);
  const currentPage = useSelector(getCurrentPage);
  const currentCategory = useSelector(getCurrentCategory);
  const isMobileView = window.innerWidth < 768;
  const newWishlist = useSelector((state) => state.newWishlist.newWishlist);

  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectedCategorySelector);

  const [showFilterList, setShowFilterList] = useState(false);
  const [dataToRender, setDataToRender] = useState();

  // checking the product is in wishlist or not
  useEffect(() => {

    const filterProduct = allProductData.filter(product => product?.potency !== "N/A")

    // Check if a product is in the wishlist
    const updatedProducts = filterProduct?.map((product) => {
      const isInWishlist = newWishlist.some(
        (wishlistItem) =>
          wishlistItem.productVariantId === product.productVariantId
      );
      return { ...product, isInWishlist };
    });

    setDataToRender(updatedProducts);
  }, [allProductData, newWishlist]);

  const filterRef = useRef(null);


  // for filter icon
  const toggleFilterIcon = () => {
    setShowFilterList((prevShowFilterList) => !prevShowFilterList);
  };

  // click outside filter will close
  const handleClickOutside = (event) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target) &&
      !event.target.closest(".filterIcon") // Exclude clicks on the filter icon
    ) {
      // If click occurs outside the filter and not on the filter icon, close it
      setShowFilterList(false);
    }
  };


  // for removing filter from screen
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilterList]);

  useEffect(() => {
    if (showFilterList && isMobileView) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showFilterList, isMobileView]);

  useEffect(() => {
    cleanup();
  }, []);

  const cleanup = () => {
    clearSearchQuery();
  };

  useEffect(() => {
    dispatch(saveAllProducts(allProductData));
  }, [dispatch, allProductData]);



  return (
    <section className="allProductContainer">
      <div className="allProductHeading">

        <h2>{selectedCategory || "All Products"}</h2>

      </div>

      <div className="filterIcon" onClick={toggleFilterIcon}>
        <CiFilter className="filterLogo" />
      </div>
      {showFilterList && (
        <div className="filterList" ref={filterRef}>
          <SideBar toggleFilterIcon={toggleFilterIcon} />
        </div>
      )}

      <div className="allProductItem">
        {dataToRender?.map((product, index) => (
          <CommonCard medicine={product} index={index} key={index} />
        ))}
      </div>

      {nextpageloading && (
        <div style={{ margin: "1.5rem 0 0 0" }}>loading...</div>
      )}
      {currentCategory === "normalProduct" && (
        <button
          className="allProductBtn"
          onClick={() => {
            dispatch(setCurrentPage(currentPage + 1));
          }}
        >
          View More
        </button>
      )}
    </section>
  );
}

export default AllProduct;
