import React, { useState } from 'react';
import "../../styles/LoginSignup/Signup.css";
import { setForgotModal, setLoginModal, setSignupModal } from '../../store/signupSlice/signupSlice';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { forgotPasswordApi, sendotpforforgotPassword } from '../../Constents/apiAxios';
import { TbLoader3 } from 'react-icons/tb';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [passLoading, setPassLoading] = useState(false)
    const [step, setStep] = useState(1); // Step 1: Enter phone, Step 2: Enter OTP, Step 3: Enter new password
    const [formData, setFormData] = useState({
        phone: "",
        otp: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [otpResponse, setOtpResponse] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const passVisible = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validatePhoneOrEmail = (input) => {
        const phoneNumberRegex = /^\d{10}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.(com|com\.br|co\.in|co\.uk)$/i;
        return phoneNumberRegex.test(input) || emailRegex.test(input);
    };

    const handlePhoneSubmit = async (e) => {
        e.preventDefault();
        if (!validatePhoneOrEmail(formData.phone)) {
            toast.error("Please enter a valid phone number or email.");
            return;
        }

        // Send OTP to the phone number
        try {
            setPassLoading(true)
            const response = await axios.post(sendotpforforgotPassword, { phone: formData.phone });
            if (response.data.status === "success") {
                toast.success(`${response.data.message}`);
                setOtpResponse(response.data.otp);
                setStep(2);
                setPassLoading(false)
            } else {
                console.log(response)
                toast.error(`${response.data.message}`);
                setPassLoading(false)
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Failed to send OTP. Please try again.";
            toast.error(errorMessage);
            setPassLoading(false)
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        try {
            setPassLoading(true)
            const isValidOtp = Number(formData.otp) === Number(otpResponse);
            if (isValidOtp) {
                toast.success("OTP verified successfully.");
                setStep(3);
                setPassLoading(false)
            } else {
                toast.error("Invalid OTP. Please try again.");
                setPassLoading(false)
            }
        } catch (error) {
            toast.error("Failed to verify OTP. Please try again.");
            setPassLoading(false)
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            toast.error("Passwords do not match. Please try again.");
            return;
        }

        try {
            setPassLoading(true)
            const response = await axios.post(forgotPasswordApi, { phone: formData.phone, newPassword: formData.newPassword });
            if (response.data.status === "success") {
                toast.success(`${response.data.message}`);
                dispatch(setForgotModal(false));
                dispatch(setLoginModal(true));
                setPassLoading(false)
            } else {
                toast.error(`${response.data.message}`);
                setPassLoading(false)
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Failed to update password. Please try again."
            toast.error(errorMessage);
            setPassLoading(false)
        }
    };

    return (
        <div className="signupSection">
            <div className="signupContainer">
                <h1>
                    Welcome
                </h1>

                <form onSubmit={step === 1 ? handlePhoneSubmit : step === 2 ? handleOtpSubmit : handlePasswordSubmit} className="formContainer">
                    {step === 1 && (
                        <>
                            <div className="signupWithEmail">
                                <div className="signupBorders"></div>
                                <div>Enter your Phone Number or Email</div>
                                <div className="signupBorders"></div>
                            </div>
                            <div className="formInputs">
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Enter Phone Number or Email"
                                    required
                                />
                                <button type="submit">{!passLoading ? "Send OTP" : <TbLoader3 className="spinner" />}</button>
                            </div>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <div className="signupWithEmail">
                                <div className="signupBorders"></div>
                                <div>Enter the OTP</div>
                                <div className="signupBorders"></div>
                            </div>
                            <div className="formInputs">
                                <input
                                    type="text"
                                    name="otp"
                                    value={formData.otp}
                                    onChange={handleChange}
                                    placeholder="Enter OTP"
                                    required
                                />
                                <button type="submit">{!passLoading ? "Verify OTP" : <TbLoader3 className="spinner" />}</button>
                            </div>
                        </>
                    )}

                    {step === 3 && (
                        <>
                            <div className="signupWithEmail">
                                <div className="signupBorders"></div>
                                <div>Reset New Password</div>
                                <div className="signupBorders"></div>
                            </div>
                            <div className="formInputs">
                                <div className="inputPassSec">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="newPassword"
                                        value={formData.newPassword}
                                        onChange={handleChange}
                                        placeholder="Enter New Password"
                                        required
                                    />
                                    <span onClick={passVisible}>
                                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                    </span>
                                </div>
                                <div className="inputPassSec">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        placeholder="Confirm New Password"
                                        required
                                    />
                                    <span onClick={passVisible}>
                                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                    </span>
                                </div>
                                <button type="submit">{!passLoading ? "Reset Password" : <TbLoader3 className="spinner" />}</button>
                            </div>
                        </>
                    )}
                    <p>
                        Don't have an account?{" "}
                        <span onClick={() => {
                            dispatch(setLoginModal(false));
                            dispatch(setSignupModal(true));
                        }}>Sign-up</span>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
