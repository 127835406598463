import React, { useState } from "react";
import "../../styles/Home/Categories.css";
import img from "../../Assets/Images/Frame.png";
import arrow1 from "../../Assets/Icons/clarity_arrow-line.svg";
import arrow2 from "../../Assets/Icons/clarity_arrow-line (1).svg";
import { Bounce } from "react-awesome-reveal";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCategory } from "../../store/categorySlice/CategorySlice";
import { getMainCategory } from "../../store/categories/categories";
import { fetchAllProductDataByCategory } from "../../store/productsData/productsData";

const Categories = () => {
  const categoryData = useSelector(getMainCategory);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemsPerPage = 6;
  const [startIdx, setStartIdx] = useState(0);

    // Function to handle scrolling of categories
  const handleScroll = (direction) => {
    const newStartIdx =
      direction === "up" ? startIdx - itemsPerPage : startIdx + itemsPerPage;
    setStartIdx(
      Math.max(0, Math.min(newStartIdx, categoryData.length - itemsPerPage))
    );
  };

  // Function to handle category selection
  const handleCategory = (item) => {
    const capitalizedCategoryName = capitalizeFirstLetter(item.categoryName);
    const categoryData = `${capitalizedCategoryName}`;
    dispatch(selectCategory(categoryData));
    navigate("/allproducts");
    dispatch(fetchAllProductDataByCategory(categoryData))
  };

 // Utility function to capitalize the first letter of each word in a string
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className=" home-categories">
      <div className="home-page-heading">
        <Bounce>
          <h1 className="home-page-heading-text">
            Homeopathy <br /> Categories
          </h1>
        </Bounce>
      </div>
      <div className="home-categories-content">
        <div className="home-categories-left">
          <img src={img} alt="" />
        </div>
        <div className="home-categories-right">
          {categoryData
            .slice(startIdx, startIdx + itemsPerPage)
            .map((category, index) => (
              <div key={index} className="home-categories-right-content">
                <img
                  src={category.imageObj.url}
                  alt=""
                  onClick={() => handleCategory(category)}
                />
                <h3>{capitalizeFirstLetter(category.categoryName)}</h3>
                <p>{category.message}</p>
              </div>
            ))}
        </div>
        <div className="vertical-slider-buttons">
          <button onClick={() => handleScroll("up")}>
            <img src={arrow1} alt="arrow" />{" "}
          </button>
          
          <button onClick={() => handleScroll("down")}>
            <img src={arrow2} alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
