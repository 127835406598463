import React, { useEffect, useState, useCallback } from "react";
import "../../styles/AddOrder/AddToCart.css";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import img3 from "../../Assets/Images/singleProduct/img/third.png";
import image from "../../Assets/Images/singleProduct/img/dlt.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import {
  cartItemDeleteAsync,
  decreaseItemQuantity,
  increaseItemQuantity,
  removeItemFromCart,
  updateCartItemQuantityAsync,
} from "../../store/newCart/newCart";
import {
  addItemToBuyNow,
  clearNewBuyNow,
} from "../../store/newBuyNow/newBuyNow";
import { setLoginModal } from "../../store/signupSlice/signupSlice";
import { setCurrentSizePotency } from "../../store/productsData/productsData";
import { toast } from "react-toastify";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

function AddToCart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newCart = useSelector((state) => state.newCart.newCart);
  const loading = useSelector((state) => state.newCart.loading);
  const token = localStorage.getItem("authToken");

  // Handle item deletion from the cart
  const handleDeleteItem = (item) => {
    if (token) {
      if (item._id) {
        dispatch(cartItemDeleteAsync(item._id));
      }
    } else {
      dispatch(removeItemFromCart(item.productVariantId));
    }
  };

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pricingDetails, setPricingDetails] = useState({
    totalMRP: 0,
    totalSellingPrice: 0,
    totalDiscount: 0,
    shippingPrice: 0,
    totalPrice: 0,
  });

  // Memoize the calculateCartTotals function
  const calculateCartTotals = useCallback((cartItems) => {
    let totalMRP = 0;
    let totalSellingPrice = 0;
    let totalDiscount = 0;
    let shippingPrice = 0; // Default shipping price is 0

    // Iterate through cart items
    cartItems.forEach((item) => {
      totalMRP += item.mrp * item.quantity;
      totalSellingPrice += item.sellingPrice * item.quantity;
      // Calculate total discount for each item
      totalDiscount += (item.mrp - item.sellingPrice) * item.quantity;
    });

    // Update shipping price if totalSellingPrice exceeds 500
    if (totalSellingPrice > 500 || newCart.length === 0) {
      shippingPrice = 0;
    } else {
      shippingPrice = 100; // Default shipping price is 50
    }

    // Calculate totalPrice including shipping
    let totalPrice = totalSellingPrice + shippingPrice;

    // Update state with calculated pricing details
    setPricingDetails({
      totalMRP: totalMRP.toFixed(2),
      totalSellingPrice: totalSellingPrice.toFixed(2),
      totalDiscount: totalDiscount.toFixed(2),
      shippingPrice: shippingPrice.toFixed(2),
      totalPrice: totalPrice.toFixed(2),
    });
  }, [newCart.length]);

  useEffect(() => {
    // Calculate cart totals whenever the cart items change
    calculateCartTotals(newCart);
  }, [newCart, calculateCartTotals]);

  // handle process button to send the item in the buy now page
  const handleProcess = () => {
    if (newCart.length === 0) {
      toast.error("Cart is empty. Please add some products to the cart.");
      return;
    }
    if (token) {
      dispatch(clearNewBuyNow());
      for (const itemToAddBuy of newCart) {
        dispatch(addItemToBuyNow(itemToAddBuy));
        navigate("/buynow");
      }
      navigate("/buynow");

    } else {
      localStorage.setItem("redirectUrl", window.location.pathname);
      dispatch(setLoginModal(true));
    }
  };

  // handle increase count of item button
  const handleIncrease = (item) => {
    if (token) {
      if (item.quantity < item.inventory) {
        dispatch(
          updateCartItemQuantityAsync({
            cartItemId: item._id,
            action: "increase",
          })
        );
      } else {
        toast.error('Cannot increase quantity. Only ' + item.inventory + ' items available in stock.');
      }
    } else {
      dispatch(increaseItemQuantity(item.productVariantId));
    }
  };

  // handle decrease count of item button
  const handleDecrease = (item) => {
    if (token) {
      if (item.quantity > 1) {
        dispatch(
          updateCartItemQuantityAsync({
            cartItemId: item._id,
            action: "decrease",
          })
        );
      }
    } else {
      dispatch(decreaseItemQuantity(item.productVariantId));
    }
  };

  // calculate average star show on screen
  const generateStarIcons = (rating) => {
    const starIcons = [];

    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        // Full star
        starIcons.push(<FaStar key={i} style={{ color: "#FFD700" }} />);
      } else if (i < rating) {
        // Partial star (use percentage to fill the star partially)
        starIcons.push(
          <FaStarHalfAlt key={i} style={{ color: "#FFD700" }} />
        );
      } else {
        // Empty star
        starIcons.push(<FaStar key={i} style={{ color: "#ccc" }} />);
      }
    }

    return starIcons;
  };

  return (
    <>
      {loading && <Loader />}
      <div className="addcart_container">
        <div className="addcart_text">
          <p>Total {newCart?.length} Product</p>
        </div>
        <div className="addcart_content">
          <div className="addcart_left_div">
            {newCart.map((item, index) => (
              <div key={index} className="addcart_card">
                <div className="wishlist_img">
                  <img src={item?.imageObj?.url} alt="" />
                </div>

                <div className="wishlist_text">
                  <h4
                    onClick={() => {
                      dispatch(
                        setCurrentSizePotency({
                          size: item?.size,
                          potency: item?.potency,
                        })
                      );
                      navigate(`/singleproduct/${item.productId}`);
                    }}
                  >
                    {item?.brand} {item?.productName} {item?.potency} {item?.size}
                  </h4>
                  <p>
                    {item?.categoryByDisease &&
                      item?.categoryByDisease
                        .filter((word) => !word.includes(","))
                        .join(", ")}
                  </p>
                  <div className="wishlist_rating">
                    <span>{generateStarIcons(item?.averageRating)}</span>
                    <span>({item?.noOfRating}) rating</span>
                  </div>

                  <div className="wishlist_potency">
                    <div className="wishlist_item">
                      <div className="wishlist_item_more">
                        <span>{item?.size}</span>
                        <div className="potency-btn">
                          <p>{item?.potency}</p>
                        </div>
                        <div className="my_order_qut">
                          <p>Qty-{item?.quantity}</p>
                          <div className="my_order_qut_btn">
                            <span onClick={() => handleIncrease(item)}>+</span>
                            <span>{item?.quantity}</span>
                            <span onClick={() => handleDecrease(item)}>-</span>
                          </div>
                        </div>
                      </div>

                      <div className="wishlist_discount">
                        <span>
                          <MdOutlineCurrencyRupee /> {item?.mrp}
                        </span>
                        <span className="discount">{item?.discount} %</span>
                        <span>
                          <MdOutlineCurrencyRupee /> {item?.sellingPrice}
                        </span>
                      </div>

                      <div className="addcart_card_text">
                        {item?.inventory < 10 && (
                          <p>Only {item?.inventory} left at this price</p>
                        )}
                        <p>medicines will be delivered in 5-7 days</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="addcart_dlt_btn"
                    onClick={() => handleDeleteItem(item)}
                  >
                    <p>
                      <img src={image} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="addcart_right_div">
            <div className="add_right_third_div">
              <h4>Payment Details</h4>

              <div className="add_right_div_text">
                <div>
                  <span>MRP Total</span>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />
                    {pricingDetails?.totalMRP}
                  </span>
                </div>

                <div>
                  <span>Discount</span>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />
                    {pricingDetails?.totalDiscount}
                  </span>
                </div>

                <div>
                  <span>Total Amount</span>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />
                    {pricingDetails?.totalSellingPrice}
                  </span>
                </div>

                <div>
                  <span>Shipping Charges</span>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />{" "}
                    {newCart?.length > 0 ? pricingDetails?.shippingPrice : 0}
                  </span>
                </div>

                <div>
                  <span>Total Payable</span>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />
                    {pricingDetails?.totalPrice}
                  </span>
                </div>

                <div>
                  <span>Total Saving</span>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />
                    {pricingDetails?.totalDiscount}
                  </span>
                </div>
                <div className="add_right_forth_div">
              <div>
                <img src={img3} alt="" />{" "}
              </div>

              <div className="delivery_charge_content">
                <span>Delivery Charges On This Order</span>
                <span> Free Delivery on Orders above ₹500</span>
              </div>
            </div>
                <div>
                  <span>
                    {" "}
                    <MdOutlineCurrencyRupee />
                    {pricingDetails?.totalPrice}
                  </span>
                  
                  <button onClick={handleProcess}>Process</button>
                </div>
              </div>
            </div>

            {/* <div className="add_right_forth_div">
              <div>
                <img src={img3} alt="" />{" "}
              </div>

              <div>
                <span>Delivery Charges On This Order</span>
                <span> Free Delivery on Orders above ₹500</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddToCart;
