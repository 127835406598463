import React from "react";
import "../../styles/privacyPolicy/ExplorePrinciple.css";

const ExplorePrinciple = () => {
  return (
    <div className="exploreprinciple-main">
      <div className="exploreprinciple-content-explor">
        <div>
          <p className="heading">
            <span> How We Use Your Information:</span>
          </p>
          <p>
            We may use the information we collect for various purposes,
            including:
          </p>
          <ul className="ul-style">
            <li className="li-style">To deliver your medicines.</li>
            <li className="li-style">
              To communicate with you about our services and respond to your
              inquiries
            </li>
            <li className="li-style">
              To personalise your experience on the Website.
            </li>
            <li className="li-style">
              To analyse trends and improve the Website.
            </li>
            <li className="li-style">
              To comply with applicable laws and regulations.
            </li>
          </ul>
        </div>
        <div>
          <p className="authorHeading">Sharing of Your Information:</p>
          <p>
            We may share your personal information with third parties in the
            following circumstances:
          </p>
          <ul className="ul-style">
            <li className="li-style">
              With service providers who help us operate the Website and provide
              our services.
            </li>
            <li className="li-style">
              With our affiliates and business partners for marketing and
              promotional purposes
            </li>
            <li className="li-style">
              To protect our rights, property, or safety, or the rights,
              property, or safety of others
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ExplorePrinciple;
