import React, { useEffect } from "react";
import Hero from "../components/refundPolicy/Hero";
import ExplorePrinciple from "../components/refundPolicy/ExplorePrinciple";
import PrinciplesSecond from "../components/refundPolicy/PrinciplesSecond";

function RefundPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Hero />
            <ExplorePrinciple />
            <PrinciplesSecond />
        </div>
    );
}

export default RefundPolicy;
