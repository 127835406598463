import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import pkg from "../../../package.json";

const prepareHeaders = (headers) => {
  // Read token from cookies
  const token = localStorage.getItem("authToken");

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  return headers;
};

const profileEditApis = createApi({
  reducerPath: "profileEditApis",
  baseQuery: fetchBaseQuery({
    baseUrl: pkg.baseUrl,
    prepareHeaders,
  }),
  tagTypes: ["DashboardData"],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => "/user/dashboard",
      providesTags: ["DashboardData"],
    }),
    passVerify: builder.mutation({
      query: (password) => ({
        url: "/user/passwordVerify",
        method: "POST",
        body: { password: password },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (password) => ({
        url: "/user/forgotPassword",
        method: "POST",
        body: password,
      }),
    }),
    updatePassword: builder.mutation({
      query: (password) => ({
        url: "/user/updatePassword",
        method: "POST",
        body: password,
      }),
      invalidatesTags: ["DashboardData"],
    }),
    updateName: builder.mutation({
      query: (name) => ({
        url: "/user/updateName",
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["DashboardData"],
    }),
    updatePhone: builder.mutation({
      query: (phone) => ({
        url: "/user/updatePhone",
        method: "POST",
        body: phone,
      }),
      invalidatesTags: ["DashboardData"],
    }),
    updateEmail: builder.mutation({
      query: (email) => ({
        url: "/user/updateEmail",
        method: "POST",
        body: email,
      }),
      invalidatesTags: ["DashboardData"],
    }),
    sendOtp: builder.mutation({
      query: (phone) => ({
        url: "/user/sendOtp1",
        method: "POST",
        body: phone,
      }),
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  usePassVerifyMutation,
  useUpdateEmailMutation,
  useUpdateNameMutation,
  useUpdatePasswordMutation,
  useUpdatePhoneMutation,
  useGetDashboardQuery,
  useSendOtpMutation,
} = profileEditApis;

export default profileEditApis;
