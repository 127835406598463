import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { appointmentBooking, repeatMedicineApi } from '../../Constents/apiAxios';
import { toast } from 'react-toastify';


// Define the initial state
const initialState = {
    bookingStatus: '',
    loading: false,
    error: null,
};

// Define the thunk action creator to book online consultation
export const bookOnlineConsultation = createAsyncThunk(
    'onlineConsultation/bookOnlineConsultation',
    async (formData) => {
        try {
            const response = await axios.post(appointmentBooking, formData);


            return response.data;
        } catch (error) {
            throw new Error('Failed to book online consultation');
        }
    }
);

//define the think action creator to repeat medicine form
export const repeatMedicine = createAsyncThunk(
    "repeatMedicine/repeatMedicineForm",
    async (formData) => {
        try {
            const response = await axios.post(repeatMedicineApi, formData);
            console.log("repeat medicine response:::::", response.data)
            if (response?.data?.status) {
                toast.success(response?.data?.status?.message);
            }
            return response?.data;
        } catch (error) {
            console.log("respeat medicien error", error)
            throw new Error("Failed to book repeat medicine");
        }
    }
);

// Create the online consultation slice
const onlineConsultationSlice = createSlice({
    name: 'onlineConsultation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bookOnlineConsultation.pending, (state) => {
                state.loading = true;
            })
            .addCase(bookOnlineConsultation.fulfilled, (state, action) => {
                state.loading = false;
                state.bookingStatus = action.payload.status;
            })
            .addCase(bookOnlineConsultation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(repeatMedicine.pending, (state) => {
                state.loading = true;
            })
            .addCase(repeatMedicine.fulfilled, (state, action) => {
                state.loading = false;
                state.bookingStatus = action.payload.status;
            })
            .addCase(repeatMedicine.rejected, (state, action) => {
                state.loading = false;
                state.loading = action.error.message;
            });
    },
});

// Export the reducer
export default onlineConsultationSlice.reducer;

// Selector
export const selectBookingStatus = (state) => state.onlineConsultation.bookingStatus;
export const selectLoadingOnline = (state) => state.onlineConsultation.loading;
export const selectError = (state) => state.onlineConsultation.error;
