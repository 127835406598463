import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "./reducers/login/logInReducer";
import categoryReducer from "./categorySlice/CategorySlice";
import productReducer from "./CartSlice/CartSlice";
import labReducer from "./labSlice/labSlice";
import labBookingReducer from "./labSlice/labBooking";
import singleLabReducer from "./labSlice/singleLab";
import processCardReducer from "./labSlice/processCard";
import contactSliceReducer from "./enquirySlice/contactSlice";
import consultationReducer from "./enquirySlice/onlineConsulatation";
import SearchQuerySlice from "./SearchQuery/SearchQuerySlice";
import userAddressReducer from "./addressSlice";
import orderHistoryReducer from "./OrderSlice";
import newCartReducer from "./newCart/newCart";
import newBuyNowReducer from "./newBuyNow/newBuyNow";
import newWishlistReducer from "./newWishlist/newWishlist";
import catogoriesReducer from "./categories/categories";
import productsDataReducer from "./productsData/productsData";
import filterReducer from "./filterSlice/filterSlice";
import signupReducer from "./signupSlice/signupSlice";
import AddressReducer from "./signupSlice/userAddress";
import returnReducer from "./newBuyNow/returnOrder";
import profileEditApis from "./apis/profileEditApis";

const store = configureStore({
  reducer: {
    login: loginReducer,
    category: categoryReducer,
    products: productReducer,
    labs: labReducer,
    processCard: processCardReducer,
    labBooking: labBookingReducer,
    singleLab: singleLabReducer,
    contact: contactSliceReducer,
    onlineConsultation: consultationReducer,
    searchQuery: SearchQuerySlice,
    address: userAddressReducer,
    orderHistory: orderHistoryReducer,
    newCart: newCartReducer,
    newBuyNow: newBuyNowReducer,
    newWishlist: newWishlistReducer,
    categories: catogoriesReducer,
    productsData: productsDataReducer,
    filter: filterReducer,
    signup: signupReducer,
    addressData: AddressReducer,
    returnOrder: returnReducer,
    [profileEditApis.reducerPath]: profileEditApis.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(profileEditApis.middleware),
});

export default store;
