import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { sendOtpToUserApi, verifyOtpApi, signupApi, dasboardApi } from '../../Constents/apiAxios';
import { setAuthHeader } from '../actions/logIn/AuthenticateHeader';
import { loginSuccess } from '../actions/logIn/LogInAction';
import { fetchUserCartAsync } from '../newCart/newCart';
import { fetchUserWishlistAsync } from '../newWishlist/newWishlist';
import { fetchUserAddresses } from './userAddress';

// Initial State
const initialState = {
    otp: "",
    token: null,
    loginModal: false,
    signupModal: false,
    forgotModal: false,
    loading: false,
    error: null,
};

// Async Thunks
export const sendOtp = createAsyncThunk(
    'signup/sendOtp',
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post(sendOtpToUserApi, formData);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const verifyOtp = createAsyncThunk(
    'signup/verifyOtp',
    async (_, thunkAPI) => {
        try {
            const otp = thunkAPI.getState().signup?.otp;
            const token = thunkAPI.getState().signup?.token;
            const response = await axios.post(verifyOtpApi, { otp: otp, token: token });

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const completeSignup = createAsyncThunk(
    'signup/completeSignup',
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post(signupApi, formData);

            if (response?.data?.status === "success") {
                if (response?.data?.token) {
                    const response1 = await axios.get(dasboardApi, {
                        headers: {
                            Authorization: `Bearer ${response.data.token}`,
                        },
                    });

                    const userData = {
                        user: response1.data.data,
                        token: response.data.token,
                    };

                    localStorage.setItem("authToken", userData.token);
                    setAuthHeader(userData.token);
                    const serializedUser = JSON.stringify(userData.user);
                    localStorage.setItem("userData", serializedUser);
                    thunkAPI.dispatch(loginSuccess(userData));
                    thunkAPI.dispatch(fetchUserCartAsync(response.data.token))
                    thunkAPI.dispatch(fetchUserWishlistAsync(response.data.token))
                    thunkAPI.dispatch(setLoginModal(false));
                    thunkAPI.dispatch(fetchUserAddresses(response.data.token))
                }
            }
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Slice
const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setOtp(state, action) {
            state.otp = action.payload;
        },

        setLoginModal: (state, action) => {
            state.loginModal = action.payload
        },
        setSignupModal: (state, action) => {
            state.signupModal = action.payload
        },
        setForgotModal: (state, action) => {
            state.forgotModal = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendOtp.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.token

            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(completeSignup.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(completeSignup.fulfilled, (state) => {
                state.loading = false;
                state.otp = "";
                state.token = null


            })
            .addCase(completeSignup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setOtp, setLoginModal, setSignupModal, setForgotModal } = signupSlice.actions;

export default signupSlice.reducer;

export const signupLoading = (state) => state?.signup?.loading
export const getSignupModal = (state) => state?.signup?.signupModal
export const getLoginModal = (state) => state?.signup?.loginModal
export const getForgotModal = (state) => state?.signup?.forgotModal
