import React, { useEffect, useState } from "react";
import "../../styles/SingleProduct/Hero.css";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { CgShoppingCart } from "react-icons/cg";
import ToggleButton from "./ToggelButton";
import { Zoom, Bounce } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setShowForm,
} from "../../store/CartSlice/CartSlice";
import { useSelector } from "react-redux";
import icon from "../../Assets/Images/singleProduct/logo.png";
import icon2 from "../../Assets/Images/singleProduct/logo2.png";
import { addItemToCart, addItemToCartAsync } from "../../store/newCart/newCart";
import { toast } from "react-toastify";
import { addItemToBuyNow, clearNewBuyNow } from "../../store/newBuyNow/newBuyNow";
import { addItemToWishlist, addItemToWishlistAsync } from "../../store/newWishlist/newWishlist";
import { setLoginModal } from "../../store/signupSlice/signupSlice";


const Hero = ({ productDatas, productSize, productPotency }) => {

  console.log("single product", productDatas)
  const isAuthenticated = !!localStorage.getItem("authToken");
  const token = useSelector((state) => state.login.token)
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const [selectedSize, setSelectedSize] = useState(
    productSize || productDatas.productDetail.size[0]
  );
  const [selectedPotency, setSelectedPotency] = useState(
    productPotency || productDatas.productDetail.potency[0]
  );
  const [selectedVar, setSelectedVar] = useState(
    productDatas.productvariant[0]
  );
  const [selectedVariantCart, setSelectedVariantCart] = useState(
    productDatas.productvariant[0]
  );



  useEffect(() => {
    const varients = productDatas?.productvariant?.filter((item) => {
      if (selectedSize === item.size && (selectedPotency === item.potency)) {
        setSelectedVar(item);
        setSelectedVar((prevState) => ({
          ...prevState,
          categoryByDisease: productDatas?.productDetail?.categoryByDisease,
        }));

        return item;
      }
    });


    if (varients.length > 0) {
      setSelectedVariantCart(varients[0])
    } else {
      setSelectedVariantCart()
    }
  }, [selectedSize, selectedPotency]);


  const handleCartButton = async () => {
    if (selectedVariantCart) {
      if (token) {
        const datatoSend = {
          productId: selectedVariantCart?.productId,
          productVariantId: selectedVariantCart?._id,
        }
        if (selectedVariantCart.inventory >= 1) {
          await dispatch(addItemToCartAsync(datatoSend));

        } else {
          toast.error("this product is out of stock currently")
        }

      } else {
        const dataToSend = {
          productVariantId: selectedVariantCart._id,
          productId: selectedVariantCart?.productId,
          brand: productDatas?.productDetail?.brand[0],
          productName: selectedVariantCart?.productName,
          size: selectedVariantCart?.size,
          potency: selectedVariantCart?.potency,
          inventory: selectedVariantCart?.inventory,
          costPrice: selectedVariantCart?.costPrice,
          mrp: selectedVariantCart?.mrp,
          discount: selectedVariantCart?.discount,
          sellingPrice: selectedVariantCart?.sellingPrice,
          imageObj: selectedVariantCart?.imageObj,
        }
        if (selectedVariantCart.inventory >= 1) {
          dispatch(addItemToCart(dataToSend))
        } else {
          toast.error("this product is out of stock currently")
        }
      }
    } else {
      console.log("the product is not available at the time")
    }

  }
  // handle wishlist button 
  const handleWishlistButton = async () => {
    if (selectedVariantCart) {
      if (token) {
        const datatoSend = {
          productId: selectedVariantCart?.productId,
          productVariantId: selectedVariantCart?._id,
        }
        await dispatch(addItemToWishlistAsync(datatoSend));

      } else {
        const dataToSend = {
          productVariantId: selectedVariantCart._id,
          productId: selectedVariantCart?.productId,
          brand: productDatas?.productDetail?.brand[0],
          productName: selectedVariantCart?.productName,
          size: selectedVariantCart?.size,
          potency: selectedVariantCart?.potency,
          inventory: selectedVariantCart?.inventory,
          costPrice: selectedVariantCart?.costPrice,
          mrp: selectedVariantCart?.mrp,
          discount: selectedVariantCart?.discount,
          sellingPrice: selectedVariantCart?.sellingPrice,
          imageObj: selectedVariantCart?.imageObj,
        }
        dispatch(addItemToWishlist(dataToSend))
      }
    } else {
      console.log("the product is not available at the time")
    }
  }


  const handleBuyNow = async () => {
    dispatch(setShowForm(false))
    if (!isAuthenticated) {
      localStorage.setItem("redirectUrl", window.location.pathname);
      dispatch(setLoginModal(true))
    } else {
      try {
        dispatch(clearNewBuyNow());
        console.log("selected variant", selectedVariantCart);

        if (selectedVariantCart.inventory < 1) {
          toast.error("the product is out of stock")
        } else {

          const datatoSend = {
            productId: selectedVariantCart?.productId,
            productVariantId: selectedVariantCart?._id,
            brand: productDatas?.productDetail?.brand[0],
            costPrice: selectedVariantCart?.costPrice,
            discount: selectedVariantCart?.discount,
            imageObj: selectedVariantCart?.imageObj,
            inventory: selectedVariantCart?.inventory,
            mrp: selectedVariantCart?.mrp,
            potency: selectedVariantCart?.potency,
            productName: selectedVariantCart?.productName,
            sellingPrice: selectedVariantCart?.sellingPrice,
            size: selectedVariantCart?.size,
            quantity: 1
          }
          dispatch(addItemToBuyNow(datatoSend));

          navigate("/buynow");
        }
      } catch (error) {
        console.log("Error", error.message);
      }
    }

  };

  const generateStarIcons = (rating) => {
    const starIcons = [];

    for (let i = 0; i < 5; i++) {
        if (i < Math.floor(rating)) {
            // Full star
            starIcons.push(<FaStar  key={i} style={{ color: "#FFD700" }} />);
        } else if (i < rating) {
            // Partial star (use percentage to fill the star partially)
            starIcons.push(
                <FaStarHalfAlt key={i} style={{ color: "#FFD700" }} />
            );
        } else {
            // Empty star
            starIcons.push(<FaStar  key={i} style={{ color: "#ccc" }} />);
        }
    }

    return starIcons;
};



  const handlePotency = (potency) => {
    setSelectedPotency(potency);
  };

  const handleSize = (size) => {
    setSelectedSize(size);
    console.log("getting", size)
  };

  const filteredCategories =
    productDatas?.productDetail?.categoryByDisease.filter(
      (word) => !word.includes(",")
    );

  return (
    <>
      {productDatas && (
        <div className="productHeromain">
          <div className="productherocontent">
            {/* <Fade direction="left"> */}
            <div className="productheroimg">
              <img
                src={selectedVar?.imageObj?.url}
                alt="Selected Product"
                className="selected-image"
              />
            </div>
            {/* </Fade> */}
            <div className="productherotext">
              <Bounce>
                <div className="product-info">
                  <h1>{productDatas?.productDetail?.brand[0]} {selectedVar?.productName} {selectedVar?.potency !== "N/A" ? selectedVar?.potency : ""} {selectedVar?.size}</h1>
                  <p>{filteredCategories.join(", ")}</p>

                  <p className="ratings">
                    <span>{generateStarIcons(productDatas?.averageRating)}</span>
                    <span>({productDatas?.noOfRating}) Rating</span>{" "}
                    <span>{productDatas.reviewCount} Reviews</span>{" "}
                    <span>Q & A</span>
                  </p>
                  <div className="offers-section1">
                    <div>
                      <span>
                        <img src={icon} alt="logo" />
                      </span>
                      <span>Pay now to get 5% Off</span>
                    </div>
                  </div>


                  <p className="price-info">
                    <h2>₹{selectedVar.sellingPrice}</h2>
                    <span style={{ color: "#196ea4", textDecoration: "line-through" }}>
                      MRP ₹{selectedVar.mrp}
                    </span>{" "}
                    <span className="discount">
                      {selectedVar.discount}% OFF
                    </span>
                  </p>
                  {/* <p>({productDatas.taxes})</p> */}
                </div>
              </Bounce>

              <Bounce>
                <div className="potency-section">
                  <p>
                    <b>Select From available Size</b>
                  </p>
                  <div className="itemmore">
                    {productDatas?.productDetail?.size?.map((size) => (
                      <div
                        key={size.potency}
                        className="potency-btn"
                        onClick={() => {
                          handleSize(size);
                        }}
                        style={{
                          background: selectedSize === size ? "#196EA4" : "",
                        }}
                      >
                        <p
                          style={{
                            color: selectedSize === size ? "#EDF8FE" : "",
                          }}
                        >
                          {size}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Bounce>
              <Bounce>
                <div className="potency-section">
                  <p>
                    <b>Select From available Potency</b>
                  </p>
                  <div className="itemmore">
                    {productDatas?.productDetail?.potency?.map((potency) => (
                      <>
                        {potency !== "N/A" && <div
                          key={potency.potency}
                          className="potency-btn"
                          onClick={() => {
                            handlePotency(potency);
                          }}
                          style={{
                            background:
                              selectedPotency === potency ? "#196EA4" : "",
                          }}
                        >
                          <p
                            style={{
                              color: selectedPotency === potency ? "#EDF8FE" : "",
                            }}
                          >
                            {potency}
                          </p>
                        </div>}
                      </>
                    ))}
                  </div>
                </div>
              </Bounce>
              {/* {item.inventory} */}
              {/* {tr} */}
              {
                selectedVar?.inventory < 10 && (
                  <div className="offers-section">
                    {selectedVar?.inventory === 0 ? <p>Availability: Out of Stock</p> : <p>Availability: Hurry ! Only {selectedVar?.inventory} left at this price</p>}
                  </div>
                )
              }
              <Bounce>
                <div className="offers-section">
                  <p>
                    <b>Available Offers</b>
                  </p>
                  <div>
                    <span>
                      <img src={icon2} alt="logo" />
                    </span>

                    <span>Free Delivery on Orders above ₹500</span>
                  </div>


                </div>
              </Bounce>

              <Zoom>
                <div className="action-buttons">
                  <button className="action-btn buy-btn" onClick={handleBuyNow}>
                    Buy Now
                  </button>

                  <ToggleButton
                    textContent="Add to Wishlist"
                    icon={<FaRegHeart />}
                    onClick={handleWishlistButton}
                  />

                  <ToggleButton
                    textContent="Add to Cart"
                    icon={<CgShoppingCart />}
                    onClick={handleCartButton}
                  />
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hero;