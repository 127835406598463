import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { returnApi } from "../../Constents/apiAxios";
import { toast } from "react-toastify";

const initialState = {
    returnOrder: [],
    loading: false,
    error: null,
};

export const returnOrderAsync = createAsyncThunk(
    "returnOrder/returnOrderAsync",
    async (formdata, { getState, rejectWithValue }) => {

        try {
            const token = getState().login.token;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            };
            const response = await axios.post(returnApi, formdata, config);
            if (response.data.status === "success") {
                toast.success(`${response.data.message}`);
            }
            return response.data;
        } catch (error) {
            console.log("return order", error);

            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            toast.error(`${message}`)
            return rejectWithValue(message);
        }
    }
);

const returnOrderSlice = createSlice({
    name: "returnOrder",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(returnOrderAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(returnOrderAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.returnOrder = action.payload;
            })
            .addCase(returnOrderAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default returnOrderSlice.reducer;

export const selectReturnOrder = (state) => state?.returnOrder?.returnOrder;
export const selectReturnOrderLoading = (state) => state?.returnOrder?.loading;
export const selectReturnOrderError = (state) => state?.returnOrder?.error;
