import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
    orderHistoryApi,
    labHistoryApi,
    consultancyHistoryApi,
} from "../Constents/apiAxios";
import { toast } from "react-toastify";

const initialState = {
    orderHistory: [],
    labBookingHistory: [],
    consultancyHistory: [],
    loading: false,
    error: null,
    orderPage: 1,
    labPage: 1,
    consultancyPage: 1,
    orderTotalPages: 1,
    labTotalPages: 1,
    consultancyTotalPages: 1,
    itemPerPage: 10,
};

// Fetch Order History
export const fetchOrderHistory = createAsyncThunk(
    "orderHistory/fetchOrderHistory",
    async (_, { rejectWithValue, getState }) => {
        const state = getState();
        const page = state.orderHistory.orderPage;
        const token = localStorage.getItem("authToken");
        try {
            const response = await axios.get(
                `${orderHistoryApi}?page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            toast.error(error.message);
            return rejectWithValue(error.message);
        }
    }
);

// Fetch Lab Booking History
export const fetchLabHistory = createAsyncThunk(
    "orderHistory/fetchLabHistory",
    async (_, { rejectWithValue, getState }) => {
        const state = getState();
        const page = state.orderHistory.labPage;
        const token = localStorage.getItem("authToken");
        try {
            const response = await axios.get(`${labHistoryApi}?page=${page}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            toast.error(error.message);
            return rejectWithValue(error.message);
        }
    }
);

// Fetch Consultancy History
export const fetchConsultancy = createAsyncThunk(
    "orderHistory/fetchConsultancy",
    async (_, { rejectWithValue, getState }) => {
        const state = getState();
        const page = state.orderHistory.consultancyPage;
        const token = localStorage.getItem("authToken");

        try {
            const response = await axios.get(
                `${consultancyHistoryApi}?page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            toast.error(error.message);
            return rejectWithValue(error.message);
        }
    }
);

const orderSlice = createSlice({
    name: "orderHistory",
    initialState,
    reducers: {
        setOrderPage: (state, action) => {
            state.orderPage = action.payload;
        },
        setLabPage: (state, action) => {
            state.labPage = action.payload;
        },
        setConsultancyPage: (state, action) => {
            state.consultancyPage = action.payload;
        },
        setOrderTotalPages: (state, action) => {
            state.orderTotalPages = action.payload;
        },
        setLabTotalPages: (state, action) => {
            state.labTotalPages = action.payload;
        },
        setConsultancyTotalPages: (state, action) => {
            state.consultancyTotalPages = action.payload;
        },
        saveOrderHistory: (state, action) => {
            state.orderHistory = action.payload;
        },
        clearOrderHistory: (state) => {
            state.orderHistory = initialState.orderHistory;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderHistory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrderHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.orderHistory = action.payload;
                state.orderTotalPages = action.payload.totalPages;
            })
            .addCase(fetchOrderHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Something went wrong";
            })
            .addCase(fetchLabHistory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLabHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.labBookingHistory = action.payload;
                state.labTotalPages = action.payload.totalPages;
            })
            .addCase(fetchLabHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Something went wrong";
            })
            .addCase(fetchConsultancy.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchConsultancy.fulfilled, (state, action) => {
                state.loading = false;
                state.consultancyHistory = action.payload;
                state.consultancyTotalPages = action.payload.totalPages;
            })
            .addCase(fetchConsultancy.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Something went wrong";
            });
    },
});

export const {
    setOrderPage,
    setLabPage,
    setConsultancyPage,
    setOrderTotalPages,
    setLabTotalPages,
    setConsultancyTotalPages,
    saveOrderHistory,
    clearOrderHistory,
} = orderSlice.actions;

export default orderSlice.reducer;
