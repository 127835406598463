import React from "react";
import "../../styles/privacyPolicy/Principles.css";
import principleImg from "../../Assets/Images/termsAndConditions/Group 706.svg";

const Principles = () => {
  return (
    <div className="principles-main">
      <div className="principles-content">
        <div className="principles-text">
          {/* <p className="heading">Exploring the Principles</p> */}
          <p className="subheading">
            Exploring the Principles and{" "}
            <span>Benefits of Homeopathic Medicines</span>
          </p>
          <p>
           Product Usage: By accessing and using our website, you agree to abide by these Terms and Conditions. Any violation of these terms may result in the termination of your access to our services.
          </p>
          <p>
          Return Process: To initiate a return or refund, please contact our customer service team with your order details and reason for return. We may require additional information or evidence, such as photographs or videos, to process your request.
          </p>
        </div>
        <div className="principles-img">
          <img src={principleImg} alt="principleImg" />
        </div>
      </div>
    </div>
  );
};

export default Principles;
