import React from "react";
import "../../styles/privacyPolicy/ExplorePrincipleC.css";

const ExplorePrinciple = () => {
  return (
    <div className="exploreprinciple-main">
      <div className="exploreprinciple-content">
        <p className="heading">
        Refund Policy: Refunds are only available in 
          <span> the following circumstances:</span>
        </p>
        <p>
        a. Product Damage: If the product is received in a damaged or defective condition, the buyer must provide video evidence of the damage within 48 hours of receiving the product to be eligible for a refund.
        </p>
        <p>
        b. Wrong Item Sent: If the wrong item is sent by us, the buyer must notify us within 48 hours of receiving the product and return the item in its original condition to be eligible for a refund.
        </p>

        <p className="authorHeading">Return Policy: Returns are accepted in the following circumstances:</p>
        <p>
        a. Expired Products: If the product is expired upon receipt, the buyer may return the item for a replacement or refund within 7 days of delivery.
        </p>
        <p>
        b. Incorrect Item: If the wrong item is sent by us, the buyer may return the item in its original condition for a replacement or refund within 7 days of delivery.
        </p>
       
      </div>
    </div>
  );
};

export default ExplorePrinciple;
