// cartSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getCartApi, cartAddApi, removeCartApi, updateCartQuantity, clearCartApi } from '../../Constents/apiAxios';
import axios from 'axios';
const initialState = {
    newCart: [], // Array to store cart items
    loading: false,
    error: null,
};

// Async Thunk action to fetch user's cart from the backend
export const fetchUserCartAsync = createAsyncThunk(
    'cart/fetchUserCart',
    async (token, thunkAPI) => {
        try {

            // Define the headers with the token
            const dispatch = thunkAPI.dispatch
            dispatch(setCartLoading(true))
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            // Make a GET request to fetch user's cart from the backend API
            const response = await axios.get(getCartApi, config);
            // Return the cart data from the response
            // console.log("response from user's cart", response.data)
            dispatch(updateCartAfterLogin(response?.data?.cartProducts))

            dispatch(setCartLoading(false))
            return response?.data?.cartProducts;
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async Thunk action to add a product to the cart
export const addItemToCartAsync = createAsyncThunk(
    'cart/addItemToCart',
    async (item, thunkAPI) => {
        try {

            console.log("item to send", item)
            const dataToSend = {
                productId: item.productId,
                productVariantId: item.productVariantId,
                quantity: 1
            }
            // Get the token from the Redux store
            const token = thunkAPI.getState().login.token;
            console.log(token)

            // Check if the user is authenticated
            if (!token) {
                throw new Error('User not authenticated');
            }

            // Define the headers with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            // Make a POST request to add the item to the cart
            const response = await axios.post(cartAddApi, dataToSend, config);

            return response.data
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async Thunk action to delete a product from the cart
export const cartItemDeleteAsync = createAsyncThunk(
    'cart/deleteCartItem',
    async (cartItemId, thunkAPI) => {
        try {
            // Get the token from the Redux store
            const token = thunkAPI.getState().login.token;

            // Check if the user is authenticated
            if (!token) {
                throw new Error('User not authenticated');
            }

            // Define the headers with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            // Make a DELETE request to delete the item from the cart
            await axios.delete(`${removeCartApi}${cartItemId}`, config);

            // Return the deleted cartItemId
            return cartItemId;
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// update the cart Item quantity if user is logged In 
export const updateCartItemQuantityAsync = createAsyncThunk(
    'cart/updateCartItemQuantity',
    async ({ cartItemId, action }, thunkAPI) => {
        try {
            // Get the token from the Redux store
            const token = thunkAPI.getState().login.token;
            if (!token) {
                throw new Error('User not authenticated');
            }

            // Define the headers with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            // Make the request to update the cart item quantity
            const response = await axios.put(`${updateCartQuantity}${cartItemId}?action=${action}`, config);
            console.log("responseData", response.data)
            // Return the updated cart item data
            return response?.data?.data;
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// clear the cart
export const clearCartAsync = createAsyncThunk(
    'cart/clearCart',
    async (token, thunkAPI) => {
        try {
            // Get the token from the Redux store
            const token = thunkAPI.getState().login.token;
            if (!token) {
                throw new Error('User not authenticated');
            }
            
            // Define the headers with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            // Make the request to update the cart item quantity
            const response = await axios.delete(clearCartApi, config);
            // Return the updated cart item data
            return response?.data?.data;
        } catch (error) {
            // Handle error if API request fails
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // Action to add a product to the cart
        addItemToCart(state, action) {
            const newItem = action.payload;
            // Check if the item already exists in the cart
            const existingItem = state.newCart.find(item => item.productVariantId === newItem.productVariantId);
            if (existingItem) {
                // If item exists, update its quantity
                // existingItem.quantity += 1;
                toast.error("Product is already present in the Cart")
            } else {
                // If item doesn't exist, add it to the cart with quantity 1
                const dataToPush = {
                    productVariantId: newItem.productVariantId,
                    productId: newItem.productId,
                    productName: newItem.productName,
                    size: newItem.size,
                    potency: newItem.potency,
                    inventory: newItem.inventory,
                    costPrice: newItem.costPrice,
                    mrp: newItem.mrp,
                    discount: newItem.discount,
                    sellingPrice: newItem.sellingPrice,
                    imageObj: newItem.imageObj,
                    quantity: 1
                }
                state.newCart.push(dataToPush);
                toast.success("Product Added in the Cart");
                localStorage.setItem('newcart', JSON.stringify(state.newCart));
            }
        },
        // Action to remove a product from the cart
        removeItemFromCart(state, action) {
            const productVariantId = action.payload;
            state.newCart = state.newCart.filter(item => item.productVariantId !== productVariantId);
            localStorage.setItem('newcart', JSON.stringify(state.newCart));
        },
        // Action to clear all items from the cart
        clearCart(state) {
            state.newCart = [];
        },
        // Action to set cart items from local storage
        setCartFromLocalStorage(state, action) {
            state.newCart = action.payload || [];
        },
        // Action to update cart items after user login
        updateCartAfterLogin(state, action) {
            const userCart = action.payload;
            const token = state?.login?.token;
            console.log(token)
            // Iterate through the user's cart items
            userCart.forEach(userCartItem => {
                // Check if the user's cart item already exists in the local cart
                const existingCartItemIndex = state.newCart.findIndex(localCartItem => localCartItem.productVariantId === userCartItem.productVariantId);

                if (existingCartItemIndex !== -1) {
                    // If the item exists, replace it with the user's cart item
                    state.newCart.splice(existingCartItemIndex, 1, userCartItem);
                } else {
                    // If the item doesn't exist, add it to the local cart
                    state.newCart.push(userCartItem);
                }
            });
            // Send local cart items not found in the user's cart to the database
            const localItemsNotInUserCart = state.newCart.filter(localCartItem => {
                return !localCartItem.hasOwnProperty('_id');
            });

            localItemsNotInUserCart.forEach(async (localCartItem) => {
                try {
                    const dataToSend = {
                        productId: localCartItem.productId,
                        productVariantId: localCartItem.productVariantId,
                        quantity: localCartItem.quantity
                    }
                    // Make a POST request to add the local cart item to the database
                     await axios.post(cartAddApi, dataToSend);
                } catch (error) {
                    console.error('Error adding item to database:', error);
                }
            });
        },

        // increase the item quantity in the cart 
        increaseItemQuantity: (state, action) => {
            const productVariantId = action.payload;
            const itemIndex = state.newCart.findIndex(item => item.productVariantId === productVariantId);
            if (itemIndex !== -1) {
                // Only increase the quantity if it is less than the available inventory
                console.log("qunatity", state?.newCart[itemIndex]?.quantity)
                console.log("inventory", state?.newCart[itemIndex]?.inventory)
                if (state?.newCart[itemIndex]?.quantity < state?.newCart[itemIndex]?.inventory) {
                    state.newCart[itemIndex].quantity += 1;
                } else {
                    toast.error('Cannot increase quantity. Only ' + state?.newCart[itemIndex]?.inventory + ' items available in stock.');
                }
            }
        },

        // decrease the item quantity in the cart 
        decreaseItemQuantity: (state, action) => {
            const productVariantId = action.payload;
            const itemIndex = state.newCart.findIndex(item => item.productVariantId === productVariantId);
            if (itemIndex !== -1) {
                if (state.newCart[itemIndex].quantity > 1) {
                    state.newCart[itemIndex].quantity -= 1;
                }
                // No need to handle the case where quantity is 1
            }
        },

        // Action to mark cart loading state
        setCartLoading(state, action) {
            state.loading = action.payload;
        },
        // Action to handle cart loading error
        setCartError(state, action) {
            state.error = action.payload;

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addItemToCartAsync.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addItemToCartAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.newCart = [...state.newCart, action.payload.data]
                // You can handle success state if needed
                toast.success("Product Added in the Cart");

            })
            .addCase(addItemToCartAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                toast.error("Product is already present in the Cart")


            })
            .addCase(cartItemDeleteAsync.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(cartItemDeleteAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.newCart = state.newCart.filter(item => item._id !== action.payload);
                toast.success("Product removed from the Cart");
            })
            .addCase(cartItemDeleteAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                toast.error("Product could not removed from the Cart");
            })
            .addCase(clearCartAsync.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            }
            )
            .addCase(clearCartAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.newCart = [];
            }
            )
            .addCase(clearCartAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                toast.error("Cart could not cleared");
            }
            )
            // cases for update cart quantity 
            .addCase(updateCartItemQuantityAsync.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCartItemQuantityAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;

                // Extract _id and message from the response payload
                const { _id, message } = action.payload;
                console.log(message)
                // Check if the payload contains _id and message "Cart item removed"
                if (_id && message === "Cart item removed") {
                    // Find the index of the cart item to be removed
                    const removedCartItemIndex = state.newCart.findIndex(item => item._id === _id);
                    if (removedCartItemIndex !== -1) {
                        // Remove the cart item from the newCart array
                        state.newCart.splice(removedCartItemIndex, 1);
                    }
                } else {
                    // Find the cart item in the Redux store by _id
                    const updatedCartItemIndex = state.newCart.findIndex(item => item._id === _id);
                    if (updatedCartItemIndex !== -1) {
                        // Update the quantity of the cart item with the quantity received from the response
                        state.newCart[updatedCartItemIndex].quantity = action.payload.quantity;
                    }
                }
            })
            .addCase(updateCartItemQuantityAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;

            });
    },

});

export const {
    addItemToCart,
    removeItemFromCart,
    clearCart,
    setCartFromLocalStorage,
    updateCartAfterLogin,
    setCartLoading,
    setCartError,
    increaseItemQuantity,
    decreaseItemQuantity
} = cartSlice.actions;

export default cartSlice.reducer;

