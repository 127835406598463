import { useState, useEffect } from "react";

import "../styles/layout/SearchBar.css";
import { useDispatch, useSelector } from "react-redux";
import { FaMagnifyingGlass } from "react-icons/fa6";

import {
    setSearchQuery,
} from "../store/SearchQuery/SearchQuerySlice";
import { useNavigate } from "react-router-dom";
import {
    fetchAllProductDataBySearch,
    setCurrentCategory,
} from "../store/productsData/productsData";
import { selectCategory } from "../store/categorySlice/CategorySlice";

function SearchBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchQueryInput, setSearchQueryInput] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const searchData = useSelector((state) => state.category.saveSearchData);

    const filteredData = searchData.filter((item) =>
        item.toLowerCase().includes(searchQueryInput.toLowerCase())
    );

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    // Function to handle item selection
    const handleItemSelect = async (item) => {
        dispatch(setCurrentCategory("searchProduct"));
        setSearchQueryInput(item); // Set selected item
    dispatch(setSearchQuery(item));
        dispatch(selectCategory(item));
        navigate("/allproducts");
        await dispatch(fetchAllProductDataBySearch(item));
    };

    const handleSearch = (value) => {
        setSearchQueryInput(value);
    };
    const handleFormOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="search_item">
                <div className="search_item_search_div">
                    <input
                        type="text"
                        placeholder="Search Your medicine "
                        onClick={() => handleFormOpen()}
                        readOnly
                    />
                    <FaMagnifyingGlass className="search_icon" />
                </div>
            </div>

            {isOpen && (
                <>
                    <div
                        className="search_pop_up"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <h5 onClick={() => setIsOpen(!isOpen)}>X</h5>
                    </div>

                    <div className="search_items_component">
                        <div className="search_pop_up_div">
                            <input
                                type="text"
                                className="search_pop_up_input"
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchQueryInput}
                                placeholder="Search Your medicine"
                            />
                            <FaMagnifyingGlass className="search_icon_two" />
                        </div>
                                {searchQueryInput !== "" && (
                                    <div className="search_dropdown">
                                        {filteredData.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    handleItemSelect(item)
                                                }
                                            >
                                                <p>{item}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                    </div>
                </>
            )}
        </>
    );
}

export default SearchBar;
