import React from "react";
import "../../styles/Blogs/TrandingBlog.css";
import { Bounce } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

function TrandingBlog({ allBlogData }) {
    const navigate = useNavigate();

    // show limited text on screen
    const getShortenedDescription = (text) => {
        const words = text.split(" ");
        return words.slice(0, 30).join(" ");
    };

    return (
        <section className="Trading_Container">
            <div className="Trading_Content">
                <div className="Trading_Head">
                    <Bounce>
                        <h2>
                            Recent <span>Blogs</span>
                        </h2>
                    </Bounce>
                </div>

                <div className="Trading_Middle">
                    {allBlogData?.map((item, index) => (
                        <div className="Trading_Middle_Content" key={index}>
                            <img
                                src={item?.images[0]?.imageObj[0]?.url}
                                alt=""
                            />
                            <div className="Trading_Middle_Text">
                                <h4>{item?.blogTitle}</h4>
                                <p>
                                    {getShortenedDescription(
                                        item?.shortDiscription
                                    )}
                                </p>
                                <button
                                    className="Read_More_Button"
                                    onClick={() =>
                                        navigate(`/blogdescription/${item?._id}`)
                                    }
                                >
                                    Read More
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default TrandingBlog;
