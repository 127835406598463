import React from "react";
import "../../styles/medicalTest/MedicalTestBottom.css";
import img1 from "../../Assets/Images/medicalTest/img4.png";
import img2 from "../../Assets/Images/medicalTest/img5.png";

function MedicalTestBottom() {
    return (
        <section className="medical_bottom_container">
            <div className="medical_bottom_content">
                <div className="medical_content_left">
                    <h3>Unlock Natural Wellness</h3>
                    <h2>
                        Exclusive Homeopathic Solutions Sale{" "}
                        <span>&ndash; Embrace Health, Naturally</span>
                    </h2>
                </div>
                <div className="medical_content_right">
                    <img src={img2} alt="" />
                    <img src={img1} alt="" />
                </div>
            </div>
        </section>
    );
}

export default MedicalTestBottom;
