import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import "./Constents/constants.css";
import dotenv from 'react-dotenv'
import store from "./store/store";
import { Provider } from "react-redux";
import {fetchAllLabPackagesAsync, fetchLabs } from "./store/labSlice/labSlice";
import {
    setAuthToken,
    setUserData,
} from "./store/actions/logIn/LogInAction";
import { fetchUserCartAsync, setCartFromLocalStorage } from "./store/newCart/newCart";
import axios from "axios";
import { fetchUserWishlistAsync, setWishlistFromLocalStorage } from "./store/newWishlist/newWishlist";
import { fetchCategoriesByBrand, fetchCategoriesByDisease, fetchMainCategories } from "./store/categories/categories";
import { fetchMostSellingProducts } from "./store/productsData/productsData";
import { initializeNewBuyNowFromLocalStorage } from "./store/newBuyNow/newBuyNow";
import { fetchUserAddresses } from "./store/signupSlice/userAddress";


// update cart when page loads
const cartFromLocalStorage = JSON.parse(localStorage.getItem('newcart'));
store.dispatch(setCartFromLocalStorage(cartFromLocalStorage));

// update wishlist when page loads
const wishlistFromLocalStorage = JSON.parse(localStorage.getItem('newwishlist'));
store.dispatch(setWishlistFromLocalStorage(wishlistFromLocalStorage))


// Dispatch the fetchLabs action when the application starts
store.dispatch(fetchLabs());


// dispatching the main category apis 
store.dispatch(fetchMainCategories());
store.dispatch(fetchCategoriesByDisease());
store.dispatch(fetchCategoriesByBrand());
store.dispatch(fetchMostSellingProducts());
store.dispatch(fetchAllLabPackagesAsync());


const userData = JSON.parse(localStorage.getItem("userData"));
const token = localStorage.getItem("authToken");

if (userData) {
    store.dispatch(setUserData(userData));
}
if (token) {
    store.dispatch(setAuthToken(token));
    store.dispatch(initializeNewBuyNowFromLocalStorage())
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    store.dispatch(fetchUserCartAsync(token));
    store.dispatch(fetchUserWishlistAsync(token));
    store.dispatch(fetchUserAddresses(token))
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>

);