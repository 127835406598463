import React from "react";
import "../../styles/privacyPolicy/PrinciplesSecond.css";
import principleImg from "../../Assets/Images/termsAndConditions/technical support 1.svg";

const PrinciplesSecond = () => {
  return (
    <div className="principlessecond-main">
      <div className="principlessecond-content">
        <div className="principlessecond-img">
          <img src={principleImg} alt="principleImg" className="t-img" />
        </div>
        <div className="principlessecond-text">
          <p className="subheading">
         <span>
         Return Process
          </span> 
          </p>

          <p>
          To initiate a return or refund, please contact our customer service team with your order details and reason for return. We may require additional information or evidence, such as photographs or videos, to process your request.
          </p>
          <p>
            Shipping Costs: Shipping costs associated with returns or refunds
            are the responsibility of the buyer, unless otherwise specified by
            us.
          </p>
          <p>
            Refund Processing Time: Refunds will be processed within 7-10
            business days of receiving the returned item and verifying its
            condition.
          </p>
      
          <p>
            Product Condition: Returned items must be in their original
            packaging and in the same condition as when they were received.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrinciplesSecond;
