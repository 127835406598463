import React from "react";
import "../../styles/BuyNowPage/PopUpAddress.css";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { selectUserAddresses } from "../../store/signupSlice/userAddress";

function PopUpAddress({ onClose, onSelectAddress, showAddressForm, setIsNewAddress }) {
  const addressData = useSelector(selectUserAddresses)

  const handleAddressSelect = (address) => {
    onSelectAddress(address);
    onClose();
    showAddressForm();
    setIsNewAddress(false);
  };

  return (
    <div className="address_container2">
      <div className="select_address_heading_popup">
        <div>
          <h2>Select Your Address</h2>
        </div>
        <div onClick={() => onClose() } className="address_container2_cross">
          <RxCross2 />
        </div>
      </div>

      <div className="address_container_slider">
        {addressData?.map((address, index) => (
          <div
            key={index}
            className="address_item2"
            onClick={() => handleAddressSelect(address)}
          >
            <p className="namOfUserInPopUp">{address?.fullName}</p>
            <p>{address?.addressLine1}</p>
            <p>{address?.addressLine2}</p>
            <p>
              {address?.city} <span>,</span> {address?.state}
            </p>

            <p>{address?.country}</p>
            <p>
              <span>Email:</span> {address?.email}
            </p>
            <p>
              <span>Mobile:</span> {address?.mobileNo}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PopUpAddress;
