import React from "react";
import "../../styles/privacyPolicy/PrinciplesSecond.css";
import principleImg from "../../Assets/Images/termsAndConditions/technical support 1.svg";

const PrinciplesSecond = () => {
  return (
    <div className="principlessecond-main">
      <div className="principlessecond-content">
        <div className="principlessecond-img">
          <img src={principleImg} alt="principleImg" className="t-img" />
        </div>
        <div className="principlessecond-text">
          {/* <p className="heading">Exploring the Principles</p> */}
          <p className="subheading">
            Exploring the Principles and{" "}
            <span>Benefits of Homeopathic Medicines</span>
          </p>
          <p>
            Shipping Costs: Shipping costs associated with returns or refunds
            are the responsibility of the buyer, unless otherwise specified by
            us.
          </p>
          <p>
            Refund Processing Time: Refunds will be processed within 7-10
            business days of receiving the returned item and verifying its
            condition.
          </p>
      
          <p>
            Product Condition: Returned items must be in their original
            packaging and in the same condition as when they were received.
          </p>
          <p>
            By using our website, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions. If
            you do not agree with any part of these terms, please do not use our
            website. We reserve the right to modify or update these Terms and
            Conditions at any time without prior notice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrinciplesSecond;
