import React from "react";
import "../../styles/layout/FloatingIcon.css";
import { CiShoppingCart } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function FloatingIcon() {
    const navigate = useNavigate();
    const location = useLocation();

    const cart = useSelector((state) => state.newCart.newCart);

    // Check if the user is on the cart page
    const isCartPage = location.pathname === "/cart";
    const isSingleProductPage = location.pathname.startsWith("/singleproduct");

    if (isCartPage || isSingleProductPage) {
        return null;
    }

    return (
        <div id="floating_icon_div" onClick={() => navigate("/cart")}>
            <span id="floating_icon_div_num">{cart.length}</span>
            <CiShoppingCart id="floating_icon" />
        </div>
    );
}

export default FloatingIcon;
