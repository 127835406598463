import React from "react";
import "../../styles/SingleProduct/SinglePageTerms.css";
import g1 from "../../Assets/Images/singleProduct/Frame.svg";
import { Fade} from "react-awesome-reveal";

const SinglePageTerms = () => {
    return (
        <div className="section3main">
            <div className="section3content">
                <div className="section3img">
                    <Fade direction="left">
                        <img src={g1}  alt=""/>
                    </Fade>
                </div>
                <div className="section3text">
                    <Fade direction="right">
                        <h1>
                            <span>Terms</span> & Conditions
                        </h1>
                        <p>
                            Homeopathic products have several uses and should be
                            taken on the basis of symptom similarity. Results
                            may vary depending upon the conditions.
                        </p>
                    </Fade>

                    {/* <Zoom>
                        <button className="section3Button">View More</button>
                    </Zoom> */}
                </div>
            </div>
        </div>
    );
};

export default SinglePageTerms;
