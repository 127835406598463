import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchQuery: "",
  filter: false,
  loading: true,
};

const searchQuerySlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    clearSearchQuery(state) {
      state.searchQuery = "";
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setLoadingQuery(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setSearchQuery, clearSearchQuery, setFilter, setLoadingQuery } =
  searchQuerySlice.actions;
export const selectedQuery = (state) => state.searchQuery.searchQuery;
export const filterQuery = (state) => state?.searchQuery?.filter;
export const loadingQuery = (state) => state?.searchQuery?.loading;
export default searchQuerySlice.reducer;
