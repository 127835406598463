import React, { useEffect, useState } from "react";
import Hero from "../components/SingleProduct/Hero"
import Section1 from "../components/SingleProduct/Section1";
import SinglePageUseFul from "../components/SingleProduct/SinglePageUseFul";
import SinglePageTerms from "../components/SingleProduct/SinglePageTerms";
import SinglePageFAQ from "../components/SingleProduct/SinglePageFAQ";
import SinglePageRating from "../components/SingleProduct/SinglePageRating";
import { useParams } from "react-router-dom";
import axios from "axios";
import { singleProductApi } from "../Constents/apiAxios";
import Loader from "../components/loader/Loader";
import { useSelector } from "react-redux";
import { getCurrentSizePotency } from "../store/productsData/productsData";



const SingleProduct = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { productId } = useParams();
    const {size, potency} = useSelector(getCurrentSizePotency)
    const [productDatas, setProductDatas] = useState(null);
    
    // console.log(productDatas);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const response = await axios.get(
                    `${singleProductApi}${productId}`
                );
                console.log("response:::", response.data)
                setProductDatas(response?.data);
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        };

        fetchProductData();
    }, [productId]);


    if (!productDatas) {
        return <Loader/>
    }

    return (
        <>
            {productDatas && 
                <>
                    <Hero productDatas={productDatas} productSize={size} productPotency={potency}/>
                    <Section1 medInfo={productDatas} />
                    <SinglePageUseFul
                        usefulData={
                            productDatas?.productDetail?.categoryByDisease
                        }
                    />
                    <SinglePageTerms />
                    {/* <SinglePageProducts medicineData={medicineData} /> */}
                    <SinglePageFAQ items={productDatas} />
                    <SinglePageRating ratingData={productDatas}/>
                </>
            }
        </>
    );
};

export default SingleProduct
