import React from "react";
import "../../styles/AboutUs/Founder.css";
import img from "../../Assets/Images/About Us/Untitled-1 1.png";
import { Bounce, Fade, Zoom } from "react-awesome-reveal";

const Founder = () => {
  return (
    <div className="about-founder">
      <div className="about-founder-left">
        <Bounce>
          <h1>About Founder</h1>
        </Bounce>
        <Zoom>
          <h3>
            <span className="secondaryTextColor">WORDS FROM </span>
            <span className="primaryTextColor">Dr. NC. Pandey.</span>
          </h3>
        </Zoom>
        <Fade direction="down">
          <p>
            Homeopathy operates on the premise of stimulating the body's innate
            healing abilities, addressing the root cause of ailments rather than
            merely suppressing symptoms. Unlike allopathic medicine, which often
            relies on symptom management through pharmaceutical intervention,
            homeopathy aims to enhance the body's natural defence mechanisms,
            fostering long-term wellness and resilience. By utilising highly
            diluted natural substances tailored to each individual's specific
            needs, homeopathy offers a gentle yet potent approach to healing,
            free from the harsh side effects commonly associated with
            conventional treatments. While allopathy may provide immediate
            relief, it often fosters dependency on medications, leading to a
            cycle of reliance on external interventions. In contrast, homeopathy
            empowers patients to take an active role in their health, promoting
            self-healing and holistic well-being.
          </p>
        </Fade>
      </div>
      <div className="about-founder-right">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default Founder;
