import React, { useEffect } from 'react';
import Hero from '../components/termsAndConditions/Hero'
import Principles from "../components/termsAndConditions/Principles";
import ExplorePrinciple from "../components/termsAndConditions/ExplorePrinciple";
import PrinciplesSecond from "../components/termsAndConditions/PrinciplesSecond";
// import PrinciplesLast from "../components/termsAndConditions/PrinciplesLast";
// import ExplorePrincipleC from "../components/termsAndConditions/ExplorePrincipleC";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <Hero />
      <Principles/>
      <ExplorePrinciple/>
      <PrinciplesSecond/>
      {/* <ExplorePrincipleC /> */}
      {/* <PrinciplesLast/> */}
    </>
  )
}

export default TermsAndConditions