import React from "react";
import "../../styles/privacyPolicy/Hero.css";
import heroimg from "../../Assets/Images/termsAndConditions/Group 705.svg";

const Hero = () => {
  return (
    <div className="privacyHero-main">
      <div className="privacy-content">
        <div className="hero-img">
          <img src={heroimg} alt="heroimg" className="tcimg"/>
        </div>
        <div className="hero-content">
          <h1>
            <span>TERMS & CONDITIONS</span>
          </h1>
          {/* <p>
          Product Usage: By accessing and using our website, you agree to abide by these Terms and Conditions. Any violation of these terms may result in the termination of your access to our services.
          </p> */}
         
          {/* <button>Read More</button> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;