import { Fade, Zoom } from "react-awesome-reveal";
import img from "../../Assets/Images/About Us/Asset 4 1.svg";
import "../../styles/AboutUs/Hero.css";
function Hero() {
  return (
    <div className="about-hero">
      <div className="about-hero-left">
        <Fade direction="left">
          <div className="About-Us-Exploring-Principles">
            <h1>
              <span className="secondaryTextColor">
                Exploring the Principles and{" "}
              </span>
              <span className="primaryTextColor">
                Benefits of Homeopathic Medicines
              </span>
            </h1>
          </div>
        </Fade>
        {/* image to show only mobile devices */}
        <div className="mobileview">
          <img src={img} alt="" />
        </div>
        <Zoom>
          <div className="about-us-secondHeading-Clinic-esta">
            <h3>
              <span className="secondaryTextColor">
                HOW THE FIRST HOMEOPATHY CLINIC ESTABLISHED IN
              </span>
              <span className="primaryTextColor"> KUMAUN.</span>
            </h3>
          </div>
        </Zoom>
        <Fade direction="down">
          <p>
            In 1996, Dr. NC Pandey embarked on a visionary journey to empower
            his community in Kumaon to combat diseases through the gentle yet
            powerful principles of homeopathy. Started as a wholesaler and
            consultation Dr. Pandey's dream took root, igniting a movement of
            health and healing across the region. Over the years, his dedication
          </p>
          <p>
            and expertise have transformed countless lives, offering natural
            alternatives and personalized care to those in need. Through
            unwavering perseverance and a deep-seated belief in the efficacy of
            homeopathy, Dr. Pandey's dream continues to flourish, bringing hope
            and vitality to individuals and families throughout Haldwani
          </p>
        </Fade>
      </div>
      <div className="about-hero-right">
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default Hero;
