import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../styles/medicalTest/MedicalTestService.css";
import { useDispatch, useSelector } from "react-redux";
import {
  openProcessCard,
  closeProcessCard,
} from "../../store/labSlice/processCard";
import Loader from "../loader/Loader";
import { setLabForm } from "../../store/CartSlice/CartSlice";
import axios from "axios";
import { getService } from "../../Constents/apiAxios";
import iconLab from "../../Assets/Images/medicalTest/Frame.png"
import ProcessCard from "./Book_process/Process_card";
import Input from "../ui/Input";


function MedicalServices() {
  const dispatch = useDispatch();
  const [showCard, setShowCard] = useState(false);
  const processCard = useSelector((state) => state.processCard?.processCard);
  const loader = useSelector((state) => state.labBooking.status);
  const modalRef = useRef(null);
  const [service, setService] = useState();
  const [allservice, setAllService] = useState([]);
  const [loading, setLoading] = useState(false);
const [searchTerm, setSearchTerm] = useState("");
  const openProcessCardFunction = (item) => {
    dispatch(openProcessCard(true));
    setService(item?.serviceName);
    localStorage.setItem("labSetService", JSON.stringify(item?.serviceName));
  };
  // console.log(service);

  const toggleBtn = () => {
    setShowCard(!showCard);
  };

  useEffect(() => {
    if (processCard) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [processCard]);

  // Close modal when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(closeProcessCard(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);
  useEffect(() => {
    getServiceApi();
  }, [searchTerm]);

  const getServiceApi = async () => {
    setLoading(true);
    try {
      const response1 = await axios.get(`${getService}?query=${searchTerm}`);
      if (response1?.data?.allCategory) {
        setAllService(response1?.data?.allCategory);
        setLoading(false);
      } else {
        setAllService([]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleSearch = useCallback(
    debounce((e) => {
      setSearchTerm(e.target.value);
    }, 500), // Adjust the delay as needed
    []
  );


  return (
    <>
      {loading && <Loader />}
      

      <section className="medical_test_services">
        <div className="medical_test_head">
          {/* <h3>Main Features</h3> */}
          <h2>Our Main Services</h2>
          {/* <input style={{padding:"10px"}} placeholder="Search here.." onChange={handleSearch} type="search" name="" id="" /> */}
          <Input value={searchTerm} onChange={handleSearch}/>
        </div>
      {(!allservice || !allservice?.length > 0) && <h4 style={{color:"white",textAlign:"center"}}>No Services avialable...</h4> }

        <div
          className={
            showCard ? "medical_test_content expanded" : "medical_test_content"
          }
        >
          {allservice
            .slice(0, showCard ? allservice.length : 5)
            .map((item, index) => (
              <div className="medical_test_card" key={index}>
                <div
                  className={
                    showCard ? "test_card_item_expend" : "test_card_item"
                  }
                >
                  <div className="medical_test_card_img">
                    <img
                      src={iconLab}
                      alt="error"
                      style={{ height: "40px", width: "40px" }}
                    />
                  </div>
                  <div>
                    <p>{item?.serviceName}</p>
                  </div>
                </div>
                <div className="test_card_hide">
                  {/* <p>Price = &#8377; {item?.price} </p> */}
                  <button
                    onClick={() => {
                      openProcessCardFunction(item);
                      dispatch(setLabForm(true));
                    }}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            ))}
        </div>
        {!showCard ? (
          <div>
            <button className="medical_service_button" onClick={toggleBtn}>
              See All Services
            </button>
          </div>
        ) : (
          <div>
            <button className="medical_service_button" onClick={toggleBtn}>
              See Less Services
            </button>
          </div>
        )}

        {/* Overlay to cover the screen when modal is open */}
        {processCard && (
          <div className="overlayCss">
            {loader === "loading" && <Loader />}
            <div className="process_card_overlay" ref={modalRef}>
              <ProcessCard service={service} />
            </div>
          </div>
        )}
      </section>



    </>
  );
}

export default MedicalServices;
