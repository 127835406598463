import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { singleLabUrl } from "../../Constents/apiAxios"

// Define the initial state
const initialState = {
    lab: null,
    status: 'idle', // or 'loading', 'succeeded', 'failed'
    error: null,
};

// Define the thunk for fetching a single lab
export const fetchSingleLab = createAsyncThunk(
    'lab/fetchSingleLab',
    async (labId, thunkAPI) => {
        try {
            const response = await axios.get(`${singleLabUrl}/${labId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create the lab slice for fetching a single lab
const labSlice = createSlice({
    name: 'lab',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleLab.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSingleLab.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.lab = action.payload;
            })
            .addCase(fetchSingleLab.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            });
    },
});

// Selector to get the single lab from the state
export const selectSingleLab = (state) => state.singleLab.lab;

export default labSlice.reducer;
