import "../../styles/ApointmentForm/OfflineForm.css";
import React, { useState } from "react";
import "../../styles/ApointmentForm/ApointmentForm.css";
import saveIcon from "../../Assets/Images/logo/Vector.png";
import { CgArrowLongRight } from "react-icons/cg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {isBefore, isSameDay, isSaturday } from "date-fns";
import Loader from "../loader/Loader";
import { repeatMedicine, selectLoadingOnline } from "../../store/enquirySlice/onlineConsulatation";

// time slots
const timeSlots = [
    "11 AM",
    "11:30 AM",
    "12 AM",
    "12:30 PM",
    "1 PM",
    "1:30 PM",
    "2 PM",
    "2:30 PM",
    "3 PM",
    "3:30 PM",
    "4 PM",
    "4:30 PM",
    "5 PM",
    "5:30 PM",
    "6 PM",
    "6:30 PM",
    "7 PM",
    "7:30 PM",
];

function formatDateDef(isoString) {
    const date = new Date(isoString);

    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}

function OfflineForm({ setOtherModalOpen }) {
    const loader = useSelector(selectLoadingOnline)
    const dispatch = useDispatch();
    const [selectedTime, setSelectedTime] = useState("");
    const [formStep, setFormStep] = useState(1);
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNo: "",
        registrationNo: "",
        address: "",
        pinCode: "",
        date: formatDateDef(new Date()),
        time: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === "name" || name === "address") {
            newValue = value.replace(/\b\w/g, char => char.toUpperCase());
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };
    const handleDateChange = (value) => {
        // Format the date to dd/mm/yyyy format
        const formattedDate = formatDate(value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            date: formattedDate, // Update the selected date directly without formatting
        }));
    };

    const formatDate = (date) => {
        // Get the components of the date
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Return the formatted date in dd/mm/yyyy format
        return `${day}/${month}/${year}`;
    };

    const handleTimeSelect = (time) => {
        setSelectedTime(time);
        setFormData((prevFormData) => ({
            ...prevFormData,
            time: time,
        }));
    };

    const handleModal = () => {
        setOtherModalOpen(false);
    };

    const handleNextBtn = () => {
        if (validateForm()) {
            setFormStep(formStep + 1);
        }
    };

    const validateForm = () => {




        // Validation for name
        if (formData.name.trim() === "") {
            toast.error("Please enter your name.");
            return false;
        }

        // Validation for email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/;
        if (!formData.email.trim().match(emailRegex)) {
            toast.error("Please enter a valid email address.");
            return false;
        }

        // Validation for mobile number
        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phoneNo.trim().match(phoneRegex)) {
            toast.error("Please enter a valid 10-digit mobile number.");
            return false;
        }

        if (!formData.registrationNo) {
            toast.error('Please enter a valid registration')
        }




        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();



        // Validation for name
        if (formData.name.trim() === "") {
            toast.error("Please enter your name.");
            return false;
        }

        // Validation for email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/;
        if (!formData.email.trim().match(emailRegex)) {
            toast.error("Email must be a valid Gmail or Yahoo address");
            return false;
        }

        // Validation for mobile number
        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phoneNo.trim().match(phoneRegex)) {
            toast.error("Please enter a valid 10-digit mobile number.");
            return false;
        }



        const requiredFields = ["name", "email", "phoneNo", "registrationNo", "address", "pinCode"];

        for (const field of requiredFields) {
            if (!formData[field] || (typeof formData[field] !== 'string') || formData[field].trim() === "") {
                toast.error(`Please enter your ${field === "phoneNo" ? "Phone Number" : field}.`);
                return false;
            }
        }

        if (formStep === 2) {
            const requiredFieldsStep2 = ["date", "time"];

            for (const field of requiredFieldsStep2) {
                if (!formData[field] || (typeof formData[field] !== 'string') || formData[field].trim() === "") {
                    toast.error(`Please enter your ${field}.`);
                    return false;
                }
            }
        }

        const response = await dispatch(repeatMedicine(formData));
        // console.log("consultation response ::::", response)

        if (response?.payload?.status === "success") {
            setFormData({
                name: "",
                email: "",
                phoneNo: "",
                registrationNo: "",
                address: "",
                pinCode: "",
                date: new Date(), // Reset to current date
                time: "",
            });
            toast.success(`${response?.payload?.message}`);
            setOtherModalOpen(false);
        } else if (response?.payload?.status === "failed") {
            toast.error(`${response?.payload?.message}`);
        }
    };

    const tileClassName = ({ date }) => {
        // Check if the current date is the selected date
        if (isSameDay(date, formData.date)) {
            // Return the class name for the selected date
            return "selected-date";
        }
        // If the current date is not selected, return null
        return null;
    };
    const tileDisabled = ({ date, view }) => {
        // Check if the date is in the past
        if (isBefore(date, new Date())) {
            return true;
        }

        // Check if the day is Saturday
        if (isSaturday(date)) {
            return true;
        }

        // If the date is not in the past and not a Saturday, return false
        return false;
    };

    return (
        <section className="book_popup_container">
            {loader && <Loader />}
            <div className="popup_header">
                <img src={saveIcon} alt="" />
                <h3>This form is only for offline patients</h3>
            </div>
            <div className="popup_content">
                {formStep === 1 && (
                    <>
                        <form
                            action=""
                            className="popup_first_form"
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <label htmlFor="">
                                    Name:{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Type Your Name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    Email Id:{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Enter Your Active Email Id"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required

                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    Mobile No:{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="phoneNo"
                                    placeholder="Start Typing Whatsapp Number"
                                    value={formData.phoneNo}
                                    onChange={handleInputChange}
                                    maxLength={10}
                                    required

                                />
                            </div>

                            <div>
                                <label htmlFor="">
                                    Reg no:
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="registrationNo"
                                    placeholder="Registration Number"
                                    value={formData.registrationNo}
                                    onChange={handleInputChange}
                                    required

                                />
                            </div>

                            <div>
                                <label htmlFor="">
                                    Address:
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    value={formData.address}
                                    required

                                    onChange={handleInputChange}
                                />
                            </div>

                            <div>
                                <label htmlFor="">
                                    Pin code:
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="pinCode"
                                    placeholder="Pin code"
                                    value={formData.pinCode}
                                    onChange={handleInputChange}
                                    maxLength={10}
                                    required

                                />
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="offline_input"
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <label htmlFor="" id="offline_label">
                                    Do you want to consult before repeating your
                                    medicine{" "}
                                </label>
                            </div>
                            <div className="popup_first_div_btn">
                                <h3 onClick={handleModal}>Cancel</h3>
                                <div className="">
                                    {isChecked ? (
                                        <button onClick={handleNextBtn}>
                                            Next{" "}
                                            <CgArrowLongRight className="popup_arrow" />
                                        </button>
                                    ) : (
                                        <button onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </>
                )}

                {formStep === 2 && (
                    <>
                        <form
                            action=""
                            className="popup_second_form"
                            onSubmit={handleSubmit}
                        >
                            <div className="second_form_content">
                                <div className="popup_second_form_div1">
                                    <label htmlFor="">
                                        Select Date
                                        <span style={{ color: "red" }}>
                                            *
                                        </span>{" "}
                                    </label>
                                    <div>
                                        <Calendar
                                            value={formData.date}
                                            onChange={handleDateChange}
                                            className="customCalendar"
                                            tileClassName={tileClassName} // Apply custom tile class
                                            tileDisabled={tileDisabled}
                                            onClickDay={(date) => {
                                                if (
                                                    isBefore(
                                                        date,
                                                        new Date()
                                                    ) ||
                                                    isSaturday(date)
                                                ) {
                                                    toast.error(
                                                        "You cannot select this date."
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="popup_second_form_div2">
                                    <label htmlFor="">
                                        Select Time{" "}
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div>
                                        <p>Select Available Time </p>
                                    </div>
                                    <div className="time_slot_div">
                                        {timeSlots.map((slot, index) => (
                                            <div
                                                key={index}
                                                className={`time_div ${selectedTime === slot
                                                    ? "selected-time"
                                                    : ""
                                                    }`}
                                                onClick={() =>
                                                    handleTimeSelect(slot)
                                                }
                                            >
                                                {slot}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="popup_second_form_btn">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </section>
    );
}

export default OfflineForm;
