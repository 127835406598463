import React from "react";
import "../../styles/SingleProduct/Section1.css";
import { Fade, Zoom } from "react-awesome-reveal";
import img from "../../Assets/Images/singleProduct/img/Group 661.svg";

const Section1 = ({ medInfo }) => {
    return (
        <div className="section1-main">
            <div className="section1content">
                <div className="section1text">
                    <Fade direction="left">
                        <h3 className="product-title">
                            {medInfo.productDetail.name}
                        </h3>
                        <h5 className="common-name">
                            {medInfo.productDetail.pDheading}
                        </h5>

                        {/*////// medicine info print here ////// */}

                        <ul className="symptoms-list">
                            {medInfo.productDetail.pDdescription.map(
                                (desc, i) => (
                                    <li style={{ listStyle: "disc" }} key={i}>
                                        {desc.split("\n").map((line, j) => (
                                            <span key={j}>
                                                 {line}
                                                <br />
                                            </span>
                                        ))}
                                    </li>
                                )
                            )}
                        </ul>
                    </Fade>
                </div>
                <div className="section1img">
                    <Zoom>
                        <img
                            src={img}
                            alt="Product"
                            className="product-image"
                        />
                    </Zoom>
                </div>
            </div>
        </div>
    );
};

export default Section1;
