import React from "react";
import "../../styles/privacyPolicy/PrinciplesSecond.css";
import principleImg from "../../Assets/Images/privacyPolicy/Frame1.png";

const PrinciplesSecond = () => {
  return (
    <div className="principlessecond-main">
      <div className="principlessecond-content-p">
        <div className="principlessecond-img">
          <img src={principleImg} alt="principleImg" className="p-img"/>
        </div>
        <div className="principlessecond-text">
          {/* <p className="heading">Exploring the Principles</p> */}
          <p className="subheading">
            <span>Security:</span>
          </p>
          <p>
            We take reasonable measures to protect the security of your personal
            information and prevent unauthorised access or disclosure. However,
            please be aware that no method of transmission over the internet or
            electronic storage is 100% secure.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrinciplesSecond;
