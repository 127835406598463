import React from "react";
import "../../styles/privacyPolicy/Principles.css";
import principleImg from "../../Assets/Images/privacyPolicy/Frame.png";

const Principles = () => {
  return (
    <div className="principles-main">
      <div className="principles-content">
        <div className="principles-text">
          <p className="subheading">
            <span> Information We Collect</span>
          </p>
          <p>
            We collect personal information that you provide directly to us when
            you interact with the Website. The types of personal information we
            may collect include:
          </p>
          <ul className="ul-style">
            <li clas className="li-style">
              Contact information such as your name, email address, and phone
              number
            </li>
            <li clas className="li-style">
              Information you provide when you communicate with us or request
              information about our services
            </li>
            <li clas className="li-style">
              Information about your use of the Website, such as your IP
              address, browser type, and operating system.
            </li>
          </ul>
        </div>
        <div className="principles-img">
          <img src={principleImg} alt="principleImg" />
        </div>
      </div>
    </div>
  );
};

export default Principles;
