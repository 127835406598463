import React from 'react';
import "../../../styles/medicalTest/Address.css"
const Address = ({ formData, handleFormChange }) => {
  
    return (
        <div className="address-form">
            <small>Note: We provide home facility in Haldwani city only, For some tests you required to visit lab</small>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder="Home No/Street/Landmark"
                    value={formData?.address?.addressLine1}
                    onChange={(e) => handleFormChange('address', { ...formData?.address, addressLine1: e.target.value })}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder="Locality"
                    value={formData?.address?.addressLine2}
                    onChange={(e) => handleFormChange('address', { ...formData?.address, addressLine2: e.target.value })}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder="City"
                    value={formData?.address?.city}
                    onChange={(e) => handleFormChange('address', { ...formData?.address, city: e.target.value })}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder="State"
                    value={formData?.address?.state}
                    onChange={(e) => handleFormChange('address', { ...formData?.address, state: e.target.value })}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder="Pincode"
                    value={formData?.address?.pincode}
                    onChange={(e) => handleFormChange('address', { ...formData?.address, pincode: e.target.value })}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    required
                    placeholder="Alternate Mobile Number"
                    value={formData?.address?.alternateMobile}
                    onChange={(e) => handleFormChange('address', { ...formData?.address, alternateMobile: e.target.value })}
                />
            </div>
        </div>
    );
}

export default Address;
