// ContactSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { contactEnquiry } from '../../Constents/apiAxios';
// Define the initial state
const initialState = {
    loading: false,
    error: null,
};

// Define the thunk action creator to send an enquiry
export const sendEnquiry = createAsyncThunk(
    'contact/sendEnquiry',
    async (formData) => {
        try {
            const response = await axios.post(contactEnquiry, formData);
            return response.data;
        } catch (error) {
            throw new Error('Failed to send enquiry');
        }
    }
);

// Create the contact slice
const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendEnquiry.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendEnquiry.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(sendEnquiry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the reducer
export default contactSlice.reducer;

// Selectors
export const selectLoading = (state) => state.contact.loading;
export const selectError = (state) => state.contact.error;
