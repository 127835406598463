import React, { useState, useEffect, useRef } from "react";
import "../../styles/ProfileScreen/Order.css";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import OrderPopUp from "./OrderPopUp";
import Returnorder from "./Returnorder";
import axios from "axios";
import {
    orderCancelApi,
    cancelLabBookingApi,
    cancelConsultancyApi,
} from "../../Constents/apiAxios";

import Loader from "../../components/loader/Loader";
import {
    addressToUpdate,
    deleteUserAddress,
    openModalAddress,
    selectUserAddresses,
    userAddressLoading,
} from "../../store/signupSlice/userAddress";
import { selectReturnOrderLoading } from "../../store/newBuyNow/returnOrder";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import {
    fetchOrderHistory,
    fetchLabHistory,
    fetchConsultancy,
    setOrderPage,
    setLabPage,
    setConsultancyPage,
} from "../../store/OrderSlice";
import CommonPopUp from "./CommonPopUp";

function Order({ isModelOpen, setIsModelOpen }) {
    const returnLoading = useSelector(selectReturnOrderLoading);
    const addressData = useSelector(selectUserAddresses);
    const loading2 = useSelector(userAddressLoading);
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isMobileView, setIsMobileView] = useState(false);
    const [returnmodal, setReturnmodal] = useState(false);
    const [selectedOrderIndex, setSelectedOrderIndex] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [ratingPopUp, setRatingPopUp] = useState(false);
    const orderRef = useRef();
    const [openPopUp, SetPopUpOpen] = useState(false);
    const [selectHisData, setSelectHisData] = useState(null);

    const handleOpenCommonPopUp = (index) => {
        setSelectHisData(index);
        SetPopUpOpen(!openPopUp);
    };

    const orderData = useSelector((state) => state?.orderHistory?.orderHistory);
    const labBookingData = useSelector(
        (state) => state?.orderHistory?.labBookingHistory
    );
    const consultancyData = useSelector(
        (state) => state?.orderHistory?.consultancyHistory
    );

    const orderPage = useSelector((state) => state.orderHistory.orderPage);
    const orderTotalPages = useSelector(
        (state) => state.orderHistory.orderTotalPages
    );
    const labPage = useSelector((state) => state.orderHistory.labPage);
    const labTotalPages = useSelector(
        (state) => state.orderHistory.labTotalPages
    );
    const consultancyPage = useSelector(
        (state) => state.orderHistory.consultancyPage
    );
    const consultancyTotalPages = useSelector(
        (state) => state.orderHistory.consultancyTotalPages
    );
    const loading = useSelector((state) => state.orderHistory.loading);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const handleViewDetails = (index, value) => {
        setSelectedOrderIndex(index);
        setSelectedOrder(index);
        setRatingPopUp(value);
        setIsModelOpen(true);
    };
    const handleReturn = (order) => {
        setSelectedOrder(order);
        setReturnmodal(true);
    };



    useEffect(() => {
        const handleClickOutSide = (event) => {
            if (orderRef.current && !orderRef.current.contains(event.target)) {
                setIsModelOpen(false);
                SetPopUpOpen(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutSide);
        return () => {
            document.removeEventListener("mousedown", handleClickOutSide);
        };
    }, []);

    // update the address
    const handleUpdateAddress = (address) => {
        dispatch(openModalAddress());
        dispatch(addressToUpdate(address));
    };

    // Function to handle address deletion
    const handleDeleteAddress = async (address) => {
        try {
            const response = await dispatch(deleteUserAddress(address._id));
            // Check if the response status is success
            if (response?.payload?.status === "success") {
                toast.success(response?.payload?.message);
            } else {
                // This block may never be reached because thunk will throw an error for rejected case
                toast.error("Failed to delete address");
            }
        } catch (error) {
            // Handle error case
            toast.error(error.message);
        }
    };

    //function for render button conditionaly here

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const convertToIST = (utcDate) => {
        const date = new Date(utcDate);
        const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
        return new Date(date.getTime() + istOffset);
    };

    const renderButton = (order) => {
        const orderStatus = order?.orderstatus;
        const deliveryDateUTC = order?.deliveredDate;
        const deliveryDateIST = convertToIST(deliveryDateUTC);
        const elapsedTime = (currentTime - deliveryDateIST) / (1000 * 60 * 60); // in hours // here need to change add 60

        if (orderStatus !== "delivered" && orderStatus !== "cancelled") {
            return (
                <div
                    onClick={() => handleCancel(order._id)}
                    className="order_history_view_more"
                >
                    Cancel
                </div>
            );
        } else if (elapsedTime <= 48) {
            // here add 48 for exact time
            return (
                <div
                    onClick={() => handleReturn(order)}
                    className="order_history_view_more"
                >
                    Return
                </div>
            );
        } else if (orderStatus !== "cancelled") {
        } else if (orderStatus !== "cancelled") {
            return (
                <div
                    onClick={() => handleViewDetails(order, true)}
                    className="order_history_view_more"
                >
                    Review
                </div>
            );
        }
    };

    const handleCancel = async (orderId) => {
        try {
            const token = localStorage.getItem("authToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const response = await axios.post(
                `${orderCancelApi}/${orderId}`,
                {},
                { headers }
            );

            if (response.data.status === "success") {
                toast.success("Order cancelled successfully");
                dispatch(fetchOrderHistory());
            } else {
                toast.error("Failed to cancel order");
            }
        } catch (error) {
            toast.error("Error cancelling order: " + error.message);
        }
    };

    useEffect(() => {
        if (activeIndex === 1) {
            dispatch(fetchOrderHistory());
        } else if (activeIndex === 2) {
            dispatch(fetchLabHistory());
        } else if (activeIndex === 3) {
            dispatch(fetchConsultancy());
        }
    }, [activeIndex, orderPage, labPage, consultancyPage, dispatch]);

    const handlePageIncrease = () => {
        if (activeIndex === 1 && orderPage < orderTotalPages) {
            dispatch(setOrderPage(orderPage + 1));
        } else if (activeIndex === 2 && labPage < labTotalPages) {
            dispatch(setLabPage(labPage + 1));
        } else if (
            activeIndex === 3 &&
            consultancyPage < consultancyTotalPages
        ) {
            dispatch(setConsultancyPage(consultancyPage + 1));
        }
    };

    const handlePageDecrease = () => {
        if (activeIndex === 1 && orderPage > 1) {
            dispatch(setOrderPage(orderPage - 1));
        } else if (activeIndex === 2 && labPage > 1) {
            dispatch(setLabPage(labPage - 1));
        } else if (activeIndex === 3 && consultancyPage > 1) {
            dispatch(setConsultancyPage(consultancyPage - 1));
        }
    };

    //lab cancel
    const handleLabCancel = async (labId) => {
        try {
            const token = localStorage.getItem("authToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const payload = {
                completeOrder: false,
                cancelOrder: true,
            };
            const response = await axios.put(
                `${cancelLabBookingApi}/${labId}`,
                payload,
                { headers }
            );

            if (response.data.status === "success") {
                toast.success("Lab Booking cancelled successfully");
                dispatch(fetchLabHistory());
            } else {
                toast.error("Failed to cancel Lab Booking");
            }
        } catch (error) {
            toast.error("Error cancelling Lab Booking: " + error.message);
        }
    };

    // consultancy cancel handle
    const handleConsultancyCancel = async (labId) => {
        try {
            const token = localStorage.getItem("authToken");
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const payload = {
                bookingComplete: false,
                bookingCancel: true,
            };
            const response = await axios.put(
                `${cancelConsultancyApi}/${labId}`,
                payload,
                { headers }
            );

            if (response.data.status === "success") {
                toast.success("Consultancy Booking cancelled successfully");
                dispatch(fetchConsultancy());
            } else {
                toast.error("Failed to cancel Consultancy Booking ");
            }
        } catch (error) {
            toast.error(
                "Error cancelling Consultancy Booking : " + error.message
            );
        }
    };



    useEffect(() => {
        if (openPopUp) {
            document.body.style.overflow = "hidden";

        } else {
            document.body.style.overflow = "auto";
        }

        // Clean up on component unmount
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [openPopUp]);

    return (
        <>
            {(returnLoading || loading || loading2) && <Loader />}

            <section className="profile_order_container">
                <div className="order_header">
                    <div className="order_btn">
                        <button
                            onClick={() => handleClick(0)}
                            className={
                                activeIndex === 0 ? "activate" : "nonActivate"
                            }
                        >
                            Address
                        </button>
                        <button
                            onClick={() => handleClick(1)}
                            className={
                                activeIndex === 1 ? "activate" : "nonActivate"
                            }
                        >
                            Order History
                        </button>
                        <button
                            onClick={() => handleClick(2)}
                            className={
                                activeIndex === 2 ? "activate" : "nonActivate"
                            }
                        >
                            Lab Booking History
                        </button>
                        <button
                            onClick={() => handleClick(3)}
                            className={
                                activeIndex === 3 ? "activate" : "nonActivate"
                            }
                        >
                            Consultancy Booking History
                        </button>
                    </div>
                </div>

                <div className="address_details">
                    {activeIndex === 0 && (
                        <div className="address_container">
                            {addressData?.map((address, index) => (
                                <div key={index} className="address_item">
                                    <p className="address_item_fullName user-address-font">
                                        <span>{address?.fullName}</span>
                                    </p>
                                    <FiEdit
                                        className="editButton"
                                        onClick={() => {
                                            handleUpdateAddress(address);
                                        }}
                                    />
                                    <RxCross2
                                        className="deleteIcon"
                                        onClick={() => {
                                            handleDeleteAddress(address);
                                        }}
                                    />

                                    {!isMobileView ? (
                                        <>
                                            <p>{address?.addressLine1}</p>
                                            <p>{address?.addressLine2}</p>

                                            <p>
                                                {address?.city}
                                                <span>,</span> {address?.state}
                                            </p>

                                            <p>{address?.country}</p>
                                            <p>
                                                <span className="user-address-font">
                                                    Email:
                                                </span>{" "}
                                                {address?.email}
                                            </p>
                                            <p>
                                                <span className="user-address-font">
                                                    Mobile No:
                                                </span>{" "}
                                                {address?.mobileNo}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>{address?.addressLine1}</p>
                                            <p>{address?.addressLine2}</p>
                                            <p>
                                                {address?.city}
                                                <span>,</span> {address?.state}
                                            </p>
                                            <p>{address?.country}</p>
                                            <p>
                                                <span>Email:</span>&nbsp;
                                                {address?.email}
                                            </p>
                                            <p>
                                                <span>Mobile No:</span>&nbsp;
                                                {address?.mobileNo}
                                            </p>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {activeIndex === 1 && (
                        <>
                            <div className="address_container1">
                                <>
                                    {orderData?.productOrders?.map((order, index) => (
                                        <div
                                            key={index}
                                            className="address_item_1"
                                        >
                                            <div className="address_item_1_div">
                                                <p className="address_item_1_div_p">
                                                    Order ID:&nbsp;
                                                    <span>
                                                        {order.OrderId}
                                                    </span>
                                                </p>

                                                <p>
                                                    Total Price:&nbsp;
                                                    <span>
                                                        {order.finalPrice}
                                                    </span>
                                                </p>

                                                <p>
                                                    Mode of order:&nbsp;
                                                    <span>
                                                        {order.type}
                                                    </span>
                                                </p>

                                                <p>
                                                    Order status:&nbsp;
                                                    <span>
                                                        {order.orderstatus}
                                                    </span>
                                                </p>

                                                <p>
                                                    Number Of Orders:&nbsp;
                                                    <span>
                                                        {
                                                            order.products
                                                                .length
                                                        }
                                                    </span>
                                                </p>
                                            </div>

                                            <div className="btn-box">
                                                <div
                                                    onClick={() =>
                                                        handleViewDetails(
                                                            order,
                                                            false
                                                        )
                                                    }
                                                    className="order_history_view_more"
                                                >
                                                    View Details
                                                </div>
                                                {renderButton(order)}
                                            </div>
                                        </div>
                                    ))}
                                </>
                                <div className="pagination">
                                    <button onClick={handlePageDecrease}>
                                        Prev
                                    </button>
                                    <button>
                                        <span>{orderPage}</span>/
                                        {orderTotalPages}
                                    </button>
                                    <button onClick={handlePageIncrease}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {activeIndex === 2 && (
                    <>
                        <div className="address_container1">
                            <>
                                {labBookingData?.labOrders?.map(
                                    (lab, index) => (
                                        <div
                                            className="address_item_1"
                                            key={index}
                                        >
                                            <div className="address_item_1_div">
                                                <p>
                                                    S.No.: &nbsp;
                                                    <span>{index + 1}</span>
                                                </p>
                                                <p>
                                                    Name:&nbsp;
                                                    <span>
                                                        {lab?.address?.fullName}{" "}
                                                    </span>
                                                </p>
                                                {/* <p>
                                                Mobile No.{" "}
                                                {lab?.address?.mobileNo}
                                            </p> */}
                                                <p>
                                                    Lab Name:&nbsp;
                                                    <span>
                                                        {lab?.lab?.name}
                                                    </span>
                                                </p>
                                                <p>
                                                    Services:&nbsp;
                                                    <span>
                                                        {
                                                            lab?.labservice
                                                                ?.serviceName
                                                        }{" "}
                                                    </span>
                                                </p>
                                                <p>
                                                    Booking Status:&nbsp;
                                                    <span>
                                                        {lab?.cancelOrder ===
                                                            false &&
                                                            lab?.completeOrder ===
                                                            false
                                                            ? "New"
                                                            : lab?.completeOrder ===
                                                                true
                                                                ? "Complete"
                                                                : lab?.cancelOrder ===
                                                                    true
                                                                    ? "Cancelled"
                                                                    : ""}
                                                    </span>
                                                </p>
                                                {/* <p>Cost: {lab?.finalPrice} </p> */}
                                            </div>

                                            <div className="btn-box">
                                                <div
                                                    className="order_history_view_more"
                                                    onClick={() =>
                                                        handleOpenCommonPopUp(
                                                            lab
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </div>
                                                {lab?.cancelOrder !== true &&
                                                    lab?.completeOrder !==
                                                    true && (
                                                        <div
                                                            className="order_history_view_more"
                                                            onClick={() =>
                                                                handleLabCancel(
                                                                    lab._id
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </>
                            <div className="pagination">
                                <button onClick={handlePageDecrease}>
                                    Prev
                                </button>
                                <button>
                                    <span>{labPage}</span>/{labTotalPages}
                                </button>
                                <button onClick={handlePageIncrease}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </>
                )}




                {activeIndex === 3 && (
                    <>
                        <div className="address_container1">
                            <>
                                {consultancyData?.allLeads?.map(
                                    (item, index) => (
                                        <div
                                            className="address_item_1"
                                            key={index}
                                        >
                                            <div className="address_item_1_div">
                                                <p>
                                                    S.No.:&nbsp;
                                                    <span>{index + 1}</span>
                                                </p>
                                                <p>
                                                    Name:&nbsp;
                                                    <span>{item?.name}</span>
                                                </p>

                                                {/* <p>Mobile No. {item?.phoneNo}</p> */}
                                                <p>
                                                    Date:&nbsp;
                                                    <span>{item?.date}</span>
                                                </p>
                                                <p>
                                                    Doctor:&nbsp;
                                                    <span>{item?.doctor}</span>
                                                </p>
                                                <p>
                                                    Booking Status:&nbsp;
                                                    <span>
                                                        {item?.bookingCancel ===
                                                            false &&
                                                            item?.bookingComplete ===
                                                            false
                                                            ? "New"
                                                            : item?.bookingComplete ===
                                                                true
                                                                ? "Complete"
                                                                : item?.bookingCancel ===
                                                                    true
                                                                    ? "Cancelled"
                                                                    : ""}
                                                    </span>
                                                </p>
                                            </div>

                                            <div className="btn-box">
                                                <div
                                                    className="order_history_view_more"
                                                    onClick={() =>
                                                        handleOpenCommonPopUp(
                                                            item
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </div>

                                                {item?.bookingCancel !== true &&
                                                    item?.bookingComplete !==
                                                    true && (
                                                        <div
                                                            className="order_history_view_more"
                                                            onClick={() =>
                                                                handleConsultancyCancel(
                                                                    item?._id
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </>
                            <div className="pagination">
                                <button onClick={handlePageDecrease}>
                                    Prev
                                </button>
                                <button>
                                    <span>{consultancyPage}</span>/
                                    {consultancyTotalPages}
                                </button>
                                <button onClick={handlePageIncrease}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </>
                )}



                {/* ***************** Pop up components *************** */}
                {activeIndex === 1 &&
                    isModelOpen &&
                    selectedOrderIndex !== null && (
                        <OrderPopUp
                            orderData={selectedOrder}
                            ratingPopUp={ratingPopUp}
                            onClose={() => setIsModelOpen(false)}
                        />
                    )}

                {returnmodal && (
                    <Returnorder
                        setReturnmodal={setReturnmodal}
                        selectedOrder={selectedOrder}
                    />
                )}
                {openPopUp && (
                    <CommonPopUp
                        onClose={() => SetPopUpOpen(false)}
                        commonData={selectHisData}
                    />
                )}
            </section>
        </>
    );
}

export default Order;
