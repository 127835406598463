import React from "react";
import "../../styles/Blogs/Hero.css";
import Icon from "../../Assets/Images/blogs/Vector (8).png";

import { Fade } from "react-awesome-reveal";

function Hero() {
    return (
        <>
            <section className="Hero_Container">
                <div className="Hero_Content">
                    <div className="Hero_Left_Div">
                        <Fade direction="left">
                            <h1>
                                EXPLORING THE WORD {""}
                                <span>OF HOMEOPATHY FOR HOLISTIC WELLNESS</span>
                            </h1>
                            <p>
                                Excess anger, worries, sedentary life where
                                people tend to eat and sit on one place,
                                disappointment with life and constant thoughts
                                which desire to be alone are well relieved with
                                the help of Nux vomica.
                            </p>
                        </Fade>
                    </div>

                    <div className="Hero_Right_Div">
                        <div className="hero_right_div_main_section">
                            <div className="image_main_container">
                                <div className="first_section_image">
                                    <div className="card-box-2">
                                        <img src={Icon} alt="icon" />
                                    </div>
                                </div>
                                <div className="image_container"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;
