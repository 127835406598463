import React, { useEffect, useState } from "react";
import "../../styles/LoginSignup/Signup.css";
import PhoneOtpSignup from "./PhoneOtpSignup";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import {
    googleLoginAction,
} from "../../store/actions/logIn/LogInAction";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { completeSignup, sendOtp, setLoginModal, setSignupModal, signupLoading, verifyOtp } from "../../store/signupSlice/signupSlice";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const loading = useSelector(signupLoading)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [step, setStep] = useState(0);

    const [showPassword, setShowPassword] = useState(false);

    const passVisible = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
    });

    // Perform form validation here
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/; // Change here: limit to Gmail and Yahoo
    const phoneNumberRegex = /^\+?\d{10}$/;




    const handleChange = (e) => {
        const { name, value } = e.target;
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);


        if (name === "firstName" || name === "lastName") {

            setFormData({
                ...formData,
                [name]: capitalizedValue,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }


    };

    const handleStep = () => {
        setStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!emailRegex.test(formData.email.toLowerCase())) {
            toast.error("Email must be a valid Gmail or Yahoo address");
            return;
        }

        if (!phoneNumberRegex.test(formData.phone)) {
            toast.error("Invalid phone number format");
            return;
        }

        if (step === 1) {
            console.log("this is step 1");
            console.log("in step one", formData);



            try {
                const response = await dispatch(sendOtp(formData)).unwrap();

                if (response.status === "success") {
                    toast.success(response.message);
                    setStep(2);
                }
            } catch (error) {
                toast.error(error.message);
                console.error(error);
            }
        } else if (step === 2) {
            console.log("this is step 2");
            console.log("in step two", formData);

            try {
                const response = await dispatch(verifyOtp()).unwrap();

                if (response.status === "success") {
                    toast.success(response.message);
                    setStep(3);
                } else if (response.status === "failed") {
                    toast.error(response.message);
                }
            } catch (error) {
                toast.error(error.message);
                console.error(error);
            }
        } else if (step === 3) {
            console.log("this is step 3");
            console.log("in step three", formData);
            if (formData.password.length <= 8 && formData.confirmPassword.length <= 8) {
                toast.error("Password must be more than 8 characters");
                return;
            }
            try {
                const response = await dispatch(completeSignup(formData)).unwrap();

                if (response.status === "success") {
                    toast.success(response.message);
                    console.log(response)
                    if (response.emailMessage?.status === "success") {
                        toast.success(response.emailMessage.message);
                    }
                    dispatch(setLoginModal(false));
                    dispatch(setSignupModal(false));
                } else if (response.status === "failed") {
                    toast.error(response.message);
                }
                console.log(response);
            } catch (error) {
                toast.error(error.message);
                console.error(error);
            }
        }
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {loading ? <Loader /> : <div className="signupSection">

                <div className="signupContainer">
                <RxCross2  className="login_signup_close_btn" onClick={() =>navigate("/")}/>
                    <h1>Welcome</h1>
                    {step === 0 && (
                        <>

                            <div className="googleSignup">
                                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
                                    <GoogleLogin
                                        onSuccess={(credentialResponse) => {
                                            const decoded = jwtDecode(
                                                credentialResponse?.credential
                                            );
                                            console.log(
                                                "log in details",
                                                credentialResponse?.credential
                                            );
                                            dispatch(
                                                googleLoginAction({
                                                    firstName: decoded.given_name,
                                                    lastName: decoded.family_name,
                                                    email: decoded.email,
                                                    picture: decoded.picture,
                                                    gmail: true,
                                                })
                                            );
                                            console.log(decoded);
                                        }}
                                        onError={() => {
                                            console.log("Login Failed");
                                        }}
                                    />
                                </GoogleOAuthProvider>
                            </div>

                            <div className="googleSignup" onClick={handleStep}>
                                <p>Sign Up With Email or Phone</p>
                            </div>
                        </>
                    )}
                    {step > 0 && step <= 3 && (
                        <form onSubmit={handleSubmit} className="formContainer">
                            {step === 1 && (
                                <div className="signupWithEmail">
                                    <div className="signupBorders"></div>
                                    <div>Sign Up With Email or Phone</div>
                                    <div className="signupBorders"></div>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="signupWithEmail">
                                    <div className="signupBorders"></div>
                                    <div>Enter your phone OTP</div>
                                    <div className="signupBorders"></div>
                                </div>
                            )}
                            {step === 3 && (
                                <div className="signupWithEmail">
                                    <div className="signupBorders"></div>
                                    <div>Create your password</div>
                                    <div className="signupBorders"></div>
                                </div>
                            )}

                            <div className="formInputs">
                                {step === 1 && (
                                    <>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            placeholder="Enter Your First Name"
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Enter Your Last Name"
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Enter Your Email Address"
                                        />
                                        <input
                                            type="number"
                                            name="phone"
                                            className="number_input"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder="Enter Your Phone Number"
                                            maxLength={10}
                                            required
                                        />
                                    </>
                                )}
                                {step === 2 && <PhoneOtpSignup />}

                                {step === 3 && (
                                    <>
                                        <div className="inputPassSec">
                                            <input
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                placeholder="Enter The Password"
                                                required
                                            />
                                            <span onClick={passVisible}>
                                                {showPassword ? (
                                                    <FaRegEyeSlash />
                                                ) : (
                                                    <FaRegEye />
                                                )}
                                            </span>
                                        </div>

                                        <div className="inputPassSec">
                                            <input
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="confirmPassword"
                                                value={formData.confirmPassword}
                                                onChange={handleChange}
                                                placeholder="Confirm Your Password"
                                                required
                                            />
                                            <span onClick={passVisible}>
                                                {showPassword ? (
                                                    <FaRegEyeSlash />
                                                ) : (
                                                    <FaRegEye />
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )}
                                <button type="submit">Proceed</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>}


        </>
    );
};

export default Signup;
