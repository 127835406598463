import React, { useEffect } from "react";
import Hero from "../components/allProducts/Hero";
import AllProduct from "../components/allProducts/AllProduct";
import Loader from "../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProductsData,
  loadingProduct,
  getCurrentCategory,
  getCurrentPage,
} from "../store/productsData/productsData";

function AllProducts() {
  const currentPage = useSelector(getCurrentPage);
  const currentCategory = useSelector(getCurrentCategory);
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadingProd = useSelector(loadingProduct);

  useEffect(() => {
    if (currentCategory === "normalProduct") {
      dispatch(fetchAllProductsData());
    }
  }, [currentPage, currentCategory, dispatch]);


  return (
    <>
      {loadingProd ? <Loader /> : <div>
        <Hero />
        <AllProduct />
      </div>}
    </>
  );
}

export default AllProducts;
