import React, { useState } from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import "../../styles/SingleProduct/SinglePageRating.css";

import { Bounce, Fade } from "react-awesome-reveal";

import { FaUserLarge } from "react-icons/fa6";

const SinglePageRating = ({ ratingData }) => {
    console.log("ratingData::", ratingData?.rating)
    const [filter, setFilter] = useState(null);

    const handleFilterClick = (stars) => {
        setFilter(stars === "All" ? null : stars);
    };

    const filteredReviews = filter
        ? ratingData?.rating?.filter(res => res?.rating === filter)
        : ratingData?.rating;


    // Dynamic class name for the active button
    const getButtonClassName = (star) => {
        return filter === star || (filter === null && star === "All") ? "activebuttons" : "filter_buttons";
    };



    const generateStarIcons = (rating) => {
        const starIcons = [];

        for (let i = 0; i < 5; i++) {
            if (i < Math.floor(rating)) {
                // Full star
                starIcons.push(<FaStar className="star_icon_star" key={i} style={{ color: "#FFD700" }} />);
            } else if (i < rating) {
                // Partial star (use percentage to fill the star partially)
                starIcons.push(
                    <FaStarHalfAlt className="star_icon_star" key={i} style={{ color: "#FFD700" }} />
                );
            } else {
                // Empty star
                starIcons.push(<FaStar className="star_icon_star" key={i} style={{ color: "#ccc" }} />);
            }
        }

        return starIcons;
    };


    const generateStar = (rating) => {
        const starIcons = [];

        for (let i = 0; i < 5; i++) {
            if (i < Math.floor(rating)) {
                // Full star
                starIcons.push(<FaStar className="star_icon" key={i} style={{ color: "#FFD700" }} />);
            } else if (i < rating) {
                // Partial star (use percentage to fill the star partially)
                starIcons.push(
                    <FaStarHalfAlt className="star_icon" key={i} style={{ color: "#FFD700" }} />
                );
            } else {
                // Empty star
                starIcons.push(<FaStar className="star_icon" key={i} style={{ color: "#ccc" }} />);
            }
        }

        return starIcons;
    };


    const getdateandtime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // console.log(formattedDateTime);
    return (
        <>

            <div className="section6main">
                <div className="section6content">
                    <Bounce>
                        <h1 className="hd">
                            <span>Rating &</span> Review
                        </h1>
                    </Bounce>

                    <div className="section6item">
                        <div className="ratingandreview">
                            <div className="ratingboard">
                                <div className="rating">
                                    <Fade direction="left">
                                        <div className="rating-average" >
                                            <h1>{ratingData?.averageRating.toFixed(1)}</h1>
                                            <div className="star">

                                                {
                                                    generateStarIcons(ratingData?.averageRating.toFixed(1))
                                                }
                                            </div>

                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

                <div className={filteredReviews?.length < 3 ? "usershow" : "overthreeitem"}>
                    <div className="user_review_text_container">
                        <h3>Top reviews from customers</h3>

                        <div className="buttons_star_filter">
                            {["All", 5, 4, 3, 2, 1].map(star => (
                                <button key={star} onClick={() => handleFilterClick(star)}
                                    className={getButtonClassName(star)}

                                >
                                    <span>{star}</span><FaStar className="star_icon" />
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* all reviews */}
                    {filteredReviews?.map((res, index) => (
                        <div className="user-review_container" key={index}>
                            <div className="user_star_name_container">
                                <div className="user_icon">
                                    <FaUserLarge className="icon_user" />
                                </div>
                                {/* <div className="date_name_container"> */}

                                <p className="name-name">{res?.user?.firstName} {res?.user?.lastName}</p>
                                <p className="date_date_date">{getdateandtime(res?.createdAt)}</p>
                                {/* </div> */}

                                <div className="stars_container">
                                    {
                                        generateStar(res?.rating)
                                    }


                                </div>
                            </div>
                            <p>{res?.comment}</p>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export default SinglePageRating;
