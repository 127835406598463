import React, { useEffect } from "react";
import Hero from "../components/contactUs/Hero";
import Map from "../components/contactUs/Map";
import Form from "../components/contactUs/Form";
import { useSelector } from "react-redux";
import { selectLoading } from "../store/enquirySlice/contactSlice";
import Loader from "../components/loader/Loader";

const ContactUs = () => {
  const loader = useSelector(selectLoading)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {loader ? <Loader /> : <>
        <Hero />
        <Form />
        <Map />
      </>}
    </>
  );
};

export default ContactUs;
