import React from "react";
import "../../styles/Gallary/Hero.css";

import HeroBg from "../../Assets/Images/Gallery/gallery_hero.png";
import HeroMobile from "../../Assets/Images/Gallery/mobile.png";

const isMobileView = window.innerWidth < 768;

const Hero = () => {
  return (
    <div className="gallery-hero">
      <div className="hero_gallery_content">
        <div className="hero_gallery_items">
          <h1>Harmony in Healing</h1>
          <div className="hero_gallery_items-para">
            <p>Navigating Wellness with Homeopathy</p>
            <p>
              Explore the essence of natural healing through our captivating
              gallery. Immerse yourself in a visual journey of wellness,
              featuring our curated selection of premium homeopathic products,
              nourishing remedies, and serene lifestyle inspirations. From
              soothing tinctures to aromatic essentials, each image embodies the
              harmonious synergy of nature and holistic wellness. Discover the
              beauty of holistic living and find your perfect remedy.
            </p>
          </div>
        </div>
        <div className="gallery_hero_img">
          {!isMobileView ? (
            <img src={HeroBg} alt="" />
          ) : (
            <img src={HeroMobile} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
