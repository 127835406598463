import React, { useMemo, useState } from "react";
import "../../../styles/AllProducts/FilterProduct/Medicine.css";
import FilterPopUp from "../FilterPopUp";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesByDisease } from "../../../store/categories/categories";
import { addDisease, removeDisease, selectDiseases } from "../../../store/filterSlice/filterSlice";

function Treatments() {
    const dispatch = useDispatch()
    const selectedDisease = useSelector(selectDiseases);
    const treatmentData = useSelector(getCategoriesByDisease)
    //for pop-up
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const toggleBrandSelection = (brand) => {
        if (selectedDisease.includes(brand)) {
            // Brand is already selected, so remove it
            dispatch(removeDisease(brand));
        } else {
            // Brand is not selected, so add it
            dispatch(addDisease(brand));
        }
    };

 // selected treatment will show on the top 
    const sortedBrands = useMemo(() => {
        const categoryByDisease = treatmentData;
        if (!Array.isArray(categoryByDisease)) {
            return [];
        }

        const selectedBrandsList = categoryByDisease.filter((brand) =>
            selectedDisease.includes(brand?.categoryName)
        );
        const unselectedBrandsList = categoryByDisease.filter(
            (brand) => !selectedDisease.includes(brand?.categoryName)
        );

        return [...selectedBrandsList, ...unselectedBrandsList];
    }, [treatmentData, selectedDisease]);

      //show four data initially
    const showData = sortedBrands?.slice(0, 4);

    return (
        <div className="sidebar_medicine">
            <div className="sidebar_medicine_heading">
                    <h4 className="treatment" >Disease</h4>
            </div>

           
                <div className="sidebar_medicine_text">
                    {showData?.map((brand) => {
                        const brandString = String(brand?.categoryName);
                        return (
                            <div key={brandString}>
                                <input
                                    type="checkbox"
                                    id={`brand-${brand?.categoryName}`}
                                    checked={selectedDisease.includes(
                                        brand?.categoryName
                                    )}
                                    onChange={() =>
                                        toggleBrandSelection(
                                            brand?.categoryName
                                        )
                                    }
                                />
                                <label
                                    htmlFor={`brand-${brand?.categoryName}`}
                                    className="lablefilter"
                                >
                                    {brand?.categoryName}
                                </label>
                            </div>
                        );
                    })}
                </div>
          

             <button onClick={openModal}>view more</button>

            {isModalOpen && (
                <FilterPopUp
                    isOpen={isModalOpen}
                    allBrand={sortedBrands}
                    closeModal={closeModal}
                    toggleBrandSelection={toggleBrandSelection}
                    selectedBrands={selectedDisease}
                />
            )}
        </div>
    );
}

export default Treatments;
