import React, { useState } from "react";
import "../../styles/ApointmentForm/ApointmentForm.css";
import saveIcon from "../../Assets/Images/logo/Vector.png";
import { CgArrowLongRight } from "react-icons/cg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { bookOnlineConsultation, selectLoadingOnline } from "../../store/enquirySlice/onlineConsulatation";
import { toast } from "react-toastify";
import {  isBefore, isSameDay, isSaturday } from "date-fns";
import Loader from "../loader/Loader";

// time slots
const timeSlots = [
    "11 AM",
    "11:30 AM",
    "12 AM",
    "12:30 PM",
    "1 PM",
    "1:30 PM",
    "2 PM",
    "2:30 PM",
    "3 PM",
    "3:30 PM",
    "4 PM",
    "4:30 PM",
    "5 PM",
    "5:30 PM",
    "6 PM",
    "6:30 PM",
    "7 PM",
    "7:30 PM",
];

function AppointmentFom({  setIsModalOpen }) {
   
    const loader = useSelector(selectLoadingOnline)

    const dispatch = useDispatch();
    const [doctors, setDoctors] = useState([
        {
            name: "Dr. YASH PANDEY",
            experience: "3+ years",
            qualification: "BHMS",
            charges: 300,
            selected: false, // Added selected property
        },
        {
            name: "Dr. KHUSHBOO PANDEY",
            experience: "7+ years",
            qualification: "BHMS, MD(Homeo.), Ph.D Scholar",
            charges: 500,
            selected: false, // Added selected property
        },
    ]);
    const [selectedTime, setSelectedTime] = useState("");
    const [formStep, setFormStep] = useState(1);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNo: "",
        message: "",
        date: new Date(), // Initialize with current date
        time: "",
        doctor: "",
    });


    // handler for input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const capitalizedValue = value.replace(/\b\w/g, (char) => char.toUpperCase());
        if (name === "name") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: capitalizedValue,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

    };

    // handler for date change
    const handleDateChange = (value) => {
        // Format the date to dd/mm/yyyy format
        setFormData((prevFormData) => ({
            ...prevFormData,
            date: value, // Store the raw date object
        }));
    };

    // formate date in readable form 
    const formatDate = (date) => {
        // Get the components of the date
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Return the formatted date in dd/mm/yyyy format
        return `${day}/${month}/${year}`;
    };

    // handler for time select
    const handleTimeSelect = (time) => {
        setSelectedTime(time);
        setFormData((prevFormData) => ({
            ...prevFormData,
            time: time,
        }));
    };

    const handleModal = () => {
        setIsModalOpen(false);
    };

    // handle doctor
    const handleDoctorSelect = (doctorName) => {
        const updatedDoctors = doctors.map((doctor) => {
            if (doctor.name === doctorName) {
                return { ...doctor, selected: true };
            } else {
                return { ...doctor, selected: false };
            }
        });
        setFormData((prevFormData) => ({
            ...prevFormData,
            doctor: doctorName,
        }));

        // Update the doctors array
        setDoctors(updatedDoctors);
    };

    // handler for next button
    const handleNextBtn = () => {
        if (validateForm()) {
            setFormStep(formStep + 1);
        }
    };


    // form validations
    const validateForm = () => {
        // Validation for name
        if (formData.name.trim() === "") {
            toast.error("Please enter your name.");
            return false;
        }

        // Validation for doctor
        if (formData.doctor === "") {
            toast.error("Please select a doctor.");
            return false;
        }

        // Validation for email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/;
        if (!formData.email.trim().match(emailRegex)) {
            toast.error("Email must be a valid Gmail or Yahoo address");
            return false;
        }

        // Validation for mobile number
        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phoneNo.trim().match(phoneRegex)) {
            toast.error("Please enter a valid 10-digit mobile number.");
            return false;
        }

        if (formData.message.trim() === "") {
            toast.error("Please enter message")
            return false
        }
        return true;
    };

    // submit form 
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if date and time are selected
        if (formStep === 2) {
            if (!formData.date || !formData.time) {
                toast.error("Please select date and time before submitting.");
                return;
            }
        }

        // Format date before submitting
        const formattedData = {
            ...formData,
            date: formatDate(formData.date),
        };

        const response = await dispatch(bookOnlineConsultation(formattedData));
      

        if (response?.payload?.status === "success") {
            setFormData({
                name: "",
                email: "",
                phoneNo: "",
                message: "",
                date: new Date(), // Reset to current date
                time: "",
                doctor: "",
            });
            toast.success(`${response?.payload?.message}`);
            setIsModalOpen(false);
        } else if (response?.payload?.status === "failed") {
            toast.error(`${response?.payload?.message}`);
        }
    };

    const tileClassName = ({ date }) => {
        // Check if the current date is the selected date
        if (isSameDay(date, formData.date)) {
            // Return the class name for the selected date
            return "selected-date";
        }
        // If the current date is not selected, return null
        return null;
    };
    const tileDisabled = ({ date, view }) => {
        // Check if the date is in the past
        if (isBefore(date, new Date())) {
            return true;
        }

        // Check if the day is Saturday
        if (isSaturday(date)) {
            return true;
        }

        // If the date is not in the past and not a Saturday, return false
        return false;
    };

    return (
        <section className="book_popup_container">
            {loader && <Loader />}
            <div className="popup_header">
                <img src={saveIcon} alt="" />
                <h3>Book Appointment</h3>
            </div>
            <div className="popup_content">
                {formStep === 1 && (
                    <>
                        <form
                            action=""
                            className="popup_first_form"
                            onSubmit={handleSubmit}
                        >
                            <div className="doctor-selection">
                                {doctors?.map((doctor, index) => (
                                    <span
                                        className={
                                            doctor?.selected
                                                ? "selected-doctor"
                                                : ""
                                        }
                                        key={index}
                                        onClick={() =>
                                            handleDoctorSelect(doctor?.name)
                                        }
                                    >
                                        <h4>{doctor?.name}</h4>
                                        <p>{doctor?.qualification}</p>
                                        <p>exp: {doctor?.experience}</p>
                                        <p>charges: &#8377;{doctor?.charges}</p>
                                    </span>
                                ))}
                            </div>

                            <div>
                                <label htmlFor="">
                                    Name:{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Type Your Name"
                                    value={formData?.name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    Email Id:{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Enter Your Active Email Id"
                                    value={formData?.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    Mobile No:{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="phoneNo"
                                    placeholder="Start Typing Whatsapp Number"
                                    value={formData?.phoneNo}
                                    onChange={handleInputChange}
                                    maxLength={10}
                                />
                            </div>
                            <div>
                                <label htmlFor="">Message</label>
                                <textarea
                                    name="message"
                                    placeholder="Type your Message here...."
                                    value={formData?.message}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                            <div className="popup_first_div_btn">
                                <h3 onClick={handleModal}>Cancel</h3>

                                <div className="">
                                    <button onClick={handleNextBtn}>
                                        Next{" "}
                                        <CgArrowLongRight className="popup_arrow" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}

                {formStep === 2 && (
                    <>
                        <form
                            action=""
                            className="popup_second_form"
                            onSubmit={handleSubmit}
                        >
                            <div className="second_form_content">
                                <div className="popup_second_form_div1">
                                    <label htmlFor="">
                                        Select Date
                                        <span style={{ color: "red" }}>
                                            *
                                        </span>{" "}
                                    </label>
                                    <div>
                                        <Calendar
                                            value={formData?.date}
                                            onChange={handleDateChange}
                                            className="customCalendar"
                                            tileClassName={tileClassName} // Apply custom tile class
                                            tileDisabled={tileDisabled}
                                            onClickDay={(date) => {
                                                if (
                                                    isBefore(
                                                        date,
                                                        new Date()
                                                    ) ||
                                                    isSaturday(date)
                                                ) {
                                                    toast.error(
                                                        "You cannot select this date."
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="popup_second_form_div2">
                                    <label htmlFor="">
                                        Select Time{" "}
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div>
                                        <p>Select Available Time </p>
                                    </div>
                                    <div className="time_slot_div">
                                        {timeSlots.map((slot, index) => (
                                            <div
                                                key={index}
                                                className={`time_div ${selectedTime === slot
                                                    ? "selected-time"
                                                    : ""
                                                    }`}
                                                onClick={() =>
                                                    handleTimeSelect(slot)
                                                }
                                            >
                                                {slot}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="popup_second_form_btn">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </section>
    );
}

export default AppointmentFom;
