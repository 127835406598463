import React from 'react';
import '../../styles/Loader/Loader.css'
import { TbLoader } from "react-icons/tb";

const Loader = () => (
  <div className='loader'>
       <TbLoader className="TbLoader" />
  </div>
);

export default Loader;