import React, { useEffect } from "react";
import Loader from "../components/loader/Loader";

import {  useSelector } from "react-redux";
import Hero from "../components/home/Hero";
import MedicinesByDisease from "../components/home/MedicinesByDisease";
import Categories from "../components/home/Categories";
import Testinomials from "../components/home/Testinomials";
import MedicineSection from "../components/home/MedicineSection";
import { loadingProduct } from "../store/productsData/productsData";
import { catLoading } from "../store/categories/categories";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const loadmostSell = useSelector(loadingProduct);
  const loadingcat = useSelector(catLoading);

  return (
    <div>
      {loadmostSell || loadingcat ? (
        <Loader />
      ) : (
        <>
          <Hero />
          <MedicinesByDisease />
          <Categories />
          <Testinomials />
          <MedicineSection />
        </>
      )}
    </div>
  );
}

export default Home;
