import "../../styles/Home/Hero.css";
import img from "../../Assets/Images/Group 1782.png";
import { Fade, Zoom } from "react-awesome-reveal";
import ReactTypingEffect from "react-typing-effect";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setLoadingQuery,
  setSearchQuery,
} from "../../store/SearchQuery/SearchQuerySlice";
import SearchBar from "../../Constents/SearchBar";

const Hero = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQueryInput, setSearchQueryInput] = useState("");

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQueryInput(query);
    dispatch(setSearchQuery(query));
  };

  const handleSearchButtonClick = () => {
    dispatch(setLoadingQuery(true));
    if (searchQueryInput.trim() === "") {
      toast.error("Please enter a search query");
      return;
    }
    navigate("/allproducts");
  };

  return (
    <>
      <div className="home-hero">
        
        <div className="home-hero-left">
          <Fade direction="left">
            <h1>
              <span className="secondaryTextColor"> Find the best</span>
              <br />
              <span className="primaryTextColor">Holistic treatment here</span>
            </h1>
          </Fade>
          <Zoom>
            <h2>
              <span className="primaryTextColor">
                to make your health better.{" "}
              </span>
            </h2>
          </Zoom>
          <p className="search_by_medicine_text">
            <ReactTypingEffect
              speed={50}
              eraseSpeed={0}
              typingDelay={false}
              eraseDelay={1000}
              text={"Search by medicine, brand, or health condition!"}
            />
          </p>
          <SearchBar
            handleSearchButtonClick={handleSearchButtonClick}
            searchQueryInput={searchQueryInput}
            handleSearch={handleSearch}
          />
        </div>

        <div className="home-hero-right">
          <img src={img} alt="cover" />
        </div>
      </div>
    </>
  );
};

export default Hero;
