import React, { useState } from "react";
import "../../styles/Contactus/Form.css";
import { Zoom } from "react-awesome-reveal";
import { useDispatch } from "react-redux";
import { sendEnquiry } from "../../store/enquirySlice/contactSlice";
import { toast } from "react-toastify";

const Form = () => {
  const dispatch = useDispatch();

  // Regular expressions for validation
  const nameRegex = /^[a-zA-Z\s]*$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{10}$/;

  // State variables to store form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    message: "",
  });

  // Function to validate form data
  const validateForm = () => {
    let valid = true;

    if (!formData.firstName.trim() || !nameRegex.test(formData.firstName.trim())) {
      valid = false;
    }

    if (!formData.lastName.trim() || !nameRegex.test(formData.lastName.trim())) {
      valid = false;
    }

    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      valid = false;
    }

    if (!formData.phoneNo.trim() || !phoneRegex.test(formData.phoneNo.trim())) {
      valid = false;
    }

    return valid;
  };

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await dispatch(sendEnquiry(formData));

        if (response.payload.status === "success") {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            message: "",
          });
          toast.success(`${response.payload.message}`);
        } else if (response.payload.status === "failed") {
          toast.error(`${response.payload.message}`);
        }
      } catch (error) {
        console.error("Failed to submit enquiry:", error);
        toast.error("Failed to submit enquiry. Please try again later.");
      }
    } else {
      toast.error("Please fill in all required fields correctly.");
    }
  };

  return (
    <div className="formmain">
      <div className="formcontent">
        <form onSubmit={handleSubmit}>
          <Zoom>
            <h1>GET IN TOUCH</h1>
          </Zoom>
          <div className="form-group">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="first-control"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="first-control"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="E-Mail"
              className="form-control"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="tel"
              name="phoneNo"
              placeholder="Phone No"
              className="form-control"
              value={formData.phoneNo}
              onChange={handleInputChange}
            />
          </div>
          <textarea
            name="message"
            placeholder="Message"
            className="form-control message"
            rows="4"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
          <button type="submit" className="btn-submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
