import "../../styles/layout/CantFindMedicine.css";

const CantFindMedicine = () => {
  const whatsappLink = `https://wa.me/${process.env.REACT_APP_ADMIN_NUMBER}?text=Hello%2C%20I%20need%20assistance%20finding%20a%20specific%20medicine.%20Can%20you%20help%3F`;
  return (
    <div className="whatsapp_floating-button">
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <button>Can't find medicine? Contact us on WhatsApp</button>
      </a>
    </div>
  );
};

export default CantFindMedicine;
