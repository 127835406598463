// categoryByDiseaseSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { homeApiByDisease, homeApiMainCategory, productCategoryApiByBrand } from "../../Constents/apiAxios";

// Define the initial state
const initialState = {
    categoryByDisease: [],
    mainCategory: [],
    categoryByBrand: [],
    loading: false,
    error: null,
};

// Define the async thunk for fetching categories by disease
export const fetchCategoriesByDisease = createAsyncThunk(
    "categoriesByDisease/fetchCategoriesByDisease",
    async (_, { dispatch }) => {
        try {
            console.log(homeApiByDisease)
            const response = await axios.get(homeApiByDisease);
            const data = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);

// Define the async thunk for fetching categories by brand
export const fetchCategoriesByBrand = createAsyncThunk(
    "categoriesByBrand/fetchCategoriesByBrand",
    async (_, { dispatch }) => {
        try {
            const response = await axios.get(productCategoryApiByBrand);
            const data = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);

// Define the async thunk for fetching main categories
export const fetchMainCategories = createAsyncThunk(
    "mainCategories/fetchMainCategories",
    async (_, { dispatch }) => {
        try {
            const response = await axios.get(homeApiMainCategory);
            const data = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);

// Create the category by disease slice
const categoryByDiseaseSlice = createSlice({
    name: "categoriesByDisease",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesByDisease.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategoriesByDisease.fulfilled, (state, action) => {
                state.loading = false;
                state.categoryByDisease = action?.payload?.allCategory;
            })
            .addCase(fetchCategoriesByDisease.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchMainCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMainCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.mainCategory = action?.payload?.allCategory;
            })
            .addCase(fetchMainCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchCategoriesByBrand.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategoriesByBrand.fulfilled, (state, action) => {
                state.loading = false;
                state.categoryByBrand = action?.payload?.allCategory;
            })
            .addCase(fetchCategoriesByBrand.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export default categoryByDiseaseSlice.reducer;

export const getCategoriesByDisease = (state) => state?.categories?.categoryByDisease;
export const getCategoriesByBrand = (state) => state?.categories?.categoryByBrand;
export const getMainCategory = (state) => state?.categories?.mainCategory;

export const catLoading = (state) => state?.categories?.loading;
