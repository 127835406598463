import "../../styles/SingleProduct/ToggleButton.css"

const ToggleButton = (props) => {
  return (
    <button
      className="primaryButton"
      type={props.type || 'button'}
      onClick={props.onClick}
    >
      <p className="pText">{props.textContent}</p>

      <div className="pButton"><div className="addIcon">{props.icon}</div></div>


    </button>
  );
};

export default ToggleButton;
